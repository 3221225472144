import React from "react";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const MuiDaterange = ({
  name,
  label,
  disabled = false,
  required = false,
  disableFuture = true,
  value,
  onChange,
  onKeyUp,
  format = "DD-MM-YYYY",
  clearable = true,
  fullWidth = true,
  sx = {},
  style = {}
}) => {

  const defaultStyles = {
    backgroundColor: "#FFF",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx,
  };

  return (
    <FormControl fullWidth>
    <DateRangePicker
      label={label}
      name={name}
      disabled={disabled}
      disableFuture={disableFuture}
      required={required}
      value={value}
      format={format}
      clearable={clearable}
      fullWidth={fullWidth}
      onChange={onChange}
      onKeyUp={onKeyUp}
      sx={defaultStyles}
      style={style}
    />
    </FormControl>
  );
};

export default MuiDaterange;
