import React, { useState } from 'react';
import axios from 'axios';
import CustomAxios from '../utils/CustomAxios';

export const Test = () => {
    const [transactionId, setTransactionId] = useState(''); // Assume you have a transaction ID to use

    const handleFetchAndPrintReceipt = async () => {
        try {
            // URL to your Django view that generates the PDF
            const receiptUrl = `oasis/generate_receipt/1235/`;

            // Fetch the PDF receipt
            const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);

                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };

    return (
        <div>
            <input
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Enter Transaction ID"
            />
            <button onClick={handleFetchAndPrintReceipt}>Fetch and Print Receipt</button>
        </div>
    );
};
