import React, { useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';
import { Box, Button, Select, Typography, TextField, CircularProgress, Dialog, Table, Alert, TableHead, TableRow, TableCell, TableBody, DialogTitle, DialogContent, DialogActions, IconButton, Accordion, AccordionSummary, AccordionDetails, MenuItem, Checkbox, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Stack, Autocomplete } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LinkHeader } from '../../components/linkHeader';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { BakeryDining, SettingsRemote } from '@mui/icons-material';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/primereact.min.css'; // Import the PrimeReact styles
import 'primeicons/primeicons.css'
import { CutOff } from '../../components/Cut-Off';
import '../../styles/form.css'
import CustomAxios from '../../utils/CustomAxios';
import { useMediaQuery } from 'react-responsive';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import MuiInput from "../../elements/Mui/mui_input";
import MuiAutocomplete from "../../elements/Mui/mui_Autocomplete";
import dayjs from "dayjs";
import MuiDatePicker from '../../elements/Mui/mui_date_picker'
import MuiSearchBar from '../../elements/Mui/mui_searchbar'
import MuiButton from "../../elements/Mui/mui_button";

const TransactionsTable = ({ transactions, onEdit, onRemove, handleFetchAndPrintReceipt }) => {

    const theme = useTheme();

    const formatDate = (date) => {
        if(typeof(date)!="string"){
           const new_date = new Date(date)
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = String(new_date.getDate()).padStart(2, '0');
        const monthIndex = String(new_date.getMonth() + 1).padStart(2, '0');
        const month = months[monthIndex];
        const year = new_date.getFullYear();
        console.log(day)
        return `${day}-${monthIndex}-${year}`; 
        }
        else{
            const dd = String(new Date(date).getUTCDate()).padStart(2, '0');
            const mm = String(new Date(date).getUTCMonth() + 1).padStart(2, '0'); // January is 0!
            const yy = String(new Date(date).getUTCFullYear());
            
            return `${dd}-${mm}-${yy}`;
        }
        
    };
    const date = new Date(); // Assuming input date is in ISO 8601 format
    const formattedDate = formatDate(date);
    //console.log(formattedDate)
    return (
        <Table>
            <TableHead sx={{ backgroundColor: '#f8f9fa' }}>
                <TableRow>
                    <TableCell>SN</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>RN</TableCell>
                    <TableCell>M#</TableCell>
                    <TableCell>Member Name</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Withdrawal Amount</TableCell>
                    <TableCell>Bank</TableCell>
                    <TableCell>Chq#</TableCell>
                    <TableCell>Narration</TableCell>
                    <TableCell>Insurance</TableCell>
                    <TableCell>Processing Fee</TableCell>
                    <TableCell>Actions</TableCell>
                    <TableCell>Print</TableCell>
                    <TableCell>Remove</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transactions.map((transaction, index) => (
                    <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{formatDate(transaction.transdate)}</TableCell>
                        <TableCell>{transaction.transdetailsid}</TableCell>
                        <TableCell>{transaction.memberId}</TableCell>
                        <TableCell>{transaction.memberDetails.memberName}</TableCell>
                        {/* <TableCell>{transaction.amount}</TableCell> */}
                        <TableCell>{transaction.memberDetails.age}</TableCell>
                        <TableCell>{transaction.transtype == 21 || transaction.transtypeid == 21 ? "Share" : transaction.transtype == 22 || transaction.transtypeid == 22 ? "Subscription" : transaction.transtype == 24 || transaction.transtypeid == 24 ? "specificDeposit" : "Bonus"}</TableCell>
                        <TableCell>{transaction.transtotalamount}</TableCell>
                        <TableCell >{parseInt(transaction.transamount).toFixed(2)}</TableCell>
                        <TableCell>{transaction.bank}</TableCell>
                        <TableCell>{transaction.ChequeNo}</TableCell>
                        <TableCell>{transaction.narration}</TableCell>
                        <TableCell >{transaction.insurance!==""?transaction.insurance!==null?parseInt(transaction.insurance).toFixed(2):(0).toFixed(2):(0).toFixed(2)}</TableCell>
                        <TableCell >{transaction.processingfee!==""?transaction?.processingfee!==null?parseInt(transaction.processingfee).toFixed(2):(0).toFixed(2):(0).toFixed(2)}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => onEdit(index)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            {transaction.transdetailsid ?
                                <PrintIcon style={{ cursor: "pointer" }} onClick={() => handleFetchAndPrintReceipt(transaction.transdetailsid, "single")} />
                                : <div>-</div>
                            }
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={() => onRemove(index)}>
                                <RemoveIcon /> {/* Assuming you have imported RemoveIcon */}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
};

const TransactionModal = ({
    removedTrans,
    setRemovedTrans,
    open,
    transaction,
    onSave,
    onClose,
    banks,
    transInTable,
    loan,
    Guarantor,
    SDlist,
    guarantorForList,
    getMemberDetails,
    bulkno // Assuming this is passed as a prop from the parent component
}) => {
    const [editedTransaction, setEditedTransaction] = useState(transaction);
    const [share, setShare] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const [specificDeposit, setSpecificDeposit] = useState(false);
    const [bonus, setBonus] = useState(false)
    const [sharetrans, setShareTrans] = useState(0);
    const [subscriptionTrans, setSubscriptionTrans] = useState(0);
    const [specificDepositTrans, setSpecificDepositTrans] = useState(0);
    const [bonusTrans, setBonusTrans] = useState(0)
    const [allTrans, setAllTrans] = useState([]);
    const [errors, setErrors] = useState({ balance: '', fields: '', deduct: '', trans: '', transamount: '', member: '', bankerror: '', sderror:'' });
    const [save, setSave] = useState(false);
    const [memberDetails, setMemberDetails] = useState([]);
    const [radio, setRadio] = useState("Part");
    const [selectedSdbal, setSelectedSdBal] = useState('')
    const [DeductRadio, setDeductRadio] = useState("");
    const [shareNarration, setShareNaraation] = useState("PwShare");
    const [subsNarration, setSubsNarration] = useState("PwSubs");
    const [sdNarration, setSdNaraation] = useState("PwSd");
    const [boNarration, setboNarration] = useState("PwBonus");

    const [toggle, setToggle] = useState("SD");
    const [bankName, setBankName] = useState("")
    const [transdate, setTransdate] = useState(new Date());
    const [cutoffDate, setCutoffDate] = useState([]);
    const [bankerror, setBankError] = useState('')



    //const [banksarray,setBanksarray] = useState([]);
    const inputRef = useRef(null);
    const formReff = useRef(null);

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
        };

        // Create Date object from the provided string
        const date = new Date(dateString);

        // Format the date string
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        console.log(formattedDate)

        return new Date(formattedDate);
    };

    let bank_array = []
    useEffect(() => {
        banks.map((mem) => bank_array.push(mem.bankname))
    }, [banks])

    useEffect(() => {
        //console.log(transaction)
        const mem = allTrans.filter((mem) => transaction.memberId == mem.memberid)
        setErrors(prev => ({
            ...prev,
            bankerror: ''
        }))
        setErrors(prev => ({
            ...prev,
            sderror: ''
        }))
        transaction.processingfee = 0
        transaction.insurance = 0
        setEditedTransaction(transaction); // Reset the editedTransaction when the modal opens with new transaction datae
        if (transaction.memberId !== "") {
            fetchMemberDetails("edit")
            const filltered = transInTable.filter((mem) => mem.id == transaction.id)
            let hasPW = filltered.some(obj =>obj.narration && obj.narration.startsWith('Pw'));
            if (hasPW) {
                setRadio('Part');
            } else {
                setRadio("Full")
            }
            filltered.map((mem) => {
                console.log(mem.processingfee!==""||mem.processingfee!==null, mem.processingfee)
                editedTransaction.bank = mem.bank
                editedTransaction.processingfee += parseFloat(mem.processingfee)
                editedTransaction.insurance += parseFloat(mem.insurance)
                if (mem.transtype == 21 || mem.transtypeid == 21) {
                    console.log(mem.transdate)
                    console.log(mem.bank)
                    if (mem.remarks) {
                        //editedTransaction["newbank"] = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    setTransdate(mem.transdate)
                    // setBankName(mem.bank)
                    setShareTrans(mem.transamount)
                    setShare(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.processingfee) > 0) {
                        setDeductRadio("ShareDeduct")
                    }
                    if ((mem.narration == "PwShare")&& (transaction.remarks=="")) {
                        setShareNaraation("PwShare")
                    } else if (mem.narration == "FwShare") {
                        setShareNaraation("FwShare")
                    }
                    else if (mem.narration!="PwShare"){
                        console.log(mem.narration)
                        setShareNaraation(mem.narration)
                    }
                    else if (mem.narration!="FwShare"){
                        setShareNaraation(mem.narration)
                    }
                } else if (mem.transtype == 22 || mem.transtypeid == 22) {
                    console.log(mem.bank)
                    // editedTransaction.bank = mem.bank+ "                                               "
                    console.log(mem.transdate)
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        console.log("remarks")
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    setSubscriptionTrans(mem.transamount)
                    setSubscription(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.processingfee) > 0) {
                        setDeductRadio("SubscriptionDeduct")
                    }
                    if ((mem.narration == "PwSubs") && (mem.remarks=="")){
                        setSubsNarration("PwSubs")
                    } else if (mem.narration == "FwSubs"){
                        setSubsNarration("FwSubs")
                    }
                    else if (mem.narration!="PwSubs"){
                        setSubsNarration(mem.narration)
                    }
                    else if (mem.narration!="FwSubs"){
                        setSubsNarration(mem.narration)
                    }
                }
                else if ((mem.transtype == 24 || mem.transtypeid == 24)) {
                    console.log(mem)
                    // editedTransaction.bank = mem.bank
                    editedTransaction.sd_id= parseInt(mem.loanno)
                    if (editedTransaction.memberDetails.sds?.length>0){
                    let filtered=editedTransaction.memberDetails.sds.filter(item=>
                           ( parseInt(item.depositid)==parseInt(mem.loanno)))
                    console.log(filtered)
                    setSelectedSdBal(parseInt(filtered[0]?.sdbalance))
                    setSpecificDepositTrans(parseInt(filtered[0]?.sdbalance))
                    }
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        //editedTransaction.bankname = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    console.log(mem.transamount)
                    setSpecificDepositTrans(mem.transamount)
                    setSpecificDeposit(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.processingfee) > 0) {
                        setDeductRadio("SpecificDepositDeduct")
                    }
                    if ((mem.narration == "PwSd")&& (mem.remarks=="")) {
                        setSdNaraation("PwSd")
                    } else if (mem.narration == "FwSd"){
                        setSdNaraation("FwSd")
                    }
                    else if (mem.narration!="PwSd"){
                        setSdNaraation(mem.narration)
                    }
                    else if (mem.narration!="FwSd"){
                        setSdNaraation(mem.narration)
                    }
                }
                else if (mem.transtype == 34 || mem.transtypeid == 34) {
                    console.log(mem.bank)
                    // editedTransaction.bank = mem.bank+ "                                               "
                    console.log(mem.transdate)
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        console.log("remarks")
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    setBonusTrans(mem.transamount)
                    setBonus(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.processingfee) > 0) {
                        setDeductRadio("BonusDeduct")
                    }
                    if ((mem.narration == "PwBonus") && (mem.remarks=="")){
                        setboNarration("PwBonus")
                    } else if (mem.narration == "FwBonus"){
                        setboNarration("FwBonus")
                    }
                    else if (mem.narration!="PwBonus"){
                        setboNarration(mem.narration)
                    }
                    else if (mem.narration!="FwBonus"){
                        setboNarration(mem.narration)
                    }
                }
            })
        }
        else {
            editedTransaction.processingfee = 50
            // editedTransaction.bank = mem.bank + "                                               "
        }
    }, [transaction, open]);

    const handleChange = (e, type) => {
        const name = e.target.name;
        let value = e.target.value;
        console.log(name, value, type, SDlist)
        if (name == "bank" && value !== undefined) {
            setErrors(prev => ({
                ...prev,
                bankerror: ''
            }))
            value = value.bankname
        }
        if (name=="sd_id"){
            let filtered=SDlist.filter(item=>item.depositid==parseInt(value))
            console.log(filtered)
            setSelectedSdBal(filtered[0]?.sdbalance)
            setSpecificDepositTrans(radio=='Full'?filtered[0]?.sdbalance:0)
            console.log("we are here")
            setErrors(prev => ({
                ...prev,
                sderror: ''
            }))
        }
        setEditedTransaction(prev => { console.log("???????????????????handle change"); return ({ ...prev, [name]: value }) });
    };
    let transid_Array = []
    useEffect(() => {
        if (allTrans.length > 0 && transInTable.length > 0 && transInTable[0].transdetailid && allTrans[0].transdetailid && transInTable[0].transdetailsid && allTrans[0].transdetailsid) {
            const trans_filter = transInTable.filter((mem) => mem.memberid == allTrans[0].memberid)
            console.log(allTrans, trans_filter)
            trans_filter.map((mem, index) => {
                console.log(mem)
                if(allTrans[index]){
                    allTrans[index].transdetailsid = mem.transdetailsid
                    allTrans[index].transdetailid = mem .transdetailid
                }else{
                    setRemovedTrans(prev => [...prev, mem.transdetailid])
                }
            })
        }
    }, [allTrans])
    console.log(SDlist)
    console.log(allTrans)
    const handleSave = async (e) => {
        //console.log(cutoffDate)
        e.preventDefault();
        
        if (editedTransaction.bank == '' || editedTransaction.bank == null) {
            console.log("we are in bankerror")
            setErrors(prev => ({
                ...prev,
                bankerror: 'please enter bank name'
            }))
        }
        else if (specificDeposit && editedTransaction.sd_id == '') {
            console.log("we are in sderror")
            setErrors(prev => ({
                ...prev,
                sderror: 'please select sd id'
            }))
        }
        else {
            console.log("we are not in bank")
            handleCheckValid()
            const randomInteger = Math.floor(Math.random() * (10000 - 50) + 50)
            if (share == true || subscription == true || specificDeposit == true || bonus == true || DeductRadio =="shareDeduct" || DeductRadio =="SubscriptionDeduct" || DeductRadio =="SpecificDepositDeduct" || DeductRadio =="BonusDeduct") {
                if ((share == true || DeductRadio == "ShareDeduct") && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transdate = transdate
                    val[0].transtype = 21
                    val[0].transtypeid = 21
                    val[0].narration = shareNarration
                    val[0].id = randomInteger
                    val[0].transamount = sharetrans
                    val[0].radio = radio
                    val[0].withdrawaltype = shareNarration=='PwShare' ? 'P':'F'
                    val[0].transtotalamount = sharetrans > 0 && DeductRadio == "ShareDeduct" ? parseInt(sharetrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : sharetrans > 0 ? sharetrans : DeductRadio == "ShareDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : 0
                    DeductRadio == "ShareDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    DeductRadio == "ShareDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                if ((subscription == true || DeductRadio == "SubscriptionDeduct") && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 22
                    val[0].transtypeid = 22
                    val[0].narration = subsNarration
                    val[0].id = randomInteger
                    val[0].transdate = transdate
                    val[0].transamount = subscriptionTrans
                    val[0].radio = radio
                    val[0].withdrawaltype = subsNarration=='PwSubs' ? 'P':'F'
                    val[0].transtotalamount = subscriptionTrans > 0 && DeductRadio == "SubscriptionDeduct" ? parseInt(subscriptionTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : subscriptionTrans > 0 ? subscriptionTrans : DeductRadio == "SubscriptionDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : 0
                    //console.log(val)
                    DeductRadio == "SubscriptionDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    DeductRadio == "SubscriptionDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                    console.log(val[0])
                }
                if ((specificDeposit == true || DeductRadio == "SpecificDepositDeduct") && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 24
                    val[0].transtypeid = 24
                    val[0].narration = sdNarration
                    val[0].id = randomInteger
                    val[0].transamount = specificDepositTrans
                    val[0].transdate = transdate
                    val[0].radio = radio
                    val[0].withdrawaltype = sdNarration=='PwSd' ? 'P':'F'
                    val[0].transtotalamount = specificDepositTrans > 0 && DeductRadio == "SpecificDepositDeduct" ? parseInt(specificDepositTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : specificDepositTrans > 0 ? specificDepositTrans : DeductRadio == "SpecificDepositDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : 0
                    //console.log(val)
                    DeductRadio == "SpecificDepositDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    DeductRadio == "SpecificDepositDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                if ((bonus == true || DeductRadio == "BonusDeduct") && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 34
                    val[0].transtypeid = 34
                    val[0].narration = boNarration
                    val[0].id = randomInteger
                    val[0].transamount = bonusTrans
                    val[0].transdate = transdate
                    val[0].radio = radio
                    val[0].withdrawaltype = boNarration=='PwBonus' ? 'P':'F'
                    val[0].transtotalamount = bonusTrans > 0 && DeductRadio == "BonusDeduct" ? parseInt(bonusTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : bonusTrans > 0 ? bonusTrans : DeductRadio == "BonusDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) : 0
                    //console.log(val)
                    DeductRadio == "BonusDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    DeductRadio == "BonusDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }

            }
        }
    }

    const handleCheckValid = () => {
        console.log(errors)
        errors.balance == '' && errors.fields == '' && errors.deduct == "" && errors.trans == "" && errors.bankerror == ""  && errors.sderror == "" ? setSave(true) : setSave(false)
    }
    useEffect(() => {
        if (save == true) {
            onSave(allTrans)
        }
        setSave(false)
    }, [save])
    useEffect(() => {
        console.log(memberDetails.length > 0 && (((share == true && parseInt(memberDetails[0]?.sharebalance) - sharetrans) < 0) || ((parseInt(memberDetails[0]?.subscriptionbalance)  - subscriptionTrans) < 0 && subscription == true) || ((memberDetails[0]?.sdbalance - specificDepositTrans) < 0 && specificDeposit == true) || ((parseInt(memberDetails[0]?.bonus) - bonusTrans) < 0 && bonus == true)))
        if (memberDetails.length > 0 && (((share == true && parseInt(memberDetails[0]?.sharebalance) - sharetrans) < 0) || ((parseInt(memberDetails[0]?.subscriptionbalance)  - subscriptionTrans) < 0 && subscription == true) || ((memberDetails[0]?.sdbalance - specificDepositTrans) < 0 && specificDeposit == true) || ((parseInt(memberDetails[0]?.bonus) - bonusTrans) < 0 && bonus == true))) {
            console.log("here")
            setErrors(prev => ({
                ...prev,
                balance: 'Insufficient Balance'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                balance: ''
            }));
        }
        // if (parseFloat(sharetrans) == parseFloat(memberDetails[0]?.sharebalance) && DeductRadio === "ShareDeduct"){
        //     console.log("we are here 2")
        //     setShareTrans(parseInt(sharetrans)-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) )
        // }
        // if (parseFloat(subscriptionTrans) == parseFloat(memberDetails[0]?.subscriptionbalance) && DeductRadio === "SubscriptionDeduct"){
        //     console.log("we are at subscription")
        //     setSubscriptionTrans(subscriptionTrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }
        // if (parseFloat(specificDepositTrans) == parseFloat(memberDetails[0]?.sdbalance) && DeductRadio === "SpecificDepositDeduct"){
        //     console.log("we are here")
        //     setSpecificDepositTrans(specificDepositTrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }
        // if (parseFloat(bonusTrans) == parseFloat(memberDetails[0]?.bonusbalance) && DeductRadio === "Bonusif (parseFloat(sharetrans) == parseFloat(memberDetails[0]?.sharebalance) && DeductRadio === "ShareDeduct"){
        //     console.log("we are here 2")
        //     setShareTrans(parseInt(sharetrans)-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) )
        // }
        // if (parseFloat(subscriptionTrans) == parseFloat(memberDetails[0]?.subscriptionbalance) && DeductRadio === "SubscriptionDeduct"){
        //     console.log("we are at subscription")
        //     setSubscriptionTrans(subscriptionTrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }
        // if (parseFloat(specificDepositTrans) == parseFloat(memberDetails[0]?.sdbalance) && DeductRadio === "SpecificDepositDeduct"){
        //     console.log("we are here")
        //     setSpecificDepositTrans(specificDepositTrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }
        // if (parseFloat(bonusTrans) == parseFloat(memberDetails[0]?.bonusbalance) && DeductRadio === "BonusDeduct"){
        //     setBonusTrans(sharetrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }Deduct"){
        //     setBonusTrans(sharetrans-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)))
        // }

    }, [sharetrans, subscriptionTrans, specificDepositTrans, bonusTrans, DeductRadio, editedTransaction])

console.log(sharetrans, memberDetails[0]?.sharebalance, DeductRadio)

    useEffect(() => {
        console.log("????????????????????????????inside edited useeffect", editedTransaction)
        if (editedTransaction.bank) {
            let bankname = editedTransaction.bank.split(" ")
            console.log("????????????????????????splitted bankname", bankname)
        }

    }, [editedTransaction])
    const fetchMemberDetails = async (edit) => {
        //console.log(edit=='edit')
        const memberId = editedTransaction.memberId;
        //console.log("memberId: " + memberId);
        if (memberId) {
            try {
                const details = await getMemberDetails(memberId);
                //console.log("details: ", typeof(details));
                if (typeof (details) == "object") {
                    let sharetotal = 0
                    let substotal = 0
                    let sdtotal = 0
                    let bototal = 0
                    // const memberfilter = transInTable.filter((mem)=>mem.memberId == details.memberid)
                    // xikjven2lqfyscupuhu446vmlyai6idobfjqot7vrf5e4fr2bcqamemberfilter.red((mem)=>parseInt(sharetotal)= parseInt(sharetotal)+parseInt(mem.sharebalance))
                    transInTable.reduce((acc, curr) => {
                        if (curr.memberId == details.memberid) {
                            //console.log(transaction,edit)

                            if (curr.transtype == 21) {
                                sharetotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 22) {
                                substotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 24) {
                                sdtotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 34) {
                                bototal += parseInt(curr.transtotalamount)
                            }
                            // if(edit=='edit'){
                            //     console.log(transaction)
                            //     if(curr.transtype==21){
                            //         details.sharebalance = parseInt(details.sharebalance)-parseInt(curr.transtotalamount)
                            //     }
                            //     if(curr.transtype==22){
                            //         details.subscriptionbalance = parseInt(details.subscriptionbalance)-parseInt(curr.transtotalamount)
                            //     }
                            //     if(curr.transtype==24){
                            //         details.sdbalance = parseInt(details.sdbalance)-parseInt(curr.transtotalamount)
                            //     }}
                        }
                        return acc;
                    }, 0);
                    if(details && open==true){
                    transInTable.filter((trans)=>trans.memberid==details.memberid).map((transaction)=>{
                        if (transaction.transtype == 21 || transaction.transtypeid == 21) {
                            details.sharebalance = parseInt(details.sharebalance)+parseInt(transaction.transtotalamount)
                        }
                        if (transaction.transtype == 22 || transaction.transtypeid == 22) {
                            details.subscriptionbalance = parseInt(details.subscriptionbalance)+parseInt(transaction.transtotalamount)
                        }
                        if (transaction.transtype == 24 || transaction.transtypeid == 24) {
                            details.sdbalance = parseInt(details.sdbalance)+parseInt(transaction.transtotalamount)
                        }
                        if (transaction.transtype == 34 || transaction.transtypeid == 34) {
                            details.bonus = parseInt(details.bonus)+parseInt(transaction.transtotalamount)
                        }
                    }) 
                    }
                    // details.sharebalance = parseInt(details.sharebalance) - parseInt(sharetotal)
                    // details.subscriptionbalance = parseInt(details.subscriptionbalance) - parseInt(substotal)
                    // details.sdbalance = parseInt(details.sdbalance) - parseInt(sdtotal)
                    // details.bonus = parseInt(details.bonus) - parseInt(bototal)
                    //console.log(sharetrans,details,sharetotal,substotal,sdtotal)

                    setMemberDetails([details])
                // Assuming details contain memberName, oldIcNo, newIcNo, etc.
                setEditedTransaction(prev => { console.log("hw?????????????????????????????????????????", editedTransaction.bank)
                return({
                    ...prev,
                    sharebalance:details.sharebalance,
                    sucscriptionbalance:details.sucscriptionbalance,
                    sdbalance:details.sdbalance,
                    bonus:details.bonus,
                    memberDetails: {
                        ...prev.memberDetails,
                        memberName: details.membername, // Update these keys based on your actual data structure
                        oldIcNo: details.oldicno,
                        newIcNo: details.newicno,
                        age: details.age,
                            state:details.state,
                            estate:details.estate,
                            accountno:details.m_acctno,
                            email:details.emailid,
                            mobileno:details.mobileno,
                            membank:details.m_bankname,
                            sd_list:details.sd_list,
                    }
                })});
                    // Assuming details contain memberName, oldIcNo, newIcNo, etc.
                    setEditedTransaction(prev => {
                        console.log("hw?????????????????????????????????????????", editedTransaction.bank)
                        return ({
                            ...prev,
                            sharebalance:details.sharebalance,
                            sucscriptionbalance:details.sucscriptionbalance,
                            sdbalance:details.sdbalance,
                            bonus:details.bonus,
                            memberDetails: {
                                ...prev.memberDetails,
                                memberName: details.membername, // Update these keys based on your actual data structure
                                oldIcNo: details.oldicno,
                                newIcNo: details.newicno,
                                age: details.age,
                                state: details.state,
                                estate: details.estate,
                                accountno: details.m_acctno,
                                email: details.emailid,
                                mobileno: details.mobileno,
                                sd_list:details.sd_list,
                            }
                        })
                    });

                    setErrors(prev => ({
                        ...prev,
                        member: ""
                    }));
                } else {
                    //console.log("hiuhiuh")
                    setErrors(prev => ({
                        ...prev,
                        member: "Please Enter Valid MemberId"
                    }));
                    errors.member = "Please Enter Valid MemberId"
                }

            } catch (error) {
                console.error("Failed to fetch member details:", error);
            }
        } else {
            console.error("Member ID is undefined");
        }
    };
    console.log(shareNarration)
    const handleCheck = (event, type) => {
        console.log(type)
        type == "share" ? setShare(!share) : type == "subscription" ? setSubscription(event.target.checked) : type == "specificDeposit" ? setSpecificDeposit(event.target.checked) : setBonus(!bonus)
    }
    // useEffect(() => {
    //     if (radio == "Full" && memberDetails.length > 0) {
    //         //console.log(radio,share,DeductRadio)
    //         const sharededucted = parseInt((parseInt(memberDetails[0]?.sharebalance)) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))
    //         const subscriptionDeducted = parseInt((parseInt(memberDetails[0]?.subscriptionbalance) ) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))
    //         const specificDepositDeducted = parseInt((memberDetails[0]?.sdbalance) - (parseInt(editedTransaction.arrsetShareNaraation("PwShare") : setShareNaraation("FwShare")
    //         subscription == true && radio == "Part" ? setSubsNarration("PwSubs") : setSubsNarration("FwSubs")
    //         specificDeposit == true && radio == "Part" ? setSdNaraation("PwSd") : setSdNaraation("FwSd")
    //         bonus == true && radio == "Part" ? setboNarration("PwBonus") : setboNarration("FwBonus")

    //     } else if (radio == "Part" && memberDetails.length > 0) {
    //         share == true ? parseInt(memberDetails[0]?.sharebalance) > 0 ? DeductRadio == "ShareDeduct" && (parseInt(sharetrans) == parseInt(parseInt(memberDetails[0]?.sharebalance)) || (parseInt(sharetrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.sharebalance))) ? setShareTrans(parseInt((parseInt(memberDetails[0]?.sharebalance)) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))) : setShareTrans(sharetrans) : setShareTrans(0) : setShareTrans(0)
    //         subscription == true ? parseInt(memberDetails[0]?.subscriptionbalance)  > 0 ? DeductRadio == "SubscriptionDeduct" && (parseInt(subscriptionTrans) == parseInt(parseInt(memberDetails[0]?.subscriptionbalance) ) || (parseInt(subscriptionTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.subscriptionbalance) )) ? setSubscriptionTrans(parseInt((parseInt(memberDetails[0]?.subscriptionbalance) ) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))) : setSubscriptionTrans(subscriptionTrans) : setSubscriptionTrans(0) : setSubscriptionTrans(0)
    //         specificDeposit == true ? memberDetails[0]?.sdbalance > 0 ? DeductRadio == "SpecificDepositDeduct" && (parseInt(specificDepositTrans) == parseInt(memberDetails[0]?.sdbalance) || (parseInt(specificDepositTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0]?.sdbalance)) ? setSpecificDepositTrans(parseInt((memberDetails[0]?.sdbalance) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))) : setSpecificDepositTrans(specificDepositTrans) : setSpecificDepositTrans(0) : setSpecificDepositTrans(0)
    //         bonus == true ? parseInt(memberDetails[0]?.bonus) > 0 ? DeductRadio == "BonusDeduct" && (parseInt(bonusTrans) == parseInt(parseInt(memberDetails[0]?.bonus)) || (parseInt(bonusTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.bonus))) ? setBonusTrans(parseInt((parseInt(memberDetails[0]?.bonus)) - (parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)))) : setBonusTrans(bonusTrans) : setBonusTrans(0) : setBonusTrans(0)

    //         share == true && radio == "Part" && (parseInt(sharetrans) == parseInt(parseInt(memberDetails[0]?.sharebalance))) || (parseInt(sharetrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.sharebalance)) ? setShareNaraation("FwShare") : setShareNaraation("PwShare")
    //         subscription == true && radio == "Part" && (parseInt(subscriptionTrans) == parseInt(parseInt(memberDetails[0]?.subscriptionbalance) )) || (parseInt(subscriptionTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.subscriptionbalance) ) ? setSubsNarration("FwSubs") : setSubsNarration("PwSubs")
    //         specificDeposit == true && radio == "Part" && (parseInt(specificDepositTrans) == parseInt(memberDetails[0]?.sdbalance)) || (parseInt(specificDepositTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0]?.sdbalance) ? setSdNaraation("FwSd") : setSdNaraation("PwSd")
    //         bonus == true && radio == "Part" && (parseInt(bonusTrans) == parseInt(parseInt(memberDetails[0]?.bonus))) || (parseInt(bonusTrans) + parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) >= parseInt(parseInt(memberDetails[0]?.bonus)) ? setboNarration("FwBonus") : setboNarration("PwBonus")

    //     }
    // }, [radio, share, subscription, specificDeposit, bonus, DeductRadio, editedTransaction.processingfee, editedTransaction.insurance, sharetrans, subscriptionTrans, specificDepositTrans, bonusTrans])
    const handleSearch = (e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
            fetchMemberDetails(e);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const date = (rowData) => {
        const date = rowData.depositdate!=null && new Date(rowData.depositdate) 
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    }
    const dateformat = (rowData) => {
         if(rowData.loanstartdate!=null){
         const date = rowData.loanstartdate!=null && new Date(rowData.loanstartdate)
         return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();}
     }
    const dateformatexp = (rowData) => {
         if(rowData.loanexpirydate != null){
             const date = rowData.loanexpirydate != null && new Date(rowData.loanexpirydate)
         return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
         }
         
    }
    const sddateformatexp = (rowData) => {
        if(rowData.expirydate != null){
            const date = rowData.expirydate != null && new Date(rowData.expirydate)
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        }
        
   }
    const [ColumnVisible, setColumneVisible] = useState([

        { field: "loanid", header: "Loan#", visible: true },
        { field: "loanstartdate", header: "LoanDate", visible: true, body: dateformat },
        { field: "principalamount", header: "Principal", visible: true },
        { field: "noofinstalment", header: "#inst", visible: true },
        { field: "loanexpirydate", header: "Expiry", visible: true, body: dateformatexp },
        { field: "loanbalanceamount", header: "LoanBalance", visible: true },


    ])
    const [GuarantorColumnVisible, setGuarantorColumnVisible] = useState([
        { field: "guarantorid", header: "Guarantor", visible: true, style: { width: '80px' } },
        { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
        { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
        { field: "loanno", header: "Loan#", visible: true },
    ])
    const [sdColumnVisible, setSdColumnVisible] = useState([
        { field: "depositid", header: "depositid", visible: true },
        { field: "depositdate", header: "depositdate", visible: true, body: date },
        { field: "period", header: "period", visible: true },
        { field: "expirydate", header: "expirydate", visible: true, body: sddateformatexp },
        { field: "sdbalance", header: "sdbalance", visible: true },
    ])
    const [mgColumnVisible, setmgColumnVisible] = useState([
        { field: "memberid", header: "memberID", visible: true, style: { width: '80px' } },
        { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
        { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
        { field: "loanno", header: "Loan#", visible: true },
    ])
    const checkval = (e) => {
        console.log(e.target.value)

    }
    let shareDeducted 
    let subscriptionDeducted 
    let specificDepositDeducted 
    let bonusDeducted
    let shNarration
    let suNarration
    let specificNarration
    let bonusNarration 
    let shareTransTotalAmount, subscriptionTransTotalAmount, bonusTransTotalAmount, specificDepositTotalAmount
    const calculateTrans = () => {
        console.log(editedTransaction, memberDetails)
        if(editedTransaction.memberid && bulkno !== "") {
              transInTable.filter(transaction => {
                if(transaction.memberid === editedTransaction.memberId) {
                    if(transaction.transtypeid === 21){
                        shareTransTotalAmount = parseInt(transaction.transtotalamount)
                        shNarration = transaction.narration}
                    else if(transaction.transtypeid === 22){
                        subscriptionTransTotalAmount = parseInt(transaction.transtotalamount)
                        suNarration = transaction.narration}
                    else if(transaction.transtypeid === 24){
                        specificDepositTotalAmount = parseInt(transaction.transtotalamount)
                        specificNarration = transaction.narration}
                    else if(transaction.transtypeid === 34){
                        bonusTransTotalAmount = parseInt(transaction.transtotalamount)
                        bonusNarration = transaction.narration}
                }
              })
        }
        console.log(editedTransaction, radio, sharetrans, shareTransTotalAmount) 
        console.log(bulkno !== "", radio==="Full")
          shareDeducted = (editedTransaction.memberid && bulkno !== "" ? shareTransTotalAmount - (DeductRadio === "ShareDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) : 0) :radio==="Full" ? parseInt(memberDetails[0]?.sharebalance) - (DeductRadio === "ShareDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)):0):parseInt(sharetrans)>0?sharetrans:0)
          subscriptionDeducted = (editedTransaction.memberid && bulkno !== "" ? subscriptionTransTotalAmount - (DeductRadio === "SubscriptionDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) : 0) : radio==="Full" ? parseInt(memberDetails[0]?.subscriptionbalance) - (DeductRadio === "SubscriptionDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)):0):parseInt(subscriptionTrans)>0?subscriptionTrans:0)
          bonusDeducted = (editedTransaction.memberid && bulkno !== "" ? bonusTransTotalAmount - (DeductRadio === "BonusDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) : 0) : radio==="Full" ? parseInt(memberDetails[0]?.bonus) - (DeductRadio === "BonusDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):parseInt(bonusTrans)>0?bonusTrans:0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) :0):parseInt(bonusTrans)>0?bonusTrans:0)

          console.log('insidepart', sharetrans, subscriptionTrans, bonusTrans, specificDepositTrans)

          if(selectedSdbal && selectedSdbal>0 && radio=='Full'){
            specificDepositDeducted = parseInt(selectedSdbal) - (DeductRadio === "SpecificDepositDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)):0);
          }
          else{
            console.log("here")
            specificDepositDeducted = (editedTransaction.memberid && bulkno !== "" ? parseInt(specificDepositTrans)>0?specificDepositTrans: specificDepositTotalAmount - (DeductRadio === "SpecificDepositDeduct" ? ((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)):0) : parseInt(specificDepositTrans)>0?specificDepositTrans:0)
          }

          console.log(parseInt(selectedSdbal) ,parseInt(editedTransaction.processingfee) ,parseInt(editedTransaction.insurance),specificDepositDeducted)
          if (radio === "Full") {
          console.log(shareDeducted, subscriptionDeducted)
          share==true?setShareTrans(DeductRadio === "ShareDeduct" ? (share ? (parseInt(memberDetails[0]?.sharebalance) > 0 ? shareDeducted>0?shareDeducted:0 : 0) : parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) : shareDeducted>0?shareDeducted:0):setShareTrans(0) 
          subscription==true?setSubscriptionTrans(DeductRadio === "SubscriptionDeduct" ? (subscription ? (parseInt(memberDetails[0]?.subscriptionbalance)  > 0 ? subscriptionDeducted>0?subscriptionDeducted:0 : 0) : parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) : subscriptionDeducted>0?subscriptionDeducted:0 ):setSubscriptionTrans(0)
          bonus==true?setBonusTrans(DeductRadio === "BonusDeduct" ? (bonus ? (parseInt(memberDetails[0]?.bonus) > 0 ? bonusDeducted>0?bonusDeducted:0 : 0) : parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) : bonusDeducted>0?bonusDeducted:0):setBonusTrans(0)
          specificDeposit==true?setSpecificDepositTrans(DeductRadio === "SpecificDepositDeduct" ? (specificDeposit ? (parseInt(memberDetails[0]?.sdbalance) > 0 ? specificDepositDeducted>0?specificDepositDeducted:0 : 0) : parseInt(editedTransaction.processingfee) + parseInt(editedTransaction.insurance)) : specificDepositDeducted>0?specificDepositDeducted:0):setSpecificDepositTrans(0)
            //specificDeposit==true?setSpecificDepositTrans(DeductRadio === "SpecificDepositDeduct" ? (specificDeposit ? (memberDetails[0]?.sdbalance > 0 && parseFloat(memberDetails[0]?.sdbalance)== parseFloat(specificDeposit)? parseInt(specificDeposit)-((editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0) + (editedTransaction.insurance !== ''?parseInt(editedTransaction.insurance):0)) : specificDepositTrans) : specificDepositTrans) : +specificDepositTrans) : setSpecificDepositTrans(0);
          }
          if (radio === "Part") {
              console.log('insidepart', sharetrans, subscriptionTrans, bonusTrans, specificDepositTrans, shareDeducted)
              setShareTrans(DeductRadio === "ShareDeduct" ? (share ? (parseInt(memberDetails[0]?.sharebalance)> 0 && parseFloat(memberDetails[0]?.sharebalance) == parseFloat(sharetrans) ? bulkno!=="" ?parseFloat(parseInt(shareDeducted)>0?shareDeducted:0) :parseInt(sharetrans)>0?sharetrans:0: bulkno!=="" ?parseFloat(shareDeducted>0?shareDeducted:0):parseInt(sharetrans)>0?sharetrans:0) : bulkno!==""?parseFloat(shareDeducted>0?shareDeducted:0):parseInt(sharetrans)>0?sharetrans:0) : bulkno!==""?+parseFloat(shareDeducted>0?shareDeducted:0):parseInt(sharetrans)>0?sharetrans:0);
              setSubscriptionTrans(DeductRadio === "SubscriptionDeduct" ? (subscription ? (parseInt(memberDetails[0]?.subscriptionbalance) && parseFloat(memberDetails[0]?.subscriptionbalance) == parseFloat(subscriptionTrans)  > 0 ? bulkno!=="" ?parseFloat(subscriptionDeducted>0?subscriptionDeducted:0):parseInt(subscriptionTrans)>0?subscriptionTrans:0: bulkno!=="" ?parseFloat(subscriptionDeducted>0?subscriptionDeducted:0):parseInt(subscriptionTrans)>0?subscriptionTrans:0) : bulkno!=="" ?parseFloat(subscriptionDeducted>0?subscriptionDeducted:0):parseInt(subscriptionTrans)>0?subscriptionTrans:0) :bulkno!=="" ? +parseFloat(subscriptionDeducted>0?subscriptionDeducted:0):parseInt(subscriptionTrans)>0?subscriptionTrans:0);
              setBonusTrans(DeductRadio === "BonusDeduct" ? (bonus ? (parseInt(memberDetails[0]?.bonusbalance) && parseFloat(memberDetails[0]?.bonusbalance) == parseFloat(bonusTrans)  > 0 ? bulkno!=="" ?parseFloat(bonusDeducted>0?bonusDeducted:0):parseInt(bonusTrans)>0?bonusTrans:0 : bulkno!=="" ?parseFloat(bonusDeducted>0?bonusDeducted:0):parseInt(bonusTrans)>0?bonusTrans:0) : bulkno!=="" ? parseFloat(bonusDeducted>0?bonusDeducted:0):parseInt(bonusTrans)>0?bonusTrans:0) : bulkno!=="" ?parseFloat(bonusDeducted>0?bonusDeducted:0):parseInt(bonusTrans)>0?bonusTrans:0);
              setSpecificDepositTrans(DeductRadio === "SpecificDepositDeduct" ? (specificDeposit ? (parseInt(memberDetails[0]?.sdbalance) && parseFloat(memberDetails[0]?.sdbalance) == parseFloat(specificDepositTrans)  > 0 ? bulkno!=="" ?parseFloat(specificDepositDeducted>0?specificDepositDeducted:0):parseInt(specificDepositTrans)>0?specificDepositTrans:0 : bulkno!=="" ?parseFloat(specificDepositDeducted>0?specificDepositDeducted:0):parseInt(specificDepositTrans)>0?specificDepositTrans:0) : bulkno!=="" ? parseFloat(specificDepositDeducted>0?specificDepositDeducted:0):parseInt(specificDepositTrans)>0?specificDepositTrans:0) : bulkno!=="" ?parseFloat(specificDepositDeducted>0?specificDepositDeducted:0):parseInt(specificDepositTrans)>0?specificDepositTrans:0);
            }
};
useEffect(() => {
    calculateTrans();
}, [radio,DeductRadio,share,subscription,specificDeposit,bonus,memberDetails, editedTransaction,selectedSdbal]);
useEffect(() => {
    if (radio === "Full") {
        parseInt(memberDetails[0]?.sharebalance)>0&&setShare(true);
        parseInt(memberDetails[0]?.subscriptionbalance) >0&&setSubscription(true);
        parseInt(memberDetails[0]?.bonus)>0&&setBonus(true);
    }
    
}, [radio,])

console.log(specificDepositTrans)

    useEffect(() => {
        if (memberDetails.length > 0) {
            const shareBalance = parseInt(parseInt(memberDetails[0]?.sharebalance));
            const subsalance = parseInt(parseInt(memberDetails[0]?.subscriptionbalance) );
            const sdBalance = parseInt(selectedSdbal);
            const bonusBalance = parseInt(parseInt(memberDetails[0]?.bonus));
            const totalShareTrans = +sharetrans + +editedTransaction.processingfee + +editedTransaction.insurance;
            const totalSubsTrans = +subscriptionTrans + +editedTransaction.processingfee + +editedTransaction.insurance;
            const totalSdTrans = +specificDepositTrans + +editedTransaction.processingfee + +editedTransaction.insurance;
            const totalBonusTrans = +bonusTrans + +editedTransaction.processingfee + +editedTransaction.insurance;
        console.log(subsalance,totalSubsTrans,subscriptionTrans, editedTransaction)
        console.log(shareBalance, totalShareTrans, sharetrans, (radio==='Full' && shareBalance === totalShareTrans || shareBalance === +sharetrans))
        console.log(("remarks" in editedTransaction))
        console.log(radio)
            share==true?setShareNaraation(bulkno===""?radio==='Full' && shareBalance === totalShareTrans || shareBalance === +sharetrans ? shareNarration==="PwShare"?'FwShare': shareNarration:shareNarration!=="PwShare"?shareNarration:'PwShare':radio=="Full"&bulkno===""?"FwShare":shareNarration):DeductRadio=='ShareDeduct'&&shareNarration=="PwShare"?setShareNaraation('PwShare'):setShareNaraation(shareNarration);
            subscription==true?setSubsNarration(bulkno===""?radio==='Full' && subsalance === totalSubsTrans || subsalance === +subscriptionTrans ? subsNarration==="PwSubs"?'FwSubs': subsNarration :subsNarration!=="PwSubs"?subsNarration:'PwSubs':radio=="Full"&bulkno===""?"FwSubs":subsNarration):DeductRadio=='SubscriptionDeduct'&&subsNarration=="PwSubs"?setSubsNarration('PwSubs'):setSubsNarration(subsNarration);
            specificDeposit==true?setSdNaraation(bulkno===""?radio==='Full' && sdBalance === totalSdTrans || sdBalance === +specificDepositTrans ? sdNarration==="PwSd"?'FwSd': sdNarration :sdNarration!=="PwSd"?sdNarration:'PwSd':radio=="Full"&bulkno===""?"FwSd":sdNarration):DeductRadio=='SpecificDepositDeduct'&&sdNarration=='PwSd'?setSdNaraation('PwSd'):setSdNaraation(sdNarration);
            bonus==true?setboNarration(bulkno===""?radio==='Full' && bonusBalance === totalBonusTrans || bonusBalance === +bonusTrans ? boNarration==="PwBonus"?'FwBonus': boNarration :boNarration!=="PwBonus"?boNarration:'PwBonus':radio=="Full"&bulkno===""?"FwBonus":boNarration):DeductRadio=='BonusDeduct'&&boNarration=="PwSubs"?setboNarration('PwBonus'):setboNarration(boNarration);}
        
      }, [sharetrans,subscriptionTrans,bonusTrans,specificDepositTrans,DeductRadio,radio, editedTransaction]);
console.log(editedTransaction)
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xlg" fullWidth draggable={false} position="center" modal={false} closable={false} resizable={false}>
            <form ref={formReff} onSubmit={(e) => handleSave(e)} onKeyDown={handleKeyDown}>
                <DialogTitle>{editedTransaction?.id ? 'Edit Transaction' : 'Add Transaction'}</DialogTitle>
                <Box sx={{ display: "flex", gap: "20px" }}>
                    <DialogContent sx={{ paddingBottom: "0px", maxWidth: "60%" }} >
                        <Box sx={{ display: "flex" }}>
                            <TextField
                                required
                                margin="dense"
                                label="Member ID"
                                fullWidth
                                variant="outlined"
                                name="memberId"
                                value={editedTransaction.memberId || ''}
                                onChange={handleChange}
                                onKeyUp={(e) => handleSearch(e)}
                            />
                            <IconButton onClick={fetchMemberDetails} size="large">
                                <PersonSearchIcon />
                            </IconButton></Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <TextField
                                required
                                margin="dense"
                                label="Member Name"
                                fullWidth
                                variant="outlined"
                                name="memberName"
                                value={editedTransaction.memberDetails?.memberName || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="Old IC No"
                                fullWidth
                                variant="outlined"
                                name="oldIcNo"
                                value={editedTransaction.memberDetails?.oldIcNo || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="New IC No"
                                fullWidth
                                variant="outlined"
                                name="newIcNo"
                                value={editedTransaction.memberDetails?.newIcNo || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            /></Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <TextField
                                margin="dense"
                                label="Age"
                                variant="outlined"
                                name="age"
                                sx={{ width: "333px" }}
                                value={editedTransaction.memberDetails?.age || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <Dropdown showClear value={editedTransaction?.bank || null} label="Bank" style={{ width: "333px", height: "53px" }} placeholder='Bank *' onChange={handleChange} name="bank" options={banks} optionLabel='bankname'
                                    editable />
                                {errors.bankerror !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.bankerror}</Typography>}
                            </Box>
                            {/* <TextField
                    required
                    select
                        label="Bank"
                        fullWidth
                        variant="outlined"
                        name="bank"
                        value={editedTransaction?.bank||''}
                        onChange={handleChange}
                    >
                        {banks.map((option, idx) =>{console.log(option,editedTransaction.bank);console.log(editedTransaction?.bank==(option.bankname)); return  (
                            <MenuItem key={idx} selected={editedTransaction?.bank==option.bankname} value={option.bankname}>
                                {option.bankname}
                            </MenuItem>
                        )})}
                    </TextField> */}
                            <TextField
                                margin="dense"
                                label="Chq No"
                                variant="outlined"
                                name="ChequeNo"
                                sx={{ width: "333px" }}
                                value={editedTransaction?.ChequeNo}
                                onChange={handleChange}
                            />
                            {/* <TextField
                      margin="dense"
                      label="Amount"
                      fullWidth
                      variant="outlined"
                      name="amount"
                      type="number"
                      value={editedTransaction.amount || ''}
                      onChange={handleChange}
                  /> */}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <TextField
                                margin="dense"
                                label="Insurance"
                                variant="outlined"
                                name="insurance"
                                type="number"
                                value={editedTransaction?.insurance}
                                sx={{ width: "333px" }}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                label="Processing Fee"
                                variant="outlined"
                                name="processingfee"
                                type="number"
                                sx={{ width: "333px" }}
                                value={editedTransaction?.processingfee || ''}
                                onChange={handleChange}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Calendar value={editedTransaction?.transdate || transdate} onChange={(e) => setTransdate(e.value)} style={{ width: "315px", height: "55px", background: 'transparent', border: 'none', zIndex: 2992 }} dateFormat="dd-mm-yy" placeholder='dd-mm-yyyy' showIcon />
                                {/* <Typography sx={{fontSize:"12px"}}>Transaction Date *</Typography>
                    <input type="date" required label="Transaction Date" placeholder="Transaction Date" value={editedTransaction?.transdate || transdate} style={{width:"333px", height:"50px", color:"#495057"}} onChange={(e)=>setTransdate(e.target.value)}/> */}
                            </Box>
                        </Box>
                        {/* <TextField
                      select
                      label="Transaction Type"
                      fullWidth
                      variant="outlined"
                      name="transtype"
                      value={editedTransaction.transtype || ''}
                      onChange={handleChange}
                  >
                      {transactionTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                              {option.name}
                          </MenuItem>
                      ))}
                  </TextField> */}
                        <Box sx={{ mt: 2, mb: 1, display: 'flex' }}>
                            <Box sx={{ width: "50%" }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>PaymentType</FormLabel>
                                    <RadioGroup
                                        aria-label="Payment Type"
                                        name="Payment Type"
                                        value={radio}
                                        onChange={(e) => setRadio(e.target.value)}
                                        row

                                    >
                                        <FormControlLabel required disabled={memberDetails.length > 0 ? false : true} value="Part" control={<Radio />} label="Part" />
                                        <FormControlLabel required disabled={memberDetails.length > 0 ? false : true} value="Full" sx={{ background: "red", borderRadius: "50px", paddingRight: "10px", ml: 3, color: "#FFF" }} control={<Radio sx={{ color: "#FFF" }} />} label="Full Withdrawal" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                    <Box>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" sx={{fontWeight:"700"}}>Deduct From</FormLabel>
                            <RadioGroup
                                aria-label="Deduct From"
                                name="Deduct From"
                                value={DeductRadio}
                                onChange={(e) => setDeductRadio(e.target.value)}
                                row
                            >
                                <FormControlLabel required={(parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance) > 0)?true:false} disabled={memberDetails.length>0&& (parseInt(memberDetails[0]?.sharebalance)>0&&parseInt(memberDetails[0]?.sharebalance)>(editedTransaction.processingfee != ''?parseInt(editedTransaction.processingfee):0 + editedTransaction.insurance != ''?parseInt(editedTransaction.insurance):0)) && (parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance))?false:true} value="ShareDeduct" control={<Radio />} label="Share" />
                                <FormControlLabel required={(parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance) > 0)?true:false} disabled={memberDetails.length>0&& (parseInt(memberDetails[0]?.subscriptionbalance) >0&&parseInt(memberDetails[0]?.subscriptionbalance) >(editedTransaction.processingfee != ''?parseInt(editedTransaction.processingfee):0 + editedTransaction.insurance != ''?parseInt(editedTransaction.insurance):0)) && (parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance))?false:true} value="SubscriptionDeduct" control={<Radio />} label="Subscription" />
                                <FormControlLabel required={(parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance) > 0)?true:false} disabled={memberDetails.length>0&& (memberDetails[0]?.sdbalance>0&&memberDetails[0]?.sdbalance>(editedTransaction.processingfee != ''?parseInt(editedTransaction.processingfee):0 + editedTransaction.insurance != ''?parseInt(editedTransaction.insurance):0)) && (parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance))?false:true} value="SpecificDepositDeduct" control={<Radio />} label="SpecificDeposit" />
                                <FormControlLabel required={(parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance) > 0)?true:false} disabled={memberDetails.length>0&& (parseInt(memberDetails[0]?.bonus)>0&&parseInt(memberDetails[0]?.bonus)>(editedTransaction.processingfee != ''?parseInt(editedTransaction.processingfee):0 + editedTransaction.insurance != ''?parseInt(editedTransaction.insurance):0)) && (parseInt(editedTransaction?.processingfee) > 0 || parseInt(editedTransaction?.insurance))?false:true} value="BonusDeduct" control={<Radio />} label="Bonus" />
                            </RadioGroup>
                        </FormControl>
                         {memberDetails.length > 0 &&<Typography sx={{textAlign:"right"}}>
                            Loan Balance  <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "#fff", fontWeight: "600" }}>RM {memberDetails[0]?.loanbalance == null ? 0 : parseInt(memberDetails[0]?.loanbalance).toFixed(2)}</Typography>
                        </Typography>}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%",gap:"7px"}}>
                        <Box>
                            <Checkbox
                            required={subscription==true||specificDeposit==true||bonus==true?false:true}
                                disabled={memberDetails.length>0&& parseInt(memberDetails[0]?.sharebalance)>0?false:true}
                                checked={share}
                                onChange={(e) => handleCheck(e, "share")}
                                sx={{paddingLeft:"0px"}}
                            />Share{" "}
                        </Box>
                        {memberDetails.length > 0 && <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "#fff", fontWeight: "600" }}>RM {parseInt(memberDetails[0]?.sharebalance) == null ? 0 : parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)}</Typography>}
                        <TextField
                            label="share"
                            required
                            fullWidth
                            disabled={share ?memberDetails.length>0&&parseInt(memberDetails[0]?.sharebalance)>0? false:true : true}
                            type="number"
                            variant="outlined"
                            placeholder='Share'
                            name="share"
                            value={sharetrans}
                            onChange={(e) => setShareTrans(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" ,gap:"7px"}}>
                        <Box>
                            <Checkbox
                                required={share==true||specificDeposit==true||bonus==true?false:true}
                                disabled={memberDetails.length>0&& parseInt(memberDetails[0]?.subscriptionbalance) >0?false:true}
                                checked={subscription}
                                onChange={(e) => handleCheck(e, "subscription")}
                                sx={{paddingLeft:"0px"}}
                            />Subscription{" "}
                        </Box>
                        {memberDetails.length > 0 && <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "#fff", fontWeight: "600" }}>RM {parseInt(memberDetails[0]?.subscriptionbalance)  == null ? 0 : parseInt(parseInt(memberDetails[0]?.subscriptionbalance) ).toFixed(2)}</Typography>}
                        <TextField
                        required
                     label="subscription"
                     fullWidth
                     disabled = {subscription?memberDetails.length>0&&parseInt(memberDetails[0]?.subscriptionbalance) >0? false:true :true}
                     type="number"
                     variant="outlined"
                     name="subscription"
                     value={subscriptionTrans}
                     onChange={(e) => setSubscriptionTrans(e.target.value)}
                 /></Box>
                 <Box sx={{ display: "flex", flexDirection: "column", width: "100%",gap:"7px"}}>
                        <Box>
                        <Box sx={{display:"flex", flexDirection:"row",alignItems:"center"}}>
                      <Checkbox
                      required={subscription==true||share==true||bonus==true?false:true}
                      disabled={memberDetails.length>0&& memberDetails[0]?.sdbalance>0?false:true}
                      checked={specificDeposit}
                      onChange={(e)=>handleCheck(e,"specificDeposit")}
                      sx={{paddingLeft:"0px"}}
                      /><Box sx={{display:"flex",alignItems:"center",gap:"20px"}}>SpecificDeposit<Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                     <Dropdown
                                                value={editedTransaction?.sd_id }
                                                onChange={handleChange}
                                                name="sd_id"
                                                placeholder={"Select SD no"}
                                                options={editedTransaction?.memberDetails?.sd_list}
                                                style={{  width: "100px", height: "36px", display: "flex", alignItems: "center",background:"#FFFFFF"}}/>
                                {errors.sderror !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.sderror}</Typography>}
                            </Box></Box>{" "}
                      </Box>
                      </Box>
                      {selectedSdbal!=="" ? <Typography sx={{display:"inline-block",background:"var(--pallate-green-500, #288d2c)",padding:"4px 10px",borderRadius:"50px",color:"#fff",fontWeight:"600"}}>RM {parseInt(selectedSdbal).toFixed(2)}</Typography>:memberDetails.length>0?<Typography sx={{display:"inline-block",background:"var(--pallate-green-500, #288d2c)",padding:"4px 10px",borderRadius:"50px",color:"#fff",fontWeight:"600"}}>RM {memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0]?.sdbalance)?0.00:parseInt(memberDetails[0]?.sdbalance).toFixed(2)}</Typography>:<></>}
                   <TextField
                   required
                     label="specificDeposit"
                     fullWidth
                     disabled = {specificDeposit?memberDetails.length>0&&memberDetails[0]?.sdbalance>0? false:true :true}
                     type="number"
                     variant="outlined"
                     name="specificDeposit"
                     value={specificDepositTrans}
                     onChange={(e)=>setSpecificDepositTrans(e.target.value)}
                 />
                    </Box>


                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" ,gap:"7px"}}>
                                <Box>
                                    <Checkbox
                                        required={share == true || specificDeposit == true || subscription == true ? false : true}
                                        disabled={memberDetails.length > 0 && parseInt(memberDetails[0]?.bonus) > 0 ? false : true}
                                        checked={bonus}
                                        onChange={(e) => handleCheck(e, "bonus")}
                                        sx={{ paddingLeft: "0px" }}
                                    />Bonus{" "}
                                </Box>
                                {memberDetails.length > 0 && <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "#fff", fontWeight: "600" }}>RM {parseInt(memberDetails[0]?.bonus) == null ? 0 : parseInt(parseInt(memberDetails[0]?.bonus)).toFixed(2)}</Typography>}
                                <TextField
                                    required
                                    label="bonus"
                                    fullWidth
                                    disabled={bonus ? memberDetails.length > 0 && parseInt(memberDetails[0]?.bonus) > 0 ? false : true : true}
                                    type="number"
                                    variant="outlined"
                                    name="bonus"
                                    value={bonusTrans}
                                    onChange={(e) => setBonusTrans(e.target.value)}
                                /></Box>






                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <FormLabel component="legend" sx={{ fontWeight: "700" }}>Narration</FormLabel>
                            <Box sx={{ display: "flex", gap: "5px" }}>
                                <TextField
                                    required
                                    disabled={share == true ? false : true}
                                    margin="dense"
                                    label="Narration"
                                    fullWidth
                                    variant="outlined"
                                    name="narration"
                                    value={shareNarration}
                                    onChange={(e) => setShareNaraation(e.target.value)}
                                />
                                <TextField
                                    required
                                    disabled={subscription == true ? false : true}
                                    margin="dense"
                                    label="Narration"
                                    fullWidth
                                    variant="outlined"
                                    name="narration"
                                    value={subsNarration}
                                    onChange={(e) => setSubsNarration(e.target.value)}
                                />
                                <TextField
                                    required
                                    disabled={specificDeposit == true ? false : true}
                                    margin="dense"
                                    label="Narration"
                                    fullWidth
                                    variant="outlined"
                                    name="narration"
                                    value={sdNarration}
                                    onChange={(e) => setSdNaraation(e.target.value)}
                                />
                                <TextField
                                    required
                                    disabled={bonus == true ? false : true}
                                    margin="dense"
                                    label="Narration"
                                    fullWidth
                                    variant="outlined"
                                    name="narration"
                                    value={boNarration}
                                    onChange={(e) => setboNarration(e.target.value)}
                                />
                            </Box>
                        </Box>
                        <Typography component="label" sx={{ fontWeight: "700" }}>Total Amount</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "20px", width: "100%" }}>
                            <Typography>
                                Share   <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>RM {radio == "Part" ? (memberDetails.length > 0 && parseInt(memberDetails[0]?.sharebalance) > 0 ? sharetrans > 0 && DeductRadio == "ShareDeduct" && share == true ? (parseInt(sharetrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : (sharetrans > 0 && share == true ? parseInt(sharetrans).toFixed(2) : DeductRadio == "ShareDeduct" ? ((editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : 0) : sharetrans) : (memberDetails.length > 0 && parseInt(memberDetails[0]?.sharebalance) > 0 ? (sharetrans > 0 && DeductRadio == "ShareDeduct" && share == true ? (parseInt(sharetrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(sharetrans).toFixed(2)) : 0.00)}</Typography>
                            </Typography>
                            <Typography>
                                Subscription <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>RM {radio == "Part" ? memberDetails.length > 0 && parseInt(memberDetails[0]?.subscriptionbalance)  > 0 ? subscriptionTrans > 0 && DeductRadio == "SubscriptionDeduct" && subscription == true ? (parseInt(subscriptionTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : subscriptionTrans > 0 && subscription == true ? parseInt(subscriptionTrans).toFixed(2) : DeductRadio == "SubscriptionDeduct" ? ((editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : 0 : subscriptionTrans : memberDetails.length > 0 && parseInt(memberDetails[0]?.subscriptionbalance)  > 0 ? subscriptionTrans > 0 && DeductRadio == "SubscriptionDeduct" && subscription == true ? (parseInt(subscriptionTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(subscriptionTrans).toFixed(2) : 0.00}</Typography>
                            </Typography>
                            <Typography>
                                Specific Deposit <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>RM {radio == "Part" ? memberDetails.length > 0 && memberDetails[0]?.sdbalance > 0 ? specificDepositTrans > 0 && DeductRadio == "SpecificDepositDeduct" && specificDeposit == true ? (parseInt(specificDepositTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : specificDepositTrans > 0 && specificDeposit == true ? parseInt(specificDepositTrans).toFixed(2) : DeductRadio == "SpecificDepositDeduct" ? ((editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : 0 : specificDepositTrans : memberDetails.length > 0 && memberDetails[0]?.sdbalance > 0 ? specificDepositTrans > 0 && DeductRadio == "SpecificDepositDeduct" && specificDeposit == true ? (parseInt(specificDepositTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(specificDepositTrans).toFixed(2) : 0.00}</Typography>
                            </Typography>
                            <Typography>
                                Bonus <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>RM {radio == "Part" ? memberDetails.length > 0 && parseInt(memberDetails[0]?.bonus) > 0 ? bonusTrans > 0 && DeductRadio == "BonusDeduct" && bonus == true ? (parseInt(bonusTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : bonusTrans > 0 && bonus == true ? parseInt(bonusTrans).toFixed(2) : DeductRadio == "BonusDeduct" ? ((editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : 0 : bonusTrans : memberDetails.length > 0 && parseInt(memberDetails[0]?.bonus) > 0 ? bonusTrans > 0 && DeductRadio == "BonusDeduct" && bonus == true ? (parseInt(bonusTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(bonusTrans).toFixed(2) : 0.00}</Typography>
                            </Typography>
                            <Typography>
                            Total <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                    RM {
                                        (
                                            (share === true ? (sharetrans > 0 ? parseFloat(sharetrans) : 0) : 0) +
                                            (subscription === true ? (subscriptionTrans > 0 ? parseFloat(subscriptionTrans) : 0) : 0) +
                                            (specificDeposit === true ? (specificDepositTrans > 0 ? parseFloat(specificDepositTrans) : 0) : 0) +
                                            (bonus === true ? (bonusTrans > 0 ? parseFloat(bonusTrans) : 0) : 0)+
                                            (DeductRadio === "ShareDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== '' ? parseFloat(editedTransaction.processingfee):0) : 0) +
                                            (DeductRadio === "SubscriptionDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== '' ? parseFloat(editedTransaction.processingfee):0) : 0) +
                                            (DeductRadio === "SpecificDepositDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== '' ? parseFloat(editedTransaction.processingfee):0) : 0) +
                                            (DeductRadio === "BonusDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== '' ? parseFloat(editedTransaction.processingfee):0) : 0)
                                        ).toFixed(2)
                                    }
                                </Typography>
                            </Typography>
                        </Box>


                        <Typography component="label" sx={{ fontWeight: "700" }}>Bal After Withdrawal</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "20px", width: "100%" }}>

                            <Typography>
                                Share <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                    RM {radio === "Part" ? (
                                        memberDetails.length > 0 && parseInt(parseInt(memberDetails[0]?.sharebalance)) > 0 ? (
                                            share==true?sharetrans > 0 && DeductRadio === "ShareDeduct" ? (
                                                (parseInt(memberDetails[0]?.sharebalance) - (parseInt(sharetrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                            ) : (
                                                sharetrans > 0 ? (parseInt(memberDetails[0]?.sharebalance) - parseInt(sharetrans)).toFixed(2) : DeductRadio === "ShareDeduct" ? (parseInt(memberDetails[0]?.sharebalance) - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(memberDetails[0]?.sharebalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)
                                            ): DeductRadio === "ShareDeduct" ?(parseInt(memberDetails[0]?.sharebalance) - ( (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2):parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)
                                        ) : parseInt(memberDetails[0]?.sharebalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && parseInt(memberDetails[0]?.sharebalance) > 0 ? (
                                            sharetrans > 0 && DeductRadio === "ShareDeduct" ? (
                                                (parseInt(memberDetails[0]?.sharebalance) - (parseInt(sharetrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                            ) : (
                                                sharetrans > 0 ? (parseInt(memberDetails[0]?.sharebalance) - parseInt(sharetrans)).toFixed(2) : parseInt(memberDetails[0]?.sharebalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)
                                            )
                                        ) : parseInt(memberDetails[0]?.sharebalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.sharebalance)).toFixed(2)
                                    )}
                                </Typography>
                            </Typography>

                            <Typography>
                                Subscription <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                RM {radio === "Part" ? (
                                        memberDetails.length > 0 && parseInt(parseInt(memberDetails[0]?.subscriptionbalance)) > 0 ? (
                                            subscription==true?subscriptionTrans > 0 && DeductRadio === "SubscriptionDeduct" ? (
                                                (parseInt(memberDetails[0]?.subscriptionbalance) - (parseInt(subscriptionTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                            ) : (
                                                subscriptionTrans > 0 ? (parseInt(memberDetails[0]?.subscriptionbalance) - parseInt(subscriptionTrans)).toFixed(2) : DeductRadio === "SubscriptionDeduct" ? (parseInt(memberDetails[0]?.subscriptionbalance) - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(memberDetails[0]?.subscriptionbalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.subscriptionbalance)).toFixed(2)
                                            ): DeductRadio === "SubscriptionDeduct" ?(parseInt(memberDetails[0]?.subscriptionbalance) - ( (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2):parseInt(parseInt(memberDetails[0]?.subscriptionbalance)).toFixed(2)
                                        ) : parseInt(memberDetails[0]?.subscriptionbalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.subscriptionbalance)).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && parseInt(memberDetails[0]?.subscriptionbalance) > 0 ? (
                                            subscriptionTrans > 0 && DeductRadio === "SubscriptionDeduct" ? (
                                                (parseInt(memberDetails[0]?.subscriptionbalance) - (parseInt(subscriptionTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                            ) : (
                                                subscriptionTrans > 0 ? (parseInt(memberDetails[0]?.subscriptionbalance) - parseInt(subscriptionTrans)).toFixed(2) : parseInt(memberDetails[0]?.subscriptionbalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.subscriptionbalance)).toFixed(2)
                                            )
                                        ) : parseInt(memberDetails[0]?.subscriptionbalance) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.subscriptionbalance)).toFixed(2)
                                    )}
                                </Typography>
                            </Typography>

                            <Typography>
                                Specific Deposit <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                    RM {radio === "Part" ? (
                                        memberDetails.length > 0 &&selectedSdbal > 0 ? (
                                            specificDeposit==true?specificDepositTrans > 0 && DeductRadio === "SpecificDepositDeduct" && specificDeposit ? (
                                                parseFloat(specificDepositTrans)+parseFloat(editedTransaction.processingfee) != parseFloat(selectedSdbal)?(selectedSdbal- (parseInt(specificDepositTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2):"0.00"
                                            ) : (
                                                specificDepositTrans > 0 && specificDeposit ? (selectedSdbal- parseInt(specificDepositTrans)).toFixed(2) : DeductRadio === "SpecificDepositDeduct" ? (selectedSdbal- (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : "0.00"
                                            ):DeductRadio === "SpecificDepositDeduct"?(selectedSdbal- ((editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2):parseInt(selectedSdbal).toFixed(2)
                                        ) : "0.00"
                                    ) : (
                                        memberDetails.length > 0 && selectedSdbal> 0 ? (
                                            specificDepositTrans > 0 && DeductRadio === "SpecificDepositDeduct" && specificDeposit ? (
                                                (selectedSdbal- (parseInt(specificDepositTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                            ) : (
                                                specificDepositTrans > 0 ? (selectedSdbal- parseInt(specificDepositTrans)).toFixed(2) : "0.00"
                                            )
                                        ) : "0.00"
                                    )}
                                </Typography>
                            </Typography>

                            <Typography>
                                Bonus <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                    RM {radio === "Part" ? (
                                            memberDetails.length > 0 && parseInt(parseInt(memberDetails[0]?.bonus)) > 0 ? (
                                                bonus==true?bonusTrans > 0 && DeductRadio === "BonusDeduct" ? (
                                                    (parseInt(memberDetails[0]?.bonus) - (parseInt(bonusTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                                ) : (
                                                    bonusTrans > 0 ? (parseInt(memberDetails[0]?.bonus) - parseInt(bonusTrans)).toFixed(2) : DeductRadio === "BonusDeduct" ? (parseInt(memberDetails[0]?.bonus) - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0)).toFixed(2) : parseInt(memberDetails[0]?.bonus) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.bonus)).toFixed(2)
                                                ): DeductRadio === "BonusDeduct" ?(parseInt(memberDetails[0]?.bonus) - ((editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2):(parseInt(memberDetails[0]?.bonus)).toFixed(2)
                                            ) : parseInt(memberDetails[0]?.bonus) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.bonus)).toFixed(2)
                                        ) : (
                                            memberDetails.length > 0 && parseInt(memberDetails[0]?.bonus) > 0 ? (
                                                bonusTrans > 0 && DeductRadio === "BonusDeduct" ? (
                                                    (parseInt(memberDetails[0]?.bonus) - (parseInt(bonusTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + (editedTransaction.processingfee !== ''?parseInt(editedTransaction.processingfee):0))).toFixed(2)
                                                ) : (
                                                    bonusTrans > 0 ? (parseInt(memberDetails[0]?.bonus) - parseInt(bonusTrans)).toFixed(2) : parseInt(memberDetails[0]?.bonus) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.bonus)).toFixed(2)
                                                )
                                            ) : parseInt(memberDetails[0]?.bonus) == null ? "0.00" : parseInt(parseInt(memberDetails[0]?.bonus)).toFixed(2)
                                        )}
                                </Typography>
                            </Typography>

                            <Typography>
                                Total <Typography sx={{ color: "var(--pallate-green-500, #288d2c)", display: "inline-block", fontWeight: "700", width: "150px" }}>
                                RM {
                                    radio === "Part" ? (
                                        (
                                        (memberDetails?.length > 0
                                            ? (
                                            (parseInt(memberDetails[0]?.sharebalance) > 0 ? parseFloat(parseInt(memberDetails[0]?.sharebalance)) : 0) +
                                            (parseInt(parseInt(memberDetails[0]?.subscriptionbalance)  )> 0 ? parseFloat(parseInt(memberDetails[0]?.subscriptionbalance) ) : 0) +
                                            (specificDeposit && selectedSdbal > 0 ? parseFloat(selectedSdbal) : 0) +
                                            (parseInt(memberDetails[0]?.bonus) > 0 ? parseFloat(parseInt(memberDetails[0]?.bonus)) : 0)
                                            ) : 0
                                        ) - (
                                            (share==true?sharetrans > 0 ? parseFloat(sharetrans) : 0:0) +
                                            (subscription==true?subscriptionTrans > 0 ? parseFloat(subscriptionTrans) : 0:0) +
                                            (specificDeposit ? (specificDepositTrans > 0 ? parseFloat(specificDepositTrans) : 0) : 0) +
                                            (bonus==true?bonusTrans > 0 ? parseFloat(bonusTrans) : 0:0) +
                                            (DeductRadio === "ShareDeduct" ? 
                                            (parseFloat(editedTransaction.insurance || 0) + parseFloat(editedTransaction.processingfee || 0)) : 0) +
                                            (DeductRadio === "SubscriptionDeduct" ? 
                                            (parseFloat(editedTransaction.insurance || 0) + parseFloat(editedTransaction.processingfee || 0)) : 0) +
                                            (DeductRadio === "SpecificDepositDeduct" ? 
                                            (parseFloat(editedTransaction.insurance || 0) + parseFloat(editedTransaction.processingfee || 0)) : 0) +
                                            (DeductRadio === "BonusDeduct" ? 
                                            (parseFloat(editedTransaction.insurance || 0) + parseFloat(editedTransaction.processingfee || 0)) : 0)
                                        )
                                        ).toFixed(2)
                                    ) : "0.00"
                                    }
                                </Typography>
                            </Typography>

                        </Box>
                    </DialogContent>
                    <DialogContent sx={{ padding: "20px 20px 0px 0px", maxWidth: "40%", marginTop: "-78px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: "10px 0px 0px 0px" }}>
                                <Box sx={{ width: "50%", height: "35px", background: "whitesmoke", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderBottom: toggle == "SD" ? "2px solid var(--pallate-green-500, #288d2c)" : "none", borderRadius: "10px 10px 0px 0px" }} onClick={() => setToggle("SD")}>
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>Specific Deposit</FormLabel>
                                </Box>
                                <Box sx={{ width: "50%", height: "35px", background: "whitesmoke", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderBottom: toggle == "LN" ? "2px solid var(--pallate-green-500, #288d2c)" : "none", borderRadius: "10px 10px 0px 0px" }} onClick={() => setToggle("LN")}>
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>Active Loan[s]</FormLabel>
                                </Box>
                            </Box>
                            {toggle == "SD" &&
                                <Box>
                                    <DataTable className="custom-datatable" value={SDlist} rows={5} style={{ backgroundColor: "#F1F5F9", height: "100%" }} scrollable scrollHeight="250px" columnfinalMembersizeMode="expand" >
                                        {sdColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                </Box>}
                            {toggle == "LN" &&
                                <><Box>
                                    <DataTable value={loan} rows={5} style={{ backgroundColor: "#F1F5F9", height: loan.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                        {ColumnVisible.filter(col => col.visible).map((col, ind) => <Column key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                </Box>
                                    <Box>
                                        <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Loans Above</FormLabel>
                                        <DataTable value={Guarantor} rows={5} style={{ backgroundColor: "#F1F5F9", height: Guarantor.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                            {GuarantorColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                        </DataTable>
                                    </Box>
                                    <Box>
                                        <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Following Members[s]</FormLabel>
                                        <DataTable value={guarantorForList} rows={5} style={{ backgroundColor: "#F1F5F9", height: guarantorForList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                            {mgColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                        </DataTable>
                                    </Box>

                                </>}
                        </Box>
                    </DialogContent>

                </Box>
                <DialogActions sx={{ pl: 3, pr: 3, display: "flex", justifyContent: errors.balance == '' && errors.member == '' ? "flex-end" : "space-between" }}>
                    {errors.balance !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.balance}</Typography>}
                    {errors.trans !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.trans}</Typography>}
                    {errors.member !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.member}</Typography>}
                    <Box sx={{ display: "flex", gap: "20px", padding: "10px 0px " }}>
                        <Button sx={{ fontWeight: "700" }} onClick={onClose}>Cancel</Button>
                        <button type='submit' style={{ background: "#288d2c", border: "none", width: "100px", height: "40px", borderRadius: "10px", color: "#FFF", fontWeight: "700", cursor: "pointer" }} >SAVE</button></Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};


// Helper function to create a new transaction object template
const createNewTransaction = () => ({
    memberId: '',
    bank: null,
    ChequeNo: '',
    transtype: '',
    narration: '',
    insurance: 0,
    processingfee: 0,
    transdetailsid: '',
    sd_id:'',
    memberDetails: {
        memberName: '',
        oldIcNo: '',
        newIcNo: '',
        state: '',
        estate: '',
        accountno: '',
        mobileno:'',
        email:'',
        membank:'',
        sd_list:[],
    },
});


export const Payments = () => {


    const [transactions, setTransactions] = useState([]);
    const [finatransactions, setFinatransactions] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [transactionTypes, setTransactionTypes] = useState([{ id: 21, name: 'Share' }, { id: 22, name: 'Subscription' }, { id: 24, name: 'SpecificDeposit' }]);
    const [successMessage, setSuccessMessage] = useState('');
    const [exportCSV, setExportCSV] = useState([])
    const [bankCSV, setBankCSV] = useState([])
    const [bulkCSV, setBulkCSV] = useState([])
    const [originbulkCSV, setOriginBulkCSV] = useState([])
    const [originbankCSV, setOriginBankCSV] = useState([])
    const [loanList,setLoanList ] = useState([])
    const [guarantorList,setGuarantorList ] = useState([])
    const [specificList,setspecificList ] = useState([])
    const [guarantorForList,setguarantorForList ] = useState([])
    const [banktempCSV, setBankTempCSV] = useState([])
    const [estateList, setEstateList] = useState([])
    const [addTransRestrict, setAddTransRestrict] = useState(false)
    const [downloadexcel, setdownloadexcel] = useState(false)
    const [bulkno, setBulkNo] = useState('')
    const [searchbulkdialogopen, setSearchBulkDialogopen] = useState(false)
    const [editbutton, setEditButton] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [removedTrans, setRemovedTrans] = useState([]);
    const isMobile = useMediaQuery({ maxWidth: 600 })
    // Fetch initial data for banks on component mount
    useEffect(() => {
        const fetchBanksAndTypes = async () => {
            setLoading(true);
            try {
                const bankResponse = await CustomAxios.get(`oasis/form_details/`);
                setBanks(bankResponse.data.bankName); // Assuming the response structure
                setEstateList(bankResponse.data.estateList)
                //console.log("bank info", bankResponse.data.bankName);
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
            setLoading(false);
        };
        fetchBanksAndTypes();
    }, []);
    // Function to fetch member details

    const onRemove = (index) => {
        const updatedTransactions = transactions.filter((_, i) => i !== index);
        setTransactions(updatedTransactions);
    };
    const handlprintAll = async () => {
        try {
            let printurl;
            printurl = `oasis/download-Wreceipt-all/${bulkno}`;
            const response = await CustomAxios.get(printurl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
                console.log(fileURL)
                // Open the PDF in a new window/tab
                // const win =
                window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                // win.onload = () => {
                //     win.focus();
                //     win.print();
                // };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    }

    const handleFetchAndPrintReceipt = async (receiptId, type) => {
        try {
            // URL to your Django view that generates the PDF
            let receiptUrl;
            // if(type == "single"){
            receiptUrl = `oasis/download-Wreceipt/${receiptId}`;
            // }
            // else{
            //     receiptUrl = `${process.env.REACT_APP_OASIS_BE_URL}oasis/download-Wreceipt-all/${receiptId}`;
            // }

            // Fetch the PDF receipt
            const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
                console.log("------------", fileURL)
                // Open the PDF in a new window/tab
                // const win = 
                window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                // window.open(fileURL, )
                // win.onload = () => {
                //     win.focus();
                //     win.print();
                // };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };

    const getLoanGuarantor = async (memberId) => {
        try {
            let payload = { memberid: memberId };
            if(memberId){
                const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberId)}`);
                console.log("gggggggggggggggg", response.data);
                setLoanList(response.data.loan)
                setGuarantorList(response.data.guarantor)
                setspecificList(response.data.sd)
                setguarantorForList(response.data.memberGuarantor)
            }

        } catch (error) {
            console.error("Error fetching member details:", error);
        }
    }
    // Function to fetch member details by memberId
    const getMemberDetails = async (memberId) => {
        //console.log("inside getMemberDetails")
        //console.log("Member ID: ", memberId)

        let payload = {};

        // Directly use memberId to create the payload
        payload = { memberid: memberId };

        // Proceed with the API call
        if (memberId) {
            try {
                const response = await CustomAxios.post(`oasis/getmember/`, payload);
                //setspecificList(response.data.sd)
                getLoanGuarantor(memberId)
                // Handle the response accordingly
                const currentDate = new Date()
                const birthDateObj = new Date(response.data.dob)
                const currentDateObj = new Date(currentDate)
                const timeDiff = currentDateObj - birthDateObj
                const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
                response.data.age = age + 1
                //console.log("Member details fetched successfully", response.data, new Date(response.data.dob).getFullYear());
                return response.data;
                // Update state or perform further actions
            } catch (error) {
                console.error("Error fetching member details:", error);
            }
        } else {
            console.error("No valid memberId provided for fetching member details.");
        }


    };



    // const getMemberDetails = async (index) => {
    //     console.log("inside getMemberDetails")
    //     console.log("index: ", index)
    //     setLoading(true);
    //     // Retrieve the current transaction based on index
    //     const transaction = transactions[index];
    //     // Initialize the payload with an empty object
    //     let payload = {};

    //     // Check which field(s) has/have been provided and adjust the payload accordingly
    //     if (transaction.memberId) {
    //         payload = { memberid: transaction.memberId };
    //     } else if (transaction.memberDetails.newIcNo) {
    //         payload = { icno: transaction.memberDetails.newIcNo };
    //     } else if (transaction.memberDetails.memberName) {
    //         payload = { name: transaction.memberDetails.memberName };
    //     }

    //     // Make sure there's at least one criteria before making the API call
    //     if (Object.keys(payload).length > 0) {
    //         try {
    //             const response = await axios.post(`${process.env.REACT_APP_OASIS_BE_URL}oasis/getmember/`, payload);
    //             // Assuming the API response format aligns with our expectations
    //             const newTransactions = [...transactions];
    //             const { membername, oldicno, newicno, memberid } = response.data;
    //             newTransactions[index].memberDetails = { memberName: membername, oldIcNo: oldicno, newIcNo: newicno, memberId: memberid };
    //             setTransactions(newTransactions);
    //         } catch (error) {
    //             console.error("Error fetching member details:", error);
    //             // Optionally handle errors, such as setting an error state or displaying a message
    //         }
    //     } else {
    //         // Handle the case where no valid input was given
    //         console.error("No valid input provided for fetching member details.");
    //     }

    //     setLoading(false);
    // }; 

    const openDialogToAdd = (index) => {
        typeof index == "number" ? setCurrentTransaction({ ...transactions[index], index }) : setCurrentTransaction(createNewTransaction());
        setDialogOpen(true);
    };

    const openDialogToEdit = (index) => {
        //console.log(transactions[index])
        setCurrentTransaction({ ...transactions[index], index }); // Load the current transaction for editing
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleTransactionChange = (name, value) => {
        setCurrentTransaction((prev) => ({ ...prev, [name]: value }));
    };

    // const saveTransaction = () => {
    //     if (currentTransaction.index >= 0) {
    //         // Update existing transaction
    //         const updatedTransactions = transactions.map((t, idx) => idx === currentTransaction.index ? currentTransaction : t);
    //         setTransactions(updatedTransactions);
    //     } else {
    //         // Add new transaction
    //         setTransactions([...transactions, currentTransaction]);
    //     }
    //     setDialogOpen(false);
    // };

    const saveTransaction = (updatedTransaction) => {
        //console.log(currentTransaction,updatedTransaction)
        if (typeof currentTransaction.index === "number") {
            const itemTosplice = transactions.filter((data) => data.id == currentTransaction.id)
            const index = transactions.findIndex(item => item.id === itemTosplice[0].id);
            //console.log(index,currentTransaction.index,itemTosplice.length, ...updatedTransaction)
            transactions.splice(index, itemTosplice.length, ...updatedTransaction)
        }
        else {
            const filtered = transactions.filter((data) => data.id !== currentTransaction.id)
            setTransactions([...filtered, ...updatedTransaction]);
        }


        setDialogOpen(false);
    };

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    // Submit all transactions
    const handleSubmitAllTransactions = async (event) => {
        setdownloadexcel(true)
        //console.log("inside submitAllTransactions")
        //console.log(transactions)
        //setFinatransactions(transactions);
        event.preventDefault();
        
        // const newExportArray = exportCSV.slice(0, -1);
        // setExportCSV(newExportArray)
        // const newBankArray = bankCSV.slice(0, -1);
        // setBankCSV(newBankArray)
        // setExportCSV([])
        // setBankCSV([])
        setIsSubmitting(true);

        setLoading(true);
            const dateformate = (date) =>{
                let formattedDate
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');
                    formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
                    console.log(formattedDate)
                    return formattedDate
                }
            
        // Prepare the payload by mapping over transactions and adjusting the structure
        const payload = transactions.map(transaction => ({
            transdate: dateformate(transaction.transdate),
            memberid: parseInt(transaction.memberId),
            transtypeid: parseInt(transaction.transtype),
            transamount: parseInt(transaction.transamount), // Ensure this is a number
            bankname: transaction.bank.trim(), // Remove any trailing spaces
            narration: transaction.narration,
            transmasterid: parseInt(-5), // Assuming a static value here; adjust as needed
            insurance: parseInt(transaction.insurance), // Ensure this is a number
            processingfee: parseInt(transaction.processingfee), // Ensure this is a number
            transtotalamount: parseInt(transaction.transtotalamount), // Ensure this is a number
            chequeno: transaction.ChequeNo,
            sd_id:transaction.sd_id, // Ensure this is a number
            withdrawaltype: transaction.withdrawaltype,
            createdby: localStorage.getItem('username')
            // transdate: transaction.transdate, // Ensure this is a number
        }));

        try {
            // Adjust endpoint and payload structure as needed
            //console.log(payload)
            setLoading(true)
            const response = await CustomAxios.post(`oasis/withdrawal/`, payload);
            //console.log(response.data);
            transactions.map(async(transaction) => {
                if (transaction){
                const payload={
                    memberid:transaction.memberId,
                    transdate:dateformate(transaction.transdate),
                }
                const response = await CustomAxios.post(`oasis/terminatemember/`, payload);
            }
            })

            if (response.data.success) {
                setLoading(false);
                setBulkNo(response.data.success[0].remarks)
                // Assuming response.data.success is an array with corresponding transactions including transdetailsid
                const updatedTransactions = transactions.map((transaction, index) => {
                    // Find the corresponding response transaction by index or other criteria
                    const responseTransaction = response.data.success.find(rt =>
                        rt.memberid === parseInt(transaction.memberId) &&
                        rt.transamount === parseInt(transaction.transamount) &&
                        rt.transtypeid === parseInt(transaction.transtype)
                    );
                    // If found, append transdetailsid to the transaction
                    return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
                });

                // Update transactions state with transdetailsid
                setTransactions(updatedTransactions.sort((a,b)=>a.transdetailid - b.transdetailid));
                const final = [...updatedTransactions]
                setFinatransactions(final.sort((a,b)=>a.transdetailid - b.transdetailid));
                //GetCsvValues(final)
                //console.log(exportCSV)
                //console.log(bankCSV)
                //console.log(transactions) 
                setSuccessMessage('All transactions have been submitted successfully!');
                setAddTransRestrict(true)
            }

            // setTransactions([])
            // Handle success (e.g., show message, clear form)
            // setTransactions([createNewTransaction()]);
        } catch (error) {
            //console.error("Error submitting transactions:", error);
            setLoading(false);
        }
        setLoading(false);
    };
    function formatDate() {
        const day = new Date().getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = (new Date().getMonth() + 1).toString().padStart(2, '0'); // Get the month (adding 1 to account for zero-based index) and pad with leading zero if needed
        const year = new Date().getFullYear(); // Get the full year
        return `${day}-${month}-${year}`; // Combine day, month, and year with hyphens
    }

    const excel_formatDate = (date) => {
        if(typeof(date)!="string"){
            const new_date = new Date(date)
         const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
         const day = String(new_date.getDate()).padStart(2, '0');
         const monthIndex = String(new_date.getMonth() + 1).padStart(2, '0');
         const month = months[monthIndex];
         const year = new_date.getFullYear();
         return `${day}-${monthIndex}-${year}`; 
         }
         else{
             const new_date = new Date(date)
        const dd = String(new_date.getUTCDate()).padStart(2, '0');
        const mm = String(new_date.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
        const yy = String(new_date.getUTCFullYear());
        
        return `${dd}-${mm}-${yy}`;
         }
       
    };

    useEffect(() => {
        //console.log(finatransactions)
        const bankList = banks.filter((bank)=>bank.bankname!==null)
        if (finatransactions.length > 0) {
            let pfee = 0
            let ins = 0
            //console.log("we are here")
        for (let i=0;i<=finatransactions.length-1; i++){
            // console.log(finatransactions[i])
            //console.log(finatransactions[i])
            const csvValues ={
                MNo:'',
                Name:'',
                State:'',
                Estate:'',
                Narration:'',
                TransactionDate:'',
                Cheque_No:'',
                SH_PV_No:'',
                SH_Amt_RM:0,
                SUBS_PV_No:'',
                SUBS_Amt_RM:0,
                Bonus_PV_No:'',
                Bonus_Amt_RM:0,
                SD_PV_No:'',
                SD_Amt_RM:0,
                BR_PV_No:'',
                BR_Amt_RM:0,
                P_Fee_RM:0,
                DBS_Insurance_RM:0,
                Gst_RM:0,
                PF_Deduction:'',
                Total_RM:0,
                Total_Deduction_RM:0,
                Cheque_Amount_RM:0
            
            };
        
            const bankcsvValues = {
                Payment_Type_IBG_PBB_REN:'',
                Bene_Account_No:'',
                BIC:'',
                Bene_Full_Name:'',
                ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT:'',
                Bene_Identification_No:'',
                Payment_Amount:0,
                Recipient_Reference:'',
                Other_Payment_Details:'',
                Bene_Email:'',
                Bene_Mobile_No:'',
                Joint_Bene_Name:'',
                Joint_Beneficiary_Identification_No:'',
                Joint_ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT:'',
                Email_Content_Line_1:'',
                Email_Content_Line_2:'',
                Email_Content_Line_3:'',
                Email_Content_Line_4:'',
                Email_Content_Line_5:'',
            }
            // pfee+=parseInt(finatransactions[i].processingfee)
            // ins+=parseInt(finatransactions[i].insurance)
            csvValues.MNo = finatransactions[i].memberId;
            csvValues.Name = finatransactions[i].memberDetails.memberName;
            csvValues.Estate = finatransactions[i].memberDetails.estate;
            const Findstate = estateList.filter((data)=> data.estatename == finatransactions[i].memberDetails.estate )
            if (Findstate.length>0){
                csvValues.State =  Findstate[0].state;}
            console.log(finatransactions[i].transdetailsid)
            if (finatransactions[i].transtype==21||finatransactions[i].transtypeid==21){
            csvValues.SH_Amt_RM = finatransactions[i].transtotalamount
            csvValues.SH_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==22||finatransactions[i].transtypeid==22){
                csvValues.SUBS_Amt_RM = finatransactions[i].transtotalamount
                csvValues.SUBS_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==24||finatransactions[i].transtypeid==24){
                csvValues.SD_Amt_RM = finatransactions[i].transtotalamount
                csvValues.SD_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==34||finatransactions[i].transtypeid==34){
                csvValues.Bonus_Amt_RM = finatransactions[i].transtotalamount
                csvValues.Bonus_PV_No =finatransactions[i].transdetailsid}
            csvValues.Narration = finatransactions[i].narration;
            csvValues.DBS_Insurance_RM = parseInt(finatransactions[i].insurance!=""?finatransactions[i].insurance!=null?finatransactions[i].insurance:0:0)
            csvValues.TransactionDate = excel_formatDate(finatransactions[i].transdate);
            csvValues.Cheque_No = finatransactions[i].bank.split(" ")[0] + " " + finatransactions[i].ChequeNo
            csvValues.P_Fee_RM = parseInt(finatransactions[i].processingfee!=""?finatransactions[i].processingfee!=null?finatransactions[i].processingfee:0:0)
            csvValues.Total_Deduction_RM = csvValues.P_Fee_RM + csvValues.DBS_Insurance_RM
            csvValues.Total_RM = parseInt(csvValues.SH_Amt_RM) + parseInt(csvValues.SUBS_Amt_RM) + parseInt(csvValues.SD_Amt_RM) + parseInt(csvValues.Bonus_Amt_RM)
            csvValues.Cheque_Amount_RM = parseInt(csvValues.Total_RM) - parseInt(csvValues.Total_Deduction_RM)
            bankcsvValues.Payment_Amount =    (parseInt(csvValues.SH_Amt_RM) + parseInt(csvValues.SUBS_Amt_RM) + parseInt(csvValues.SD_Amt_RM) + parseInt(csvValues.Bonus_Amt_RM))- parseInt(csvValues.Total_Deduction_RM)
            bankcsvValues.Bene_Account_No = finatransactions[i].memberDetails.accountno+""
            bankcsvValues.Bene_Full_Name = finatransactions[i].memberDetails.memberName
            bankcsvValues.Bene_Email = finatransactions[i].memberDetails.email
            bankcsvValues.Bene_Mobile_No = finatransactions[i].memberDetails.mobileno
            bankcsvValues.Bene_Identification_No = finatransactions[i].memberDetails.newIcNo
            bankcsvValues.Recipient_Reference = "WITHDRAWAL"
            bankcsvValues.Other_Payment_Details = finatransactions[i].memberId
            bankcsvValues.ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT = "NI"
            console.log(banks)
            for (let j=0;j<bankList.length-1;j++){
                if(bankList[j].bankname.trim()==finatransactions[i].memberDetails.membank){
                    bankcsvValues.BIC = bankList[j].swiftcode
                    if (bankList[j].swiftcode=="PBBEMYKL"){
                        bankcsvValues.Payment_Type_IBG_PBB_REN = "PBB"
                    }
                    else{
                        bankcsvValues.Payment_Type_IBG_PBB_REN = "IBG"
                    }
                   
                }
            }
           
            setExportCSV(prev => [...prev, csvValues]);
        setBankCSV(prev => [...prev, bankcsvValues])
            
            
        }}
        
        // setExportCSV((prev) => [
        //     ['KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
        //     ,...prev
        //   ]);

    },[finatransactions])

    // useEffect(()=> {
    //     const combinedDictList=[]
    // originbulkCSV.forEach((dict, index) => {
    //   // Check if this dictionary has already been combined
    //   if (!dict._combined) {
    //     // Create a new combined dictionary
    //     const combinedDict = { ...dict };

    //     // Search for other dictionaries with the same key value
    //     for (let i = index + 1; i < originbulkCSV.length; i++) {
    //       if (originbulkCSV[i].MNo === dict.MNo) {
    //         if (dict.SH_Amt_RM==0 && originbulkCSV[i].SH_Amt_RM>0){
    //             dict.SH_Amt_RM=originbulkCSV[i].SH_Amt_RM
    //             dict.SH_PV_No=originbulkCSV[i].SH_PV_No
    //         }
    //         if (dict.SUBS_Amt_RM==0 && originbulkCSV[i].SUBS_Amt_RM>0){
    //             dict.SUBS_Amt_RM=originbulkCSV[i].SUBS_Amt_RM
    //             dict.SUBS_PV_No=originbulkCSV[i].SUBS_PV_No
    //         }
    //         if (dict.SD_Amt_RM==0 && originbulkCSV[i].SD_Amt_RM>0){
    //             dict.SD_Amt_RM=originbulkCSV[i].SD_Amt_RM
    //             dict.SD_PV_No=originbulkCSV[i].SD_PV_No
    //         }
    //         if (dict.DBS_Insurance_RM==0 && originbulkCSV[i].DBS_Insurance_RM>0){
    //             dict.DBS_Insurance_RM=originbulkCSV[i].DBS_Insurance_RM
    //         }
    //         if (dict.DBS_Insurance_RM==0 && originbulkCSV[i].DBS_Insurance_RM>0){
    //             dict.DBS_Insurance_RM=originbulkCSV[i].DBS_Insurance_RM
    //         }
    //         dict.P_Fee_RM = originbulkCSV[i].processingfee
    //         dict.Total_Deduction_RM = originbulkCSV[i].P_Fee_RM + originbulkCSV[i].DBS_Insurance_RM
    //         dict.Total_RM = originbulkCSV[i].SH_Amt_RM + originbulkCSV.SUBS_Amt_RM + originbulkCSV[i].SD_Amt_RM + originbulkCSV[i].Total_Deduction_RM
    //         dict.Cheque_Amount_RM = originbulkCSV[i].Total_RM -originbulkCSV[i].Total_Deduction_RM

    //       }
    //     }

    //     // Add the combined dictionary to the new list
    //     combinedDictList.push(combinedDict);
    //   }
    // })

    // // Update the state with the combined list
    // setExportCSV(combinedDictList)
    // setBankCSV(originbankCSV)
    // },[originbankCSV, originbulkCSV])

    const formatTodayDate = () => {
        const date = new Date()
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate();
        const month = String(date.getMonth()+1).padStart(2,'0');
        // const month = months[monthIndex];
        const year = date.getFullYear();
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        // const seconds = date.getSeconds();
        //console.log(`${day}/${month}/${year} ${hours}:${minutes} ${seconds}`)
        return `${day}-${month}-${year}`;
    };

    const exportToExcel = (data, fileName) => {
        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(data);
      
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
        // Write the workbook and trigger a download
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      };
      

    const handleBulkEdit = async (e) => {
        e.preventDefault()
        setdownloadexcel(false)
        setLoading(true);
        try {
            const response = await CustomAxios.get(`oasis/bulk-withdrawal-edit/?bulkno=${parseInt(bulkno)}`);
            console.log(response)
            if (response.data.data) {
                setLoading(false);
                console.log(transactions)
                setSearchBulkDialogopen(false);
                const updatedTransaction = response.data.data
                updatedTransaction.map((transaction) =>
                    transaction["id"] = transaction.memberid)
                updatedTransaction.map((trans) =>
                    trans.bank = trans.bank + "                                               ")
                const updatedTransactions = transactions.map((transaction, index) => {
                    // Find the corresponding response transaction by index or other criteria
                    const responseTransaction = response.data.data.find(rt =>
                        rt.memberid === parseInt(transaction.memberId) &&
                        rt.transamount === parseInt(transaction.transamount) &&
                        rt.transtypeid === parseInt(transaction.transtype) 
                    );
                    console.log(responseTransaction)
                    // If found, append transdetailsid to the transaction
                    return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
                });
                //console.log(updatedTransactions)
                setTransactions(updatedTransaction.sort((a,b)=>a.transdetailid - b.transdetailid));
                const final = [...response.data.data]
                setFinatransactions(final.sort((a,b)=>a.transdetailid - b.transdetailid));
                setIsSubmitting(false)
                setEditButton(true)
            }
        } catch (error) {
            console.error("Error submitting transactions:", error);
            setLoading(false);
        }
    }
    const HanldeSearchBulk = () => {
        setdownloadexcel(false)
        setTransactions([])
        setFinatransactions([])
        setBankCSV([])
        setExportCSV([])
        setSearchBulkDialogopen(true)
    }

    const handleEditAllTransactions = async () => {
        setBankCSV([])
        setExportCSV([])
        setIsSubmitting(false);
        transactions.map(async(transaction) => {
            console.log(transaction)
            if (transaction.radio=="Full"){
            const payload={
                memberid:transaction.memberId,
                transdate:transaction.transdate,   
            }
            const response = await CustomAxios.post(`oasis/terminatemember/`, payload);
        }
        })
    try{
        setLoading(true);
        transactions.map((trans)=>{
            trans.updatedby = localStorage.getItem('username')
           if(typeof(trans.transdate)!=='string'){
            const year = trans.transdate?.getFullYear();
        const month = String(trans.transdate?.getMonth() + 1).padStart(2, '0');
        const day = String(trans.transdate?.getDate()).padStart(2, '0');
        const hours = String(trans.transdate?.getHours()).padStart(2, '0');
        const minutes = String(trans.transdate?.getMinutes()).padStart(2, '0');
        const seconds = String(trans.transdate?.getSeconds()).padStart(2, '0');
        trans.transdate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    }
})
    const payload={
        transactions:transactions,
        removed_transactions:removedTrans
    }
    setdownloadexcel(true)
    const response = await CustomAxios.post(`oasis/bulk-withdrawal-edit/`, payload);
    console.log(response)
    if (response.data.success){
        setLoading(false);
        console.log(transactions)
        const updatedTransactions = transactions.map((transaction, index) => {
            // Find the corresponding response transaction by index or other criteria
            const responseTransaction = response.data.success.find(rt => 
                rt.memberid === parseInt(transaction.memberId) && 
                rt.transamount === parseInt(transaction.transamount) && 
                rt.transtypeid === parseInt(transaction.transtype)
            );
            console.log(responseTransaction)
            // If found, append transdetailsid to the transaction
            return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
        });
        setLoading(false);
        console.log(updatedTransactions)
        setTransactions(updatedTransactions.sort((a,b)=>a.transdetailid - b.transdetailid)) ;
        const final = [...updatedTransactions]
        setFinatransactions(final.sort((a,b)=>a.transdetailid - b.transdetailid));
        //GetCsvValues(final)
        //console.log(exportCSV)
        //console.log(bankCSV)
        console.log(transactions) 
        setSuccessMessage('All transactions have been Editted successfully!');
    }
    
}catch (error) {
    console.error("Error submitting transactions:", error);
    setLoading(false);
}
}

    useEffect(() => {
        let sh_total = 0;
        let subs_total = 0;
        let sd_total = 0;
        let bonus_total = 0;
        let br_total = 0;
        let pr_total = 0;
        let ins_total = 0;
        let gst_total = 0;
        let overall_total = 0;
        let overall_deduct = 0;
        let overall_cheque = 0;
        if (exportCSV.length > 0) {
            const arrayedownload = exportCSV.reduce((acc, data) => {
                const existingObjIndex = acc.findIndex(item => item.MNo === data.MNo);
                if (existingObjIndex !== -1) {
                    acc[existingObjIndex].Narration +="+"+data.Narration
                    acc[existingObjIndex].SH_Amt_RM = parseInt(acc[existingObjIndex].SH_Amt_RM )+parseInt(data.SH_Amt_RM)
                    acc[existingObjIndex].SUBS_Amt_RM =parseInt(acc[existingObjIndex].SUBS_Amt_RM) + parseInt(data.SUBS_Amt_RM)
                    acc[existingObjIndex].SD_Amt_RM = parseInt(acc[existingObjIndex].SD_Amt_RM)+parseInt(data.SD_Amt_RM)
                    acc[existingObjIndex].Bonus_Amt_RM = parseInt(acc[existingObjIndex].Bonus_Amt_RM)+parseInt(data.Bonus_Amt_RM)
                    acc[existingObjIndex].BR_Amt_RM = parseInt(acc[existingObjIndex].BR_Amt_RM )+parseInt(data.BR_Amt_RM)
                    acc[existingObjIndex].P_Fee_RM = parseInt(acc[existingObjIndex].P_Fee_RM) +parseInt(data.P_Fee_RM)
                    acc[existingObjIndex].DBS_Insurance_RM = parseInt(acc[existingObjIndex].DBS_Insurance_RM)+parseInt(data.DBS_Insurance_RM)
                    acc[existingObjIndex].Gst_RM = parseInt(acc[existingObjIndex].Gst_RM )+parseInt(data.Gst_RM)
                    acc[existingObjIndex].Total_RM = parseInt( acc[existingObjIndex].Total_RM )+parseInt(data.Total_RM)
                    acc[existingObjIndex].Total_Deduction_RM = parseInt(acc[existingObjIndex].Total_Deduction_RM)+parseInt(data.Total_Deduction_RM)
                    acc[existingObjIndex].Cheque_Amount_RM = parseInt(acc[existingObjIndex].Cheque_Amount_RM )+parseInt(data.Cheque_Amount_RM)
                    if(data.SH_PV_No!=""){
                        acc[existingObjIndex].SH_PV_No = data.SH_PV_No
                    }
                    if(data.SUBS_PV_No!=""){
                        acc[existingObjIndex].SUBS_PV_No = data.SUBS_PV_No
                    }
                    if(data.SD_PV_No!=""){
                        acc[existingObjIndex].SD_PV_No = data.SD_PV_No
                    }
                    if(data.Bonus_PV_No!=""){
                        acc[existingObjIndex].Bonus_PV_No = data.Bonus_PV_No
                    }
                   
                } else {
                    acc.push({ ...data }); 
                }
                sh_total += parseInt(data.SH_Amt_RM)
                subs_total += parseInt(data.SUBS_Amt_RM)
                sd_total += parseInt(data.SD_Amt_RM)
                bonus_total += parseInt(data.Bonus_Amt_RM)
                br_total += parseInt(data.BR_Amt_RM)
                pr_total += parseInt(data.P_Fee_RM)
                ins_total += parseInt(data.DBS_Insurance_RM)
                gst_total += parseInt(data.Gst_RM)
                overall_total += parseInt(data.Total_RM)
                overall_deduct += parseInt(data.Total_Deduction_RM)
                overall_cheque += parseInt(data.Cheque_Amount_RM)
                return acc;
            }, []); 
            let combined_earray = [
                ["KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)"], // String
                ["Withdrawal Bulk List "],
                ["Bulk # :", bulkno],
                [],
                [],
                ["Print Date:", formatTodayDate()],
                [],
                [],
                // Empty row for spacing
                Object.keys(arrayedownload[0]), // Headers
                ...arrayedownload.map(obj => Object.values(obj)),
                [],
                [],
                ["", "", "", "", "", "", "TOTAL:", "", sh_total, "", subs_total, "", bonus_total, "", sd_total, "", br_total, pr_total, ins_total, gst_total, "", "RM" + overall_total + ".00", "RM" + overall_deduct + ".00", "RM" + overall_cheque + ".00"]]
            // Object values
            setBulkCSV(combined_earray)
        }

        if (bankCSV.length > 0) {
            const arraybdownload = bankCSV.reduce((acc, data) => {
                const existingObjIndex = acc.findIndex(item => item.Other_Payment_Details == data.Other_Payment_Details);
                if (existingObjIndex !== -1) {
                    acc[existingObjIndex].Payment_Amount = parseInt(acc[existingObjIndex].Payment_Amount)+parseInt(data.Payment_Amount)
                } else {
                    acc.push({ ...data }); 
                }
                return acc;
            }, []); 
            let combined_barray = [
                [], // String
                [],
                [],
                [],
                [],
                ["", "", "Payment Date", formatTodayDate()],
                [],
                [],
                // Empty row for spacing
                Object.keys(arraybdownload[0]), // Headers
                ...arraybdownload.map(obj => Object.values(obj)), // Object values
            ];
            setBankTempCSV(combined_barray)
        }
    }, [exportCSV, bankCSV])

    const handlebulkexcel = (exportCSV) => {
        const uniqueArr = exportCSV.filter((obj, index, self) =>
            index === self.findIndex((o) => (
                Object.keys(obj).every((key) => obj[key] === o[key])
            ))

        );
        //console.log(uniqueArr)
        setBulkCSV(uniqueArr)
    }

    const handlebankexcel = (bankCSV) => {
        const uniqueArr = bankCSV.filter((obj, index, self) =>
            index === self.findIndex((o) => (
                Object.keys(obj).every((key) => obj[key] === o[key])
            ))

        );
        //console.log(uniqueArr)
        setBankTempCSV(uniqueArr)
    }
    const handleclear = () => {
        setAddTransRestrict(false)
        setdownloadexcel(false)
        setBulkNo('')
        setEditButton(false)
        setTransactions([])
        setBankCSV([])
        setExportCSV([])
        setIsSubmitting(false);

    }
    console.log(bulkCSV)
    console.log(banktempCSV)
    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            handleBulkEdit(e)
        }
       }
    return (
        <Box sx={{width:"100%", marginTop:"60px"}}>
            {/* <LinkHeader /> */}
            {isLoading ?
                <Stack rowGap="10px" sx={{ width: "100%", height: "100vh", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Please Wait</Typography>
                    <ProgressBar mode="indeterminate" style={{ width: "25%", height: '6px' }} />
                </Stack> :
                <Box sx={{display:"flex",flexDirection:"column",height:isMobile?"100vh":"calc(100vh - 148px)"}}>
                    <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                        <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                            <Box sx={{maxWidth:"100%",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                <Box sx={{width:"32px",height:"32px"}}>
                                <PaymentsOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                </Box>
                                <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#1E293B",width:"100%"}}>Payments</Typography>
                            </Box>
                            
                            <MuiSearchBar
                                handleEnter={handleEnter}
                                search={bulkno}
                                setSearch={setBulkNo}
                                placeholder="Bulk #"
                            />

                            <Box sx={{ display: "flex", gap: "20px", width:"450px", justifyContent: "flex-end" }}>

                           <MuiButton
                                variant="text"
                                onClick={handleclear}
                                value="Clear"
                                icon={<ClearIcon />}
                                iconsx={{
                                    color: "#64748B"
                                }}
                                btnsx={{
                                    width:"100%"
                                }}
                            />
                            {
                            !editbutton && 
                                <MuiButton
                                type="submit"
                                variant="contained"
                                onClick={handleSubmitAllTransactions}
                                disabled={!isSubmitting ? false : true}
                                value="Submit"
                                btnsx={{
                                    cursor: !isSubmitting ? "pointer" : "not-allowed",
                                    width:"100%"
                                }}
                            />
                            }
                            {
                            editbutton &&
                                  <MuiButton
                                  type="submit"
                                  variant="contained"
                                  onClick={handleEditAllTransactions}
                                  disabled={!isSubmitting ? false : true}
                                  value="Submit"
                                  btnsx={{
                                      cursor: !isSubmitting ? "pointer" : "not-allowed",
                                      width:"100%"
                            }}
                            />
                            }
                             
                            <MuiButton
                                variant="contained" 
                                onClick={openDialogToAdd}
                                value="Add Payments"
                                icon={<PaymentsOutlinedIcon />}
                                disabled={addTransRestrict == false ? false : true}  
                                btnsx={{
                                    cursor: addTransRestrict == false ? "pointer" : "not-allowed",
                                    width:"100%"
                                }}
                                typosx={{
                                    fontSize:"12px"
                                }}
                            />      

                            {/* <Button
                                variant="text"
                                sx={{ maxWidth: "201px", width: "100%", height: "48px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "transparent", textTransform: "none" }}>
                                <ClearIcon sx={{ color: "#64748B" }} />
                                <Typography sx={{ color: "rgb(100, 116, 139)", fontSize: "16px", fontWeight: "400", lineHeight: "24px", fontFamily: "inter" }}
                                    onClick={handleclear}>Clear
                                </Typography>
                            </Button>
                            {!editbutton &&
                                <button
                                    onClick={handleSubmitAllTransactions}
                                    type="submit"
                                    variant="contained"
                                    disabled={!isSubmitting ? false : true}
                                    style={{ border: "none", color: "#FFFFFF", textTransform: "none", width: "204px", height: "48px", borderRadius: "4px", padding: "12px", background: "#288d2c", cursor: !isSubmitting ? "pointer" : "not-allowed" }} >
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}  >Submit</Typography>
                                </button>
                            }
                            {editbutton &&
                                <button
                                    onClick={handleEditAllTransactions}
                                    type="submit"
                                    variant="contained"
                                    disabled={!isSubmitting ? false : true}
                                    style={{ border: "none", color: "#FFFFFF", textTransform: "none", width: "204px", height: "48px", borderRadius: "4px", padding: "12px", background: "#288d2c", cursor: !isSubmitting ? "pointer" : "not-allowed" }} >
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}  >Edit</Typography>
                                </button>
                            } */}
                            {/* <Button 
                            disabled={addTransRestrict == false ? false : true}  
                            onClick={openDialogToAdd} 
                            variant="contained" 
                            sx={{  cursor: addTransRestrict == false ? "pointer" : "not-allowed" ,width:"201px",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}>
                                <PaymentsOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>
                                <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Payments</Typography>
                                </Button> */}
                            </Box>
                            {/* <Button  onClick={searchMemberStatus?()=>clearAllStates():(e)=> handleSignup(e)}  variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PersonAddAltOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Member</Typography></Button> */}
                        </Box>
                    </Box>
                    <Box sx={{padding:"0px 30px", overflow:"Auto"}}>
                        {/* <Typography variant="h4" sx={{ mb: 2 }}>Payment Transactions</Typography> */}
                        <Box sx={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}>
                            {/* <Button startIcon={<SearchIcon />} onClick={HanldeSearchBulk} variant="contained" sx={{ mb: 2, ml: 5, cursor: "pointer" }}>Search</Button> */}
                            <Typography variant="h6" sx={{ mt: 2}}>Bulk No: {bulkno}</Typography>
                            <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                            {downloadexcel && <><Button startIcon={<DownloadIcon />} onClick={(e) => exportToExcel(bulkCSV, "Bulk-transfer.xls")} sx={{ padding: "12px", cursor: "pointer"}}>Bulk Transfer</Button>
                            <Button startIcon={<DownloadIcon />} onClick={(e) => exportToExcel(banktempCSV, "Bank-template")} sx={{ padding: "12px", cursor: "pointer" }}>Bank Template</Button> <Button startIcon={<PrintIcon />} sx={{ padding: "12px", cursor: "pointer" }} onClick={() => handlprintAll()}>Print All</Button></>}
                        </Box>
                        </Box>
                        <TransactionsTable transactions={transactions} onEdit={openDialogToAdd} onRemove={onRemove} handleFetchAndPrintReceipt={handleFetchAndPrintReceipt} />

                        {/* <Box sx={{display:"flex",gap:"20px"}}>
                {!editbutton && <Button onClick={(e)=>handleSubmitAllTransactions(e)} variant="contained" color="primary" >Submit All Transactions</Button>}
                {editbutton && <Button onClick={(e)=>handleEditAllTransactions(e)} variant="contained" color="primary" >Edit All Transactions</Button>}
                <Button onClick={handleclear} variant="contained" color="primary" >Clear</Button>
                </Box> */}

                        {/* <Box sx={{ display: "flex", gap: "20px" }}>
                            {!isSubmitting && (
                                <>
                                    {!editbutton && <Button onClick={handleSubmitAllTransactions} variant="contained" color="primary">Submit All Transactions</Button>}
                                    {editbutton && <Button onClick={handleEditAllTransactions} variant="contained" color="primary">Edit All Transactions</Button>}
                                </>
                            )} */}
                            {/* <Button onClick={handleclear} variant="contained" color="primary">Clear</Button> */}
                        {/* </Box> */}
                    </Box>
                    
                    <Dialog open={searchbulkdialogopen} style={{ width: '100%', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false}>
                        <Stack direction="row" className="fulldiv" sx={{ justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                            <Box style={{ boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 30px 30px", borderRadius: "0px", paddingRight: "24px" }}>
                                <Box sx={{ display: "flex", maxWidth: "669px", alignItems: "center", gap: "8px" }}>
                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bulk No</Typography>
                                    <InputText
                                        value={bulkno}
                                        type="number"
                                        //   /  disabled={Total>=payload.bulkAmount?true:false}
                                        onChange={(e) => {
                                            setBulkNo(e.target.value)
                                            // e.target.value>1200?setTransType(2):setTransType("")
                                        }}
                                        style={{ marginTop: "10px", padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    /></Box>
                                <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-24px", flexDirection: "column" }}>
                                    <Box sx={{ alignSelf: "start", display: "flex", justifyContent: "space-between", width: '100%' }}>


                                        <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "end", cursor: "pointer", textTransform: "none" }} onClick={() => setSearchBulkDialogopen(false)}>

                                            {/* <ClearIcon /> */}
                                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}></Typography>
                                            Close
                                        </Box>
                                        <Button onClick={() => handleBulkEdit()} variant="contained" color="primary" >Edit</Button>
                                    </Box>


                                </Box>
                            </Box>
                        </Stack>
                    </Dialog>
                    {dialogOpen && (
                        <TransactionModal
                            open={dialogOpen}
                            transaction={currentTransaction}
                            onChange={handleTransactionChange}
                            onSave={saveTransaction}
                            onClose={closeDialog}
                            getMemberDetails={getMemberDetails}
                            banks={banks}
                            transInTable={transactions}
                            transactionTypes={transactionTypes}
                            loan={loanList}
                            Guarantor={guarantorList}
                            SDlist={specificList}
                            guarantorForList={guarantorForList}
                            removedTrans={removedTrans}
                            setRemovedTrans={setRemovedTrans}
                            bulkno={bulkno}
                        />
                    )}

                    {successMessage && <Alert severity="success">{successMessage}</Alert>} {/* Display the success message */}
                </Box>}
            {/* <Box sx={{ position: "sticky", bottom: 0 }}>
                <Box sx={{ height: "76px", width: "100%", display: "flex", justifyContent: "flex-end", gap: "24px", padding: "14px 30px", background: "#F1F5F9" }}>
                    <Box sx={{ width: "406px", display: "flex", gap: "24px" }}>
                    </Box>
                </Box> 
            </Box> */}
        </Box>
    );
};
