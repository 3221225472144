import React, { useState } from 'react';
import {Grid, Box, TextField, InputAdornment, IconButton, Button, Link, Typography} from '@mui/material';
import './account.css';
import '../../styles/brandingStyles/branding.css';
import loginBanner from '../../assets/userAccount/loginBanner.svg';
import { PersonOutline, LockOutlined, VisibilityOutlined, VisibilityOffOutlined, LoginOutlined } from '@mui/icons-material';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import { updateUser } from '../../redux/userDetails';
import { useMediaQuery } from 'react-responsive';
import CustomAxios from '../../utils/CustomAxios';
export function Login({ handleAuthLogin }){
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const dispatch=useDispatch()
    const isMobile = useMediaQuery({ maxWidth: 600 })
    const handleTogglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };
   
    const handleLogin = (e) => {
        // Validate inputs if needed
        //console.log(password)
        if(email===''){
            //console.log("we are here")
            setEmailError("Please enter your username.")
        }
        else{
            setEmailError("")
        }
        if (password===''){
            //console.log(password)
            setPasswordError("Password is required.")
        }
        else{
            setPasswordError("")
        }
        if (passwordError!=""){
            setPassword("")
           }
        if (emailError!=""){
            setEmail("")
        }
        e.preventDefault();
        // Prepare payload
        const payload = {
          email: email,
          password: password,
        };
    
        // Send data to the API endpoint using Axios (replace with your actual API endpoint)
        CustomAxios.post(`oasis/MemberLogin/`, payload)
        //axios.post('http://127.0.0.1:8000/oasis/MemberLogin/', payload)
          .then(response => {
            // Handle API response as needed
            
            //console.log('API Response:', response.data);
            const auth_data = response.data;
            //console.log(auth_data)

            window.sessionStorage.setItem('details',JSON.stringify(auth_data.user_details))
            if (auth_data.error=="Incorrect password"){
                setPassword("")
                setPasswordError("Incorrect Password. Please enter your correct password.")
            }
            if (auth_data.error=="Invalid mailid"){
                setEmailError("We couldn't find an account with that username.")
            }
            else{
            if (auth_data.user_details["first-login"]==true){
                navigate('/reset-password')
            }
            else{
            window.sessionStorage.setItem('user_details',JSON.stringify({"member_role":auth_data.user_details.role}))
            //console.log(response.data.member_data)
            dispatch(updateUser(response.data.member_data))
            window.sessionStorage.setItem("member",JSON.stringify({"member":response.data.member_data}))
             // Call the callback function to handle authentication in App.js
            handleAuthLogin(auth_data);

            // Redirect to the desired page after successful login
            auth_data.user_details.role==="member" ? navigate('/dashboard'): navigate('/executive_summary'); // Change to your desired route
            }}})
            .catch(error => {
                console.error('Error:', error);
            });
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleLogin(e); 
        }
      };

    return(
        <Box sx={[isMobile?{backgroundColor:"#f2f6fc",minHeight:"100vh"}:{width:"100%"}]}>
            <Box sx={[isMobile&&{backgroundColor:"#f2f6fc"},{ height: {sm:'initial',md:'100vh'} }]}>
                <Grid container sx={[isMobile&&{px:"1rem",pt:"32px"},{ height: {sm:'initial',md:'100%'} }]}>
                    <Grid item xs={12} sm={7} className='login-bg-primary' sx={[isMobile&&{backgroundColor:"#f2f6fc"},{display: "flex", alignItems: "center", justifyContent: "center"}]}>
                        <Box component='img' sx={{width: { xs: "245px", sm: "300px", md:"500px",lg:"initial" }}} src={loginBanner} />
                    </Grid>
                    <Grid item xs={12} sm={5}  sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Box className='login-bg-primary form' sx={[isMobile&&{p:0,backgroundColor:"#f2f6fc"}]}>
                            <form>
                                <Box sx={[isMobile&&{mt:"20px"},{mb:{xs:"12px",sm:1}}]} className='fn-h3 fw-600 primary text-center'>Login</Box>
                                <Box className='pgh-heading fw-600 text-center' style={{}}>Welcome back to Koperasi!</Box>
                                <Box className='pgh text-center'>Log in to your account to access all the great features of Koperasi.</Box>
                                <Box sx={{mt:{xs:"20px",sm:"30px"},mb:{xs:"20px",sm:4} }}>
                                    <Box sx={{mb:{xs:"20px",sm:4}}}>
                                        <Box sx={{mb:"8px", color:"#101010"}}>User Name <span style={{color:"#EF4444"}}>*</span></Box>
                                        <TextField
                                            placeholder="Email ID / Membership Number"
                                            fullWidth
                                            variant="outlined"
                                            sx={{mb:"8px"}}
                                            value={email}
                                            onKeyDown={(e)=>handleKeyPress(e)}
                                            onChange={(e) => setEmail(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonOutline />
                                                </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <Typography sx={{alignSelf:"stretch", color: "var(--Light-Text-Danger-Text, #EF4444)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{emailError}</Typography>
                                    </Box>
                                    <Box sx={{mb:{xs:"20px",sm:4}}}>
                                        <Box sx={{mb:"8px", color:"#101010"}}>Password <span style={{color:"#EF4444"}}>*</span></Box>
                                        <TextField
                                            placeholder="Enter Password"
                                            fullWidth
                                            variant="outlined"
                                            type={showPassword ? 'text' : 'password'}
                                            sx={{mb:"8px"}}
                                            value={password}
                                            onKeyDown={(e)=>handleKeyPress(e)}
                                            onChange={(e) => setPassword(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlined />
                                                </InputAdornment>
                                                ),
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePasswordVisibility}>
                                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
                                                    </IconButton>
                                                </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Typography sx={{alignSelf:"stretch", color: "var(--Light-Text-Danger-Text, #EF4444)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{passwordError}</Typography>
                                    </Box>

                                    <Button type="submit" fullWidth variant="contained" color="primary" onClick={handleLogin} startIcon={<LoginOutlined />}>
                                        Login
                                    </Button>
                                </Box>
                            </form>
                            {/* <Box sx={{mb:4}} className='text-center'>
                                <Link className='link' color="secondary" component={RouterLink} to="/forgot-password">
                                    Forgot Password?
                                </Link>
                            </Box> */}
                            {/* <Box className='text-center'>
                                <Box>
                                    <span className='link'>Don’t have an account? </span>
                                    <Link component={RouterLink} to="/signup">
                                    Signup
                                    </Link>
                                </Box>
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
};