import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export default function MuiAutocomplete({
  disabled,
  value,
  id,
  options,
  className,
  getOptionLabel,
  autoCompleteStyle = {},
  textFieldStyle = {},
  InputLabelProps = {},
  onChange,
  label,
  displayEmpty,
  required,
  isSubmitted=false,
  validationErrMsg="",
  multiline = false,
  maxRows = 3
  // multiple = false,
  // limitTags = 2
}) {
  console.log(value)
 console.log(options)
const defaultStyles = {
    backgroundColor: "#FFF",
    fontSize: "14px",
    color: "#101010",
    fontWeight: "400",
    "& .MuiInputBase-input": {
      color: "#000",
      padding: '0px !important',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...textFieldStyle,
    };

  return (
<FormControl fullWidth>
<Autocomplete
      // multiple={multiple}
      // limitTags={limitTags}
      disablePortal
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={options}
      required={required}
      className={className}
      displayEmpty={displayEmpty}
      getOptionLabel={getOptionLabel}
      sx={{ ...autoCompleteStyle }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={defaultStyles}
          InputLabelProps={InputLabelProps}
          multiline = {multiline}
          maxRows={maxRows}
        />
      )}
    />
    {isSubmitted && validationErrMsg && <FormHelperText
    style={{ minHeight: '1em', fontFamily: "inter",fontSize: "16px",fontStyle: "normal",fontWeight: "400",lineHeight: "24px", }} // Ensure consistent height
    error={isSubmitted && validationErrMsg}
     >
    {validationErrMsg}
    </FormHelperText>}
    </FormControl>
  );
}

