import React from "react";
import Tooltip from '@mui/material/Tooltip';

const MuiTooltip = ({ children, open, title }) => {
    return (
        <Tooltip title={!open ? `${title}` : ""} arrow placement='right' PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 5],
                    },
                },
            ],
        }}>
            {children}
        </Tooltip>
    );
}

export default MuiTooltip;