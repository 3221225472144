
import { Typography,Box } from "@mui/material"

import { Form } from "./form"
import { useEffect, useState } from "react"

export const FirstPage = (props) => {
    const [api_name,setApi_name] = useState('')
    useEffect(()=>{
        if(props.api){
            setApi_name(props.api)
        }
    },[props])



    return (
        

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width:"100%", height: "93vh" }}>
                <Box sx={{ width: "384px", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                    <Typography sx={{ fontSize: "36px", fontWeight: 500, lineHeight: "40px", color: "#101010", }}>No records Found</Typography>
                    <Box sx={{ marginTop: "19px" }}>
                        <Typography sx={{ fontSize: "20px", fontWeight: 400, lineHeight: "28px", color: "#64748B" }}>To view records, please set up a Data Configure to populate the data by clicking the below icon.</Typography>
                    </Box>
                    {/* <SettingsIcon onClick={() => setVisible(true)} sx={{ fontSize: "64px", color: "#288d2c", cursor: "pointer" }} /> */}
                </Box>
                <Form />
            </Box>
       
    )
}