import { Box, IconButton, Typography, Modal, useMediaQuery, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, CircularProgress, Checkbox, Stack, Divider, Button, Input, MenuItem, Menu } from "@mui/material";
import "../styles/chat-page.css";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Logout } from "@mui/icons-material";
import sendIcon from "../assets/create-project/send.svg";
import sendIconmob from "../assets/chat/Mic.svg";
import tableicon from "../assets/chat/table.svg"
import saveicon from "../assets/chat/save.svg";
import barCharticon from "../assets/chat/chart-simple_svgrepo.com.svg";
import lineCharticon from "../assets/chat/line-chart-up-01_svgrepo.com.svg";
import pieCharticon from "../assets/chat/pie-chart_svgrepo.com.svg";
import editIcon from "../assets/chat/edit_svgrepo.com.svg";
import refreshIcon from "../assets/chat/refresh-cw-alt-3_svgrepo.com.svg";
import deleteIcon from "../assets/chat/trash_svgrepo.com.svg";
import doughnutChartIcon from "../assets/chat/bx_doughnut-chart.svg";
import polarareaChartIcon from "../assets/chat/Grid.svg";
import searchIcon from "../assets/chat/search.svg";
import exporticon from "../assets/chat/export-2_svgrepo.com.svg";
import closeIcon from "../assets/chat/close-circle_svgrepo.com.svg";
import threedotIcon from "../assets/chat/ellipses-v.svg";
import historyIcon from "../assets/chat/history_svgrepo.com.svg";
import excelIcon from "../assets/chat/ms-excel_svgrepo.com.svg";
import pptIcon from "../assets/chat/ppt-document_svgrepo.com.svg";
import pdfIcon from "../assets/chat/pdf-file_svgrepo.com.svg";
import Rockemot from "../assets/icons/20_f.png"
import arrowLeft from "../assets/chat/arrow-left (1).svg";
import questionicon from "../assets/d&a_dashboard/question.svg"
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    Tooltip,
    Legend,
    BarElement,
    RadialLinearScale

} from 'chart.js';
import { useCallback, useRef, useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import ChatMessage from "../components/chatCreation";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
import selectAllSvg from "../assets/chat/select-all.svg"
import logoutSvg from "../assets/chat/logout.svg"
import saveSvg from "../assets/chat/bi_save.svg"
import threedotsSvg from "../assets/icons/threeDots.svg"
import { CheckBoxOutlined, OpenInBrowserOutlined, OpenInNewOutlined } from "@mui/icons-material";
ReactGA.initialize(gtag_MeasurementID);

ChartJS.register(
    BarElement,
    RadialLinearScale,
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
const styleTab = {
    top:"216px",
    position: 'absolute',
};


export function Chat(props) {
    const api_base_url = props.api_base_url
    const save_url= props.api_base_url

    const navigate = useNavigate()
    const [Optionsopen,setOptionsopen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [sidebar, setSidebar] = useState(false)
    const [open, setOpen] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');
    const mob =  useMediaQuery('(max-width: 600px)');
    const [model, setModel] = useState('palmai')
    const queryParameters = new URLSearchParams(window.location.search)
    const [question,setQuestions] = useState("")
    const [chatLog, setChatLog] = useState([]);
    const messagesEndRef = useRef(null)
    const [isLoading,setIsLoading] = useState(false)
    const [selectedCB,setSelectedCB] = useState([false])
    const [Msghovered,setMsgHovered] = useState([false])
    const [search,setSearch] = useState('')
    const [isChartChangingForExistingQuestion,setIsChartChangingForExistingQuestion] = useState(false)
    const [favourite,setFavourite]=useState()
    const [username,setUsername]=useState('')
    const [filename,setFilename]=useState('')
    const [credits,setCredits]=useState('')
    const [creditsColor,setCreditsColor]=useState('#00FF00')
    const [ispayment,setIsPayment] = useState(false)
    const [userTier,setUserTier] = useState('')
    //const [ispaysucess,setIsPaysucess] = useState(false)
    const [pay_status,setPaystatus] = useState(false)
    const [isSelectOptionSelcted,setIsSelectOptionSelcted] = useState(false)
    // const [isLastQuestionLoaded,setIsLastQuestionLoaded] = useState(false)
    const [initialLoadingQue,setInitialLoadingQue] = useState(false)
    const [Optionsopenhelp, setOptionsopenHelp] = useState(false)
    const [anchorElHelp, setAnchorElHelp] = useState(null);
    
    useEffect(()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        //console.log(user_details)

        async function fetchData() {
            const {data}=await axios.post(save_url+'dnaviz/payment_info/',{
                "username":user_details.username,
                })
            let cred = data.credits_left
            setCredits(cred)
            setUserTier(data.user_tier)
            //console.log(data)
            if (data.user_tier=="free plan"){
                setModel("palmai")
            }
            if (data.user_tier=="paid plan"){
                setModel("openai")
            }
            if (data.credits_left<40){
            setCreditsColor("var(--light-text-danger-text, #EF4444)")
            setIsPayment(true)
            }
            else{
             setCreditsColor("#00FF00")
             setIsPayment(false)
            }
            }
                 
                fetchData();
                setPaystatus(queryParameters.get('paysuccess'))
                //console.log(true)
        if(user_details){
            setUsername(user_details.username)
            setFilename(user_details.file_name)
        }
        else{
            navigate("/login")
        }
       
    },[])

    // useEffect(()=>{

    //     if(messagesEndRef.current){
    //         console.log("coming------------",messagesEndRef.current.lastChild)
    //         messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    //     }
        
    // },[chatLog.length])
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };
    const handleOpen = () => {
        setOpen(true)
    };
    const handleOpenExport = () => {
        // setOpenExport(true)
        // setIsExportBtnPressed(true)
        handlePdfExport()
    };
    // useState(()=>{
    //     let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        

    // },[])
    useEffect(()=>{
        getSaved()
        setInitialLoadingQue(true)

    },[])
    const getSaved=async()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        // console.log(user_details.comming_file)
        if(user_details){
        if(user_details.comming_file){
            //console.log("for file")
            //console.log(!user_details.file_saved)
            if(user_details.file_saved){
                //console.log("not saved")

                const project_name=user_details.project_name.replace(" ","_")
            //console.log(project_name)
            const {data}=await axios.get(save_url+'dnaviz/getdata_history/'+user_details.file_saved)
            //console.log(data)
            if(data.length>0){
                const data_history=data[0].data_history.map(item=>{
                    return {user:item.user,message:item.message,response:JSON.parse(item.response),id:item.id}
                })
                setChatLog(data_history)
                //console.log(data)
            user_details.chart=data[0]
            //console.log(user_details.chart)
            const history = data[0].data_history
            //console.log(history)
            window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
            }
    
            }

        }
        else{
            //console.log(user_details.chart.data_history)
                const data_history=user_details.chart.data_history.map(item=>{
                    return {user:item.user,message:item.message,response:JSON.parse(item.response),id:item.id}
                })
                setChatLog(data_history)

        }}
        else{
            navigate("/login")
        }
        
        
    }

    const handleClose = () => setOpen(false);
    const handleOpenHistory = () => setOpenHistory(true)
    const handleCloseExport = () => setOpenExport(false);
    const handleCloseHistory = () => setOpenHistory(false);

    const sideBar = () => setSidebar(!sidebar)
    const [data,setData] = useState({
        labels: ["jan", "feb", "mar", "apr"],
        datasets: [{
            labels: "male",
            data: [3, 8, 4, 7],
            backgroundColor: ["#ff00d4", "#06B6D4", "#64748B", "#9746FF"],
            borderColor: ["#ff00d4", "#06B6D4", "#64748B", "#9746FF"],
            dataLabels:{
                display:true
            }
        },]

    })

    const processGraph = (graph)=> {
		// console.log("process graph function----------------------------------------------------------------")
		
		let stacked= false;

		const dataValues1 = {
		//   label: [],
		};
	  
		if (typeof Object.values(graph)[0] === 'object') {
		  const subKeys = Object.keys(graph[Object.keys(graph)[0]]);
		  subKeys.forEach(subKey => {
			// dataValues1.label.push(subKey);
			dataValues1[subKey.toLowerCase()] = Object.values(graph).map(item => item[subKey]);
		  });
		  stacked = true
		} else {
			// dataValues1.label = Object.keys(graph);
		  Object.keys(graph).forEach(key => {
			dataValues1[key] = [graph[key]];
		  });
		  stacked = false
		}
		//console.log("data", dataValues1);
		return { dataValues1, stacked };
	  }

    const AddSelectedCB = ()=>{
        let dummyCB = [...selectedCB]
        selectedCB.push(false)
        setSelectedCB(dummyCB)
    } 

    const handleGetResponse = async()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        try{
            setIsLoading(true)
            setQuestions('')
            let newChatLog = [...chatLog]
            newChatLog.push({ user: "user", message: question, response: '' })
            setChatLog(newChatLog)
            setIsChartChangingForExistingQuestion(false)
            let response = await axios.post(api_base_url+'dnaviz/dna_insight/',{
                "userIntent":question,
                "file_name":user_details.file_name,
                "project_name":user_details.project_name,
                "username":user_details.username,
                "chat_model":model,
            })

            if(response.data.message){
                setQuestions('')
                let newChatLog = [...chatLog]
                newChatLog.push({ user: "user", message: question, response: { user: "ai", message: `${response.data.message}` }})
                setChatLog(newChatLog);
            }
            else{
                setQuestions('')
                const graph = response.data.pieChart||response.data.dnaTable  || response.data.barChart || response.data.doughnutChart || response.data.lineChart
                                || response.data.polarareaChart || response.data.radarChart || response.data.scatterChart || response.data.bubbleChart;
                const { dataValues1, stacked } = processGraph(graph)
                let newChatLog = [...chatLog]
                newChatLog.push({ user: "user", message: question, response:
                {
                    user: "ai",
                    message: "",
                    viz: Object.keys(response.data)[0],
                    data: Object.entries(graph).map(([x, y]) => ({
                        x: `${x} (${y})`,
                        y,
                    })),
                    dataValues1,
                    stacked,
                    dataValues: Object.values(graph),
                    labels: Object.keys(graph)
                }
            })
            setChatLog(newChatLog);
                    }
            AddSelectedCB()
            setIsChartChangingForExistingQuestion(false)
            // console.log(response,Object.keys(response.data)[0])
            setIsLoading(false)
        }
        catch(err){
            let newChatLog = [...chatLog]
            newChatLog.push({ user: "user", message: question, response: { user: "ai", message: " Please re-phrase the question." } })
            setChatLog(newChatLog);
            AddSelectedCB()
            setIsChartChangingForExistingQuestion(false)
            setIsLoading(false)
        }
        scrollToLastQuestion(chatLog.length-1)
        // how duplicate values are in slug. what are there. in bar chart
    }
    
    useEffect(()=>{
        if(isChartChangingForExistingQuestion==false){
            scrollToLastQuestion(chatLog.length-1)}
        if (chatLog.length>0 && chatLog[chatLog.length-1].response!==''){
            // scrollToLastQuestion(chatLog.length-1)
            savedResult()
            //console.log(chatLog,Msghovered,selectedCB)
            let dummy = []
            let dummy_selected = []
            if(dummy.length<chatLog.length){
                chatLog.map(()=>dummy.push(false))
                setMsgHovered(dummy)
            }
            if(dummy_selected.length<chatLog.length){
                chatLog.map(()=>dummy_selected.push(false))
                setSelectedCB(dummy_selected)
            }
            //console.log(chatLog,dummy,dummy_selected)
        }
       
    },[chatLog])

    const savedResult=async()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        async function fetchData() {
            const {data}=await axios.post(save_url+'dnaviz/payment_info/',{
                "username":user_details.username,
                })
            let cred = data.credits_left
            setCredits(cred)
            if (data.credits_left<50){
            setCreditsColor("#F66")
            setIsPayment(true)
            }
            else{
             setCreditsColor("#00C2FF")
             setIsPayment(false)
            }
            }
                 
                fetchData();
        // console.log(user_details.chart)
        setFilename(user_details.chart.file_name)
            if (user_details.chart==""){
                // console.log(window.sessionStorage.getItem('authtoken'))
                // const token=decodeToken( window.sessionStorage.getItem('authtoken'))
                // console.log(token)
                const file=await axios.post(save_url+'dnaviz/save_file/',{
                    "project_name":user_details.project_name,
                    "file_name":user_details.file_name,
                    // "data_history":data_history,
                    "created_by":user_details.username,
                   
                })
                const data_history=chatLog.map(item=>{
                    return {message:item.message,user:item.user ,response:JSON.stringify(item.response)}})
                //console.log("save data",data_history)
                //console.log(user_details.file_index)
                const project_name=user_details.project_name.replace(" ","_")
                const {data}=await axios.post(save_url+'dnaviz/save/',{
        
                    "project_name":user_details.project_name,
                    "file_name":user_details.file_name,
                    "data_history":data_history,
                    "created_by":user_details.username,
                    "source_file":file.data.id
        
                    
                })
                //console.log(data)
                user_details.file_saved=file.data.id
                user_details.chart=data
                window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
                //console.log(data)
            }
            else {
                //console.log(user_details.chart)
                //console.log(user_details.file_name)

                // console.log()
                //console.log(chatLog)
                const data_history=chatLog.map(item=>{
                    if (item.id){
                        return {message:item.message,user:item.user ,response:JSON.stringify(item.response),id:item.id}
                    }
                    else{
                        return {message:item.message,user:item.user ,response:JSON.stringify(item.response)}
                    }})
                    
                    
                //console.log(data_history)
                //console.log(user_details.chart.id)
                if (user_details.comming_file){
                const {data}=await axios.patch(save_url+`dnaviz/update/${user_details.chart.id}`,{
                   "data_history":data_history

                })
                // console.log(data.response)
                //console.log(data)
                
                // setChatLog(JSON.stringify(data.message.data_history))
                user_details.chart=data
                window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
                // console.log(user_details)
                if(!isChartChangingForExistingQuestion){
                    if(initialLoadingQue){
                        scrollToLastQuestion(chatLog.length-1)
                        setInitialLoadingQue(false)
                    }
                    else{
                        scrolToChart(chatLog.length - 1)
                    }
                    // setIsLastQuestionLoaded(true)
                    // setIsLastQuestionLoaded(false)
                }
            }
            else{
                //console.log(user_details.chart)
                const {data}=await axios.patch(save_url+`dnaviz/updatefav/${user_details.chart.id}`,{
                    "data_history":data_history
 
                 })
                //  console.log(data.response)
                 //console.log(data)
                 
                 // setChatLog(JSON.stringify(data.message.data_history))
                 user_details.chart=data
                 window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
                 // console.log(user_details)
                 if(!isChartChangingForExistingQuestion){
                    if(initialLoadingQue){
                        scrollToLastQuestion(chatLog.length-1)
                        setInitialLoadingQue(false)
                    }
                    else{
                        scrolToChart(chatLog.length-1)
                    }
                }
            }
            }

        
        
    }
 
    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            handleGetResponse();
            setQuestions("")
        }
      }, [handleGetResponse]);

    const handleChartChange = (index,chartType)=>{
        let dummyChatLog = [...chatLog]
        if(dummyChatLog[index].response){
            dummyChatLog[index].response.viz = chartType
        }
        setIsChartChangingForExistingQuestion(true)
        setChatLog(dummyChatLog)
    }
   
    const Fav_Save=async(e)=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        let dummySave= [...selectedCB]
        // console.log(e.key)
        let data_history=[]
        for(let i=0;i<=dummySave.length;i++){
            const bool=dummySave[i]
            
            if (bool){
                const message = chatLog[i].message;
                  const response = JSON.stringify((chatLog[i].response))
                  const user=chatLog[i].user
                  const value={message:message,response:response,user:user}
                data_history.push(value)
            }
            
        }
        // console.log(data_history)
        // console.log(favourite)
        
        // console.log(data)
        const {data}=await axios.post(save_url+'dnaviz/savefavourite/',{
        
            "project_name":user_details.project_name,
            "file_name":favourite,
            "data_history":data_history,
            "created_by":user_details.username,
            "source_file":user_details.file_saved

            
        })
        //console.log(data)
        handleClose()
        setFavourite("")
        dummySave =dummySave.map(()=>false)
        setSelectedCB(dummySave)
        let dummyMsg = [...Msghovered]
        dummyMsg = dummyMsg.map((e)=>e = false)
        setMsgHovered(dummyMsg)
        setIsSelectOptionSelcted(false)
    }
    const handlePdfExport = async()=>{
        let dummyCB = [...selectedCB]
        //console.log(dummyCB,chatLog)
        let pdf = new jsPDF();
        let x = 10
        let y = 20
        try{
            for (let bl_id = 0; bl_id < dummyCB.length; bl_id++) {
                const bool = dummyCB[bl_id];
                //console.log(bl_id,chatLog)
                //console.log(chatLog[bl_id])
                if (bool) {
                  const message = chatLog[bl_id].message;
                  const response = chatLog[bl_id].response
                //   console.log(response)
                  if (message) {
                    pdf.setTextColor('#000000');
                    pdf.setFillColor("#edf56c");
                    pdf.autoTable({
                        startY: y,
                        margin: { left: x, right: 10 },
                        body: [[`Q${bl_id+1}.`+message.charAt(0).toUpperCase() + message.slice(1).toLowerCase()]],
                        columnStyles: { 0: { cellWidth: 180, fillColor:false  } },
                        styles: {fontSize: 20, },
                    });
                    y = pdf.autoTable.previous.finalY + 5
                    // console.log("y after adding line---",y)
                  }
                  ////////// adding text answer
                  if(response.message){
                    // console.log("coming msg-----------")
                    x += 10
                    pdf.autoTable({
                        startY: y,
                        margin: { left: x, right: 10 },
                        body: [[response.message]],
                        columnStyles: { 0: { cellWidth: 180, fillColor:false } },
                        styles: {fontSize: 16,}
                    });
                    x -=10
                    y = pdf.autoTable.previous.finalY + 5
                  }
                  ////////// adding chart 
                  if(response.viz && response.viz != "dnaTable"){
                    // console.log("comng in to chart--------",pdf.internal.pageSize.height,y)
                    x += 10
                    const canvasElement = document.querySelector(`#section_${bl_id} canvas`)
                    const newCanvas = document.createElement('canvas');
                    newCanvas.width = canvasElement.width;
                    newCanvas.height = canvasElement.height;
                    const newContext = newCanvas.getContext('2d');
                    newContext.fillStyle = 'black';
                    newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
                    newContext.drawImage(canvasElement, 0, 0);
                    try {
                        // const canvasCapture = await html2canvas(newCanvas)
                        const canvasImg = newCanvas.toDataURL('image/png')
                        if( (pdf.internal.pageSize.height - y) < 90 ){
                            pdf.addPage()
                            y = 20 // new page starting coordinates
                        }
                        pdf.addImage(canvasImg, 'PNG', x, y,170,90);
                        x -=10
                        y += 110
                    } catch (error) {
                        console.error('Error capturing canvas:', error)

                    }
                  }//////// adding table in else block
                  else if(response.viz && response.viz === "dnaTable"){
                      try {
                            x += 10
                            const tableElement = document.querySelector(`#section_${bl_id} table`)
                            pdf.autoTable({
                                startY: y,
                                html:tableElement,
                                useCss:true,
                                theme:"grid",
                            })
                            x -=10
                            y = pdf.autoTable.previous.finalY + 5
                        } catch (error) {
                            console.error('Error capturing table:', error)
                        }
                  }
                }
            }
            pdf.save('exported.pdf');
            dummyCB = dummyCB.map(()=>false)
            let dummyHV = [...Msghovered]
            dummyHV = dummyHV.map(()=>false)
            setSelectedCB(dummyCB)
            setMsgHovered(dummyHV)
            setIsSelectOptionSelcted(false)
        }
        catch(err){
            console.error(err)
            
        }
        
    }
    const handleCheckboxSelection = (id,bool)=>{
		let dummyCB = [...selectedCB]
		//console.log(dummyCB)
		if(id<dummyCB.length && id >0){
			dummyCB[id] = bool
		}
		else if(id >= 0){
			//console.log("in else-----",dummyCB)
			while (dummyCB.length <= id) {
				dummyCB.push(false)
			}
			dummyCB[id] = bool
		}
		setSelectedCB(dummyCB)
	}
    const handleOnHover = (id)=>{
        let dummy = [...Msghovered]
        if(id<dummy.length && id >0){
			dummy[id] = true
		}
		else if(id >= 0){
			//console.log("in else-----",dummy)
			while (dummy.length <= id) {
				dummy.push(false)
			}
			dummy[id] = true
		}
		setMsgHovered(dummy)
    }
    const handleSelectQa = ()=>{
        let dummy = [...Msghovered]
        // console.log(dummy)
        dummy = dummy.map((e)=>e=true)
        // console.log(dummy)
        setMsgHovered(dummy)
        setIsSelectOptionSelcted(true)
    }
    const handleSelectAllQa = ()=>{
       //console.log("----selcted list----",selectedCB)
        let dummy = [...selectedCB]
        dummy = dummy.map((e)=>e=true)
        setSelectedCB(dummy)
    }
    const handleCancelSelectedQa = ()=>{
        setIsSelectOptionSelcted(false)
        let dummy = [...Msghovered]
        dummy = dummy.map((e)=>e=false)
        setMsgHovered(dummy)
        let dummy_selected = [...selectedCB]
        dummy_selected = dummy_selected.map((e)=>e=false)
        setSelectedCB(dummy_selected)
    }
    const handleHoverEnd = (id)=>{
        let dummy = [...Msghovered]
		dummy[id] = false
		setMsgHovered(dummy)
    }
    function logout(){
		sessionStorage.removeItem("userdetails")
		navigate("/login")
	}
    const scrollToLastQuestion = (index)=>{
        handleCloseHistory()
        // setIsLastQuestionLoaded(false)
        // console.log("--------index--------",index)
        const targetElement = document.getElementById("section_"+index);
        // console.log("-----trget ele-----",targetElement)
        if (targetElement) {
            for(let i = 0;i<=index;i++){
                let ele = document.querySelector("#section_"+i)
                // console.log(i,ele)
                ele.style.display = "flex" 
            }
            targetElement.scrollIntoView();
        }
    }
    const scrolToChart=(index)=>{
        handleCloseHistory()
        let id="section_"+index
        const targetElement = document.getElementById(id);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }

    }
    const handleOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
    };
    const handleOptionsClose = () => {
        let dummy_optionOpen =  false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
        // setAnchorEl(null);
    };

    const handlePaysucessClose = () => {
        setPaystatus(false)
    }
    const handleOptionsClickHelp = (event) => {
        setAnchorElHelp(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopenHelp(dummy_optionOpen)
    };
    const handleOptionsCloseHelp = () => {
        let dummy_optionOpen = false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopenHelp(dummy_optionOpen)
        // setAnchorEl(null);
    };
    async function redirectURL(){
        //const url = `https://stageaiqpayment.nlugpt.io/payment/?username=${username}`;
        //const url = `https://stage-aiqpayment.arivu-iq.com/payment/?username=${username}`;
       // console.log(username)
        const {data}= await axios.get(save_url+"dnaviz/payment_uid/"+username)
        //console.log(data)
        //const url = `http://localhost:3001/payment/?uid=${data.unique_id}`;
        const url = `${process.env.REACT_APP_AIQ_ACC_URL}payment/?uid=${data.unique_id}`;
        //const url = `https://stageaiqpayment.nlugpt.io/payment/`
        //localStorage.setItem('sharedValue', username)
        //const data = { username: username }
        //const url = `http://localhost:3001/payment/`;
        //window.parent.postMessage(data, url);
        window.location.href = url;
        //window.open(url)
        //window.open(`https://stageaiqpayment.nlugpt.io/payment/?username=${username}`);
        //window.open(`http://localhost:3001/payment/?username=${username}`, "_blank");
    }
    function redirectURLHelp(){
        const url = `${process.env.REACT_APP_AIQ_URL}privacy-policy/`;
        window.open(url,'_blank');
    }
	const loadingTexts = ["Hang tight!","Patience, please.","Awaiting results.","Processing request.","Almost there!","Stand by.","Fetching information....","In progress...","Just a moment.","Hold on."]
        
    // console.log(chatLog[0].message.toLowerCase())
    return (
        <Box className="chat-container">
            <Box className="sidebar">
             <Box sx={{display:"flex",alignItems:"center",marginRight:"26px",gap:"10px"}}>
             <Box className="button-container1">
                    <button className="back-button1" onClick={() => { navigate('/analysis') }}> <img src={arrowLeft} className="arrow1" alt="arrow-icon" /></button>
                    
                </Box>
               
                <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal",marginTop:"36px", fontWeight: "700", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden",background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent"}}>{JSON.parse(window.sessionStorage.getItem("userdetails"))?.project_name?.charAt(0).toUpperCase()}{JSON.parse(window.sessionStorage.getItem("userdetails"))?.project_name?.slice(1)}</Typography>

             </Box>
                <Typography sx={{ marginTop: "37px", marginLeft: "32px", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", color: "var(--pallate-gray-300, #D1D5DB)" }}>History</Typography>
               <Box className="historySearch">
                <img src={searchIcon} alt="search" />
                <input onChange={(e)=>setSearch(e.target.value)} className="searchInput"  placeholder="Search" />
               </Box>
                <Box className="history-container">
                    <Box className="history-box ">
                        {/* <Typography sx={{ fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px", color: "var(--pallate-blue-gray-400, #94A3B8)" }}>Today</Typography> */}
                        {chatLog.filter((history)=>{
                            // console.log(history.message.toLowerCase().includes(search.toLowerCase()))
                            return search=== ''?history: history.message.toLowerCase().includes(search.toLowerCase())
                        }).map((questions, index) => {

                            return <Box className="history">
                            <Box className="hostory-content" onClick={()=>scrolToChart(index)}>
                                <Typography className="historyMessage" sx={{color: "var(--pallate-blue-gray-400, #94A3B8)", width: "239px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px",wordBreak:"break-word",transition:".2s ease-in-out"}}>{questions.message}</Typography>
                            </Box>
                        </Box>
                        })}   
                    </Box>
                </Box>
            </Box>
            <Box className="chat-right">
           
               {!tab&&!mob?     <Box className="top-bar">
                    <Box style={{ display: "flex" }}>
                    <Box className="credits_info">
                    <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "800", lineHeight: "28px", color: "#FFF" ,overflowX:"hidden", background:"#FFF",backgroundClip: "text", color: "transparent"}}>CREDITS LEFT:</Typography>
                    <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "800", lineHeight: "28px",overflowX:"hidden",background: creditsColor, backgroundClip: "text", color: "transparent"}}>{credits}</Typography>
                    </Box>
                        {/* <Box className="menu"><IconButton sx={{ marginTop: "28px" }} onClick={() => sideBar()}><MenuIcon sx={{ color: "white" }} /></IconButton></Box>
                     <Box className="dataSelection">
                   <select onChange={(e)=>setModel(e.target.value)} className="selectDatas"> 
                            <option style={{marginTop:"20px"}}>Select Chat Model</option>
                            <option value="openai" >Stable</option>
                            <option value="palmai">Beta</option>
                            </select></Box> */}
                    </Box>
                    
               <Box sx = {{maxWidth:"250px",display: "flex", alignItems: "flex-start",gap:"32px",marginRight:"10px"}}>
                    
                    <button className="create" onClick={() => navigate('/new-project')}><Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px", color: "#FFF" }}>Create New</Typography></button>
                   <Box sx={{padding:"0",display:"flex",gap:"32px"}}>
                                                                        <Box  sx={{padding:"0"}} >
                                                                        <Button
                                                                            sx={{ color: "#fff" }}
                                                                            onClick={handleLogout}
                                                                        >
                                                                            <Logout />
                                                                        </Button>
                                                                        </Box>
                                                                        </Box>
                                                                            {/* <Button
                                                                           
                                                                                sx={{flexShrink:"0",background:"#64748B",color:"#FFF",fontSize:"20px",fontWeight:"800",width:"40px",height:"40px",borderRadius:"50px",marginTop:"38px",cursor:"pointer",border:"2px solid rgb(151, 70, 255)",minWidth:"40px"}}
                                                                                id="file-option-button"
                                                                                aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={Optionsopen}
                                                                                onClick={(e)=>{e.stopPropagation();handleOptionsClick(e)}}
                                                                                
                                                                            >
                                                                                {username.toUpperCase()[0]}
                                                                            </Button>
                                                                            <Menu
                                                                                id="file-option-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Optionsopen}
                                                                                // open={true}
                                                                                onClose={()=>{handleOptionsClose()}}
                                                                                sx={{".MuiMenu-paper":{
                                                                                    backgroundColor:"var(--pallate-blue-gray-700, #334155)"
                                                                                }}}
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'file-option-button',
                                                                                    'style':{
                                                                                        color:"var(--light-text-inverse-text, #FFF)",
                                                                                        borderRadius:"4px",
                                                                                        width:"120px",padding:"0"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",padding:"8px"}} onClick={logout}>Logout</MenuItem>
                                                                                <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",borderTop:"1px solid grey",padding:"8px"}} onClick={()=>redirectURL()}>Account</MenuItem>
                        
                                                                            </Menu>
                                                                        </Box></Box> */}
        {/* <Box><Button sx={{flexShrink:"0",minWidth:"40px",cursor:"pointer",width:"40px",height:"40px",marginTop:"38px",marginRight:"32px",border:"1px solid var(--pallate-blue-gray-500, #64748B)",padding:"8px",borderRadius:"24px"}}
                id="file-help-button"
                aria-controls={Optionsopenhelp ? "file-help-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Optionsopenhelp}
                onClick={(e) => { e.stopPropagation(); handleOptionsClickHelp(e) }}
                >
            <img style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"3.242px 6.255px 3.25px 6.25px"}} src={questionicon}/>
        </Button>
        <Menu
                    id="file-help-menu"
                    anchorEl={anchorElHelp}
                    open={Optionsopenhelp}
                    // open={true}
                    onClose={() => { handleOptionsCloseHelp() }}
                    sx={{
                        ".MuiMenu-paper": {
                            backgroundColor: "var(--pallate-blue-gray-700, #334155)"
                        }
                    }}
                    MenuListProps={{
                        'aria-labelledby': "file-help-button",
                        'style': {
                            color: "var(--light-text-inverse-text, #FFF)",
                            borderRadius: "4px",
                            width: "120px", padding: "0"
                        }
                    }}
                >
                    <MenuItem sx={{ padding: "3px",display: "flex", alignItems: "center", padding: "8px" }} onClick={() => redirectURLHelp()}>Help</MenuItem>
                </Menu></Box>  */}
                                                                         </Box>
                                                                    </Box>:


                                                                     <Box className="top-bar1" sx={{m:"32px 16px 0px"}}>
                                                                     
                                                                    <Box sx={{display:"flex",gap:"8px"}}> <Box className="button-container2">
                                                                        <button className="back-button2" onClick={() => { navigate('/analysis') }}> <img src={arrowLeft} className="arrow1" alt="arrow-icon" /></button>
                                                                        
                                                                    </Box>
                                                                    <Box className="credits_info_mob">
                    <Typography sx={{ fontFamily: "inter", fontSize:mob?"12px":"16px", fontStyle: "normal", fontWeight: "800", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden", background:"#FFF",backgroundClip: "text", color: "transparent"}}>CREDITS LEFT:</Typography>
                    <Typography sx={{ fontFamily: "inter", fontSize: mob?"12px":"16px", fontStyle: "normal", fontWeight: "800", lineHeight: "24px",overflowX:"hidden",background: creditsColor, backgroundClip: "text", color: "transparent"}}>{credits}</Typography>
                    </Box>
                    </Box>
                                                                    
                                                                <Box sx = {{display: "flex", alignItems: "flex-start", justifyContent: "space-between",marginRight:mob?"0px":"10px"}}>
                                                                    <Box sx={{padding:"0"}}>
                                                                        <Box  sx={{padding:"0",display:"flex",flexDirection:"row",width:"100px",justifyContent:"space-between"}} >
                                                                            <button className="tab-history" onClick={() => handleOpenHistory()}><img className="tab-history-img" src={historyIcon}/></button>
                                                                            <Button
                                                                                sx={{ color: "#fff" }}
                                                                                onClick={handleLogout}
                                                                            >
                                                                                <Logout />
                                                                            </Button>
                                                                            {/* <Button
                                                                           sx={{minWidth:"0",display:"inline-flex",padding:"8px",alignItems:"flex-start",gap:"8px",borderRadius:"8px",background:"var(--pallate-blue-gray-600, #475569)",marginBottom:"10px",'&hover':{background:"var(--pallate-blue-gray-600, #475569)"}}}
                                                                                id="file-option-button"
                                                                                aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={Optionsopen}
                                                                                onClick={(e)=>{e.stopPropagation();handleOptionsClick(e)}}
                                                                                
                                                                            >
                                                                                {<img className="threeDot" src={threedotIcon}/>}
                                                                            </Button>
                                                                            <Menu
                                                                                id="file-option-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Optionsopen}
                                                                                // open={true}
                                                                                onClose={()=>{handleOptionsClose()}}
                                                                                sx={{".MuiMenu-paper":{
                                                                                    backgroundColor:"var(--pallate-blue-gray-700, #334155)",
                                                                                    borderRadius:"8px"
                                                                                }}}
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'file-option-button',
                                                                                    'style':{
                                                                                        color:"var(--light-text-inverse-text, #FFF)",
                                                                                        borderRadius:"8px",
                                                                                        width:"210px",padding:"0"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem sx={{padding:"8px 12px",display:"flex",alignItems:"center",borderBottom:"1px solid var(--pallate-blue-gray-500, #64748B)"}} onClick={()=>redirectURL()}>Account</MenuItem>
                                                                               {userTier=='paid plan'&& <MenuItem disabled={chatLog.length>=1?false:true} sx={{width:"100%",padding:"8px 12px",display:"flex",alignItems:"center",gap:"8px",borderBottom:"1px solid var(--pallate-blue-gray-500, #64748B)",}} onClick={()=>{handleSelectQa();handleOptionsClose()}}><Typography sx={{width:"100%"}}>Select</Typography><CheckBoxOutlined sx={{width:"20px",height:"20px"}}/></MenuItem>}
                                                                                <MenuItem sx={{padding:"8px 12px",display:"flex",alignItems:"center",borderBottom:"1px solid var(--pallate-blue-gray-500, #64748B)"}} disabled={selectedCB.includes(true)?false:true}  onClick={()=>{handleOpen();handleOptionsClose()}}><Typography sx={{width:"100%"}}>Save</Typography><Box component="img" src={saveSvg} sx={{width:"20px",height:"20px"}}/></MenuItem>
                                                                                <MenuItem sx={{padding:"8px 12px",display:"flex",alignItems:"center",borderBottom:"1px solid var(--pallate-blue-gray-500, #64748B)"}} disabled={selectedCB.includes(true)?false:true}  onClick={()=>{handleOpenExport();handleOptionsClose()}}><Typography sx={{width:"100%"}}>Export</Typography><OpenInNewOutlined sx={{width:"20px",height:"20px"}}/></MenuItem>
                                                                                <MenuItem sx={{padding:"8px 12px",display:"flex",alignItems:"center",bgcolor:"#9746FF"}} onClick={logout}><Typography sx={{width:"100%"}}>Logout</Typography><Box component="img" src={logoutSvg} sx={{width:"20px",height:"20px"}}/></MenuItem>
                        
                                                                            </Menu> */}
                                                                        </Box>
                                                                        
                                                                        </Box>
                                                                        </Box>
                                                                        
                                                                    </Box>}
                
                {/* <Box className="line"></Box> */}
                <Box className="chat-page" sx={{px:"1rem",pb:"0px",mb:"32px"}}>
                    <Box style={{ display: "flex", width: "100%" }}>
                        <Box className="input-section" sx={{p:0}}>
                            {/* <Box className="scrollbar-hidden" sx={{display:"flex",flexDirection:"column",overflowY:"scroll",height:"589px",width:"916px"}}> */}

{tab||mob?<Box sx={{display:"inline-flex",flexDirection:"column",gap:"12px",alignItems:"flex-start",marginTop:"16px",marginBottom:"24px",width:"100%",px:"16px"}}>

    {isSelectOptionSelcted&&<Stack direction="row" sx={{mt:"12px",width:"100%",justifyContent:"space-between"}}>
        <Button variant="contained" onClick={()=>handleSelectAllQa()} sx={{display:"flex",gap:"8px",bgcolor:"#475569",p:"8px 12px",color:"#00C2FF",textTransform:"capitalize"}}>
            <Box component="img" src={selectAllSvg}/>
            <Typography sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px",fontFamily:"Inter"}}>Select all</Typography>
        </Button>
        <Button variant="contained" onClick={()=>handleCancelSelectedQa()} sx={{bgcolor:"#475569",p:"8px 12px",color:"#00C2FF",textTransform:"capitalize"}}>Cancel</Button>
    </Stack>}
    <Typography sx={{maxWidth:"328px",width:"100%",fontSize:"16px",color:"#FFF",fontFamily:"inter",fontWeight:"700",lineHeight:"24px",wordBreak:"break-word"}}>{filename?(filename.replace(/\.[^.]+$/, "")).charAt(0).toUpperCase()+(filename.replace(/\.[^.]+$/, "")).slice(1):""}</Typography>
    </Box>:null}
                            <Box className="result-container">
                            {ispayment && <Modal
                open={ispayment}
                onClose={()=>setIsPayment(false)}
                sx={{backdropFilter:"blur(5px)",display:"flex",justifyContent:"center",alignItems:"center"}}
            >
                <Stack gap="16px" sx={{width:"300px",textAlign:"center",p:"24px",borderRadius:"8px",background:"var(--pallate-blue-gray-100, #F1F5F9)",boxShadow:"0px 0px 36px 0px rgba(0, 0, 0, 0.25)"}}>
                    <Stack gap="8px" sx={{textAlign:"center"}}></Stack>
                    <Typography sx={{color:"var(--light-text-danger-text, #EF4444)",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px"}}>HEY THERE!</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #101010)",fontFamily:"inter",fontSize:"12px",fontWeight:600,lineHeight:"16px"}}>It seems like your credits are running low.</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #101010)",fontFamily:"inter",fontSize:"12px",fontWeight:700,lineHeight:"16px"}}>Would you like to top them up and keep the momentum going?</Typography>
                        <Stack gap="12px" direction="row" sx={{textAlign:"center"}}>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#00C2FF"}} onClick={()=>{setIsPayment(false)}}>No</Button>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#9746FF"}} onClick={()=>redirectURL()}>Yes</Button>
                        </Stack>
                </Stack>   
                </Modal>}
                {pay_status && <Modal
                open={pay_status}
                onClose={()=>handlePaysucessClose()}
                sx={{backdropFilter:"blur(5px)",display:"flex",justifyContent:"center",alignItems:"center"}}
            >
                <Stack gap="16px" sx={{width:"300px",textAlign:"center",p:"24px",borderRadius:"8px",background:"var(--pallate-blue-gray-100, #F1F5F9)",boxShadow:"0px 0px 36px 0px rgba(0, 0, 0, 0.25)"}}>
                    <Stack gap="8px" sx={{textAlign:"center"}}></Stack>
                    <Typography sx={{color:"var(--light-text-danger-text, #EF4444)",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px"}}>Pretty Cool <img src={Rockemot}/></Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #101010)",fontFamily:"inter",fontSize:"12px",fontWeight:600,lineHeight:"16px"}}>Your payment was successful. Thank you for choosing us. We appreciate your business</Typography>
                        {/* <Typography sx={{color:"var(--pallate-blue-gray-800, #101010)",fontFamily:"inter",fontSize:"12px",fontWeight:700,lineHeight:"16px"}}>You have earned 500 Credits</Typography> */}
                        {/* <Typography sx={{color:"var(--pallate-blue-gray-800, #101010)",fontFamily:"inter",fontSize:"12px",fontWeight:700,lineHeight:"16px"}}>Click continue to continue with your journey</Typography> */}

                        <Stack gap="12px" direction="row" sx={{textAlign:"center"}}>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#00C2FF"}} onClick={()=>{handlePaysucessClose()}}>Continue</Button>
                            {/* <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#9746FF"}} onClick={()=>redirectURL()}>Yes</Button> */}
                        </Stack>
                </Stack>   
                </Modal>}
                                <Box className="chart-box" sx={{width:"inherit",height:"auto"}}  ref={messagesEndRef}>
                                    {chatLog.map((message, index) => {
                                        return(
                                            <Stack direction="column" rowGap="10px" sx={{alignItems:"center",borderBottom:"1px solid #334155",width:"90%"}} onMouseEnter={()=>handleOnHover(index)} onMouseLeave={()=>handleHoverEnd(index)}>
                                                <Stack id={`section_${index}`} direction="row" sx={{display:chatLog.length - 1 === index?"flex":"none",gap:tab||mob?"10px":"28px",alignItems:"flex-start",mb:"1rem",width:"100%",justifyContent:mob?"flex-start":"center"}} >
                                                    <Box sx={{width:"16px",height:"16px",pr:tab||mob?"16px":"28px"}}>
                                                        {(Msghovered[index] || selectedCB[index] )&&userTier=='paid plan'? 
                                                              <Checkbox disableRipple sx={{pt:tab?"16px":"24px",color:"#06B6D4",'&.Mui-checked': {color:"#06B6D4"}}} checked={selectedCB[index]} onChange={(e)=>{handleCheckboxSelection(index,e.target.checked)}}/> 
                                                        :  null} 
                                                    </Box>
                                                    <Box className="chart" sx={{wordBreak:"break-all"}}>
                                                        <ChatMessage index={index} id={`section_${index}`} message={message} isloading={isLoading} loadtext={loadingTexts}/>
                                                    </Box>
                                                </Stack>
                                               {message.response.viz && userTier=='paid plan'&& <Box className="chart-change" sx={{height:"55px",width:"280px",paddingLeft:"60px",marginBottom:"10px"}}>
                                                    {
                                                        message.response.viz && Msghovered[index]? <Stack direction="row" className="chart-icons-div"  sx={{alignItems:"center",justifyContent:"space-between",backgroundColor: "var(--pallate-blue-gray-700, #334155)",p:"12px",borderRadius: "16px"}}>
                                                            <Button style={{ border: "none", background: "transparent", cursor: "pointer" }} onClick={() => handleChartChange(index,"barChart")}><img className="chart-icons" src={barCharticon} alt="barchart" /></Button>
                                                            <Button style={{ border: "none", background: "transparent", cursor: "pointer" }} onClick={() => handleChartChange(index,"lineChart")}><img className="chart-icons" src={lineCharticon} alt="linechart" /></Button>
                                                            <Button style={{ border: "none", background: "transparent", cursor: "pointer" }} onClick={() => handleChartChange(index,'pieChart')}><img className="chart-icons" src={pieCharticon} alt="piechart" /></Button>
                                                            <Button style={{ border: "none", background: "transparent", cursor: "pointer" }} onClick={() => handleChartChange(index,'doughnutChart')}><img className="chart-icons" src={doughnutChartIcon} alt="piechart" /></Button>
                                                            <Button style={{ border: "none", background: "transparent", cursor: "pointer" }} onClick={() => handleChartChange(index,'polarareaChart')}><img className="chart-icons" src={polarareaChartIcon} alt="piechart" /></Button>
                                                            <Button  style={{border: "none", background: "transparent", cursor: "pointer",marginTop:"4px"}} onClick={() => handleChartChange(index,"dnaTable")}> <img className="chart-icons" src={tableicon} alt="table" /></Button>
                                                        </Stack>
                                                        : null
                                                    }
                                                </Box>}
                                            </Stack>
                                            // :
                                            // <Stack sx={{justifyContent:"center",alignItems:"center"}}>
                                            //     <Box component="img" src={threedotsSvg} sx={{color:"#FFF",width:"80px"}}/>
                                            //     <Typography sx={{fontSize:"30px",color:"#FFF"}}>Sit back and relax while we retrieve your data.</Typography>
                                            // </Stack>
                                        )
                                
                                    })
                                    }
                                </Box>
                            </Box>
                            <Box className="chat-input" sx={{gap:"13px",mb:0}}>
                            {/* <select onChange={(e)=>setModel(e.target.value)} style={{color:"#FFF",width:"150px",padding:"10px",backgroundColor:"transparent"}}> 
                            <Typography>Select your model</Typography>
                            <option value="openai">Stable</option>
                            <option value="palmai" >Beta</option>
                            </select> */}
                                <textarea value={question} onChange={(e)=>setQuestions(e.target.value)}  onKeyDown={handleKeyDown} className="chat-box" placeholder={mob?"Type your Command here...":"Ask your questions here..."}>
                                </textarea>
                                {mob?<Box className="sendButton-container">
                                        {/* {isLoading && question ?<CircularProgress/>: */}
                                        <button className="send-button" onClick={()=>handleGetResponse()}>
                                            <Box className="send"> <img className="send-icon" src={mob?sendIconmob:sendIcon} alt="send-icon" /></Box>
                                        </button>
                                        {/* } */}
                                </Box>:question&&<Box className="sendButton-container">
                                        {/* {isLoading && question ?<CircularProgress/>: */}
                                        <button className="send-button" onClick={()=>handleGetResponse()}>
                                            <Box className="send"> <img className="send-icon" src={mob?sendIconmob:sendIcon} alt="send-icon" /></Box>
                                        </button>
                                        {/* } */}
                                </Box>}
                                {/* <Box className="chat-box">
                                <input style={{ border: "#D1D5DB", outline: "none", backgroundColor: "transparent", flex: "1 0 0", alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-gray-300, #D1D5DB)" }} />
                                    <Box className="sendButton-container">
                                        <button className="send-button"><Box className="send"> <img className="send-icon" src={sendIcon} alt="send-icon" /></Box></button>
                                    </Box>
                                </Box> */}

                            </Box>
                            
                          {tab || mob?  <Modal
                            open={openHistory}
                            onClose={() => handleCloseHistory()}
                            closeAfterTransition
                        >
                            <Box className="sidebar-tab" sx={{ ...styleTab }}>
             
                <Typography sx={{ marginTop: "37px", marginLeft: {md:"32px"},marginBottom:{xs:"18px",md:"20px"}, fontFamily: "inter", fontSize: {xs:"16px",md:"20px"}, fontStyle: "normal", fontWeight: {xs:800,md:"700"}, lineHeight: "24px", color: "var(--pallate-gray-300, #D1D5DB)",textAlign:"center" }}>History</Typography>
               <Box className="historySearch"sx={{m:{xs:"0px 16px",md:"0px 26px 0px 32px"}}}>
                <img src={searchIcon} alt="search" />
                <input onChange={(e)=>setSearch(e.target.value)} className="searchInput"  placeholder="Search" />
               </Box>
                <Box className="history-container-tab" sx={{px:{xs:"16px",md:"20px"}}}>
                    <Box className="history-box-tab" sx={{gap:{xs:"14px",md:"20px"}}}>
                        {/* <Typography sx={{ fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px", color: "#FFF" }}>Today</Typography> */}
                        {chatLog.filter((history)=>{
                            return search === ''?history:history.message.toLowerCase().includes(search.toLowerCase())
                        }).map((questions, index) => {

                            return <Box className="history-tab">
                            <Box className="hostory-content" onClick={()=>scrolToChart(index)}>
                                <Typography className="historyMessage" sx={{color: "#fff", width: "100%", fontFamily: "inter", fontSize: {xs:"12px",md:"14px"}, fontStyle: "normal", fontWeight: "400", lineHeight: "20px",wordBreak:"break-word",transition:".2s ease-in-out"}}>{questions.message}</Typography>
                            </Box>
                        </Box>
                        })}   
                    </Box>
                </Box>
            </Box></Modal>:""}
                        </Box>
                        {!tab ?userTier=='paid plan'&&<Box className="buttons-container">
                            <button disabled={selectedCB.includes(true)?false:true} style={{cursor:selectedCB.includes(true)?"pointer":"not-allowed"}} className="buttons" onClick={() => handleOpen()}> <img className="icons" src={saveicon} alt="save" /></button>
                            <button disabled={selectedCB.includes(true)?false:true} style={{cursor:selectedCB.includes(true)?"pointer":"not-allowed"}} className="buttons" onClick={() => handleOpenExport()}> <img className="icons" src={exporticon} alt="expport" /></button>
                        </Box>:null}

                    </Box>
                    
                    <Box>
                        <Modal
                            open={openExport}
                            onClose={() => handleCloseExport()}
                            closeAfterTransition
                        >
                            <Box sx={{ ...style }}>
                                <Box className="export">
                                    <Box className="export-box">
                                        <Box className="export-captions">
                                            <Typography sx={{ textAlign: "center", fontFeatureSettings: " 'clig' off, 'liga' off", fontFamily: "poppins", fontSize: "32px", fontStyle: "normal", fontWeight: "700", lineHeight: "150%", color: "transparent", alignSelf: "stretch", background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", }}>EXPORT REPORT</Typography>
                                            <Typography sx={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: "400", lineHeight: "150%", color: "var(--pallate-blue-gray-300, #CBD5E1)", textAlign: "center", alignSelf: "stretch" }}>Export your Report multiple option</Typography>
                                        </Box>
                                        <img className="close-icon" src={closeIcon} alt="close-circle-icon" onClick={() => handleCloseExport()} />
                                    </Box>
                                    <Box className="export-options">
                                        <Box className="excel">
                                            <img className="export-icon" src={excelIcon} alt="close-circle-icon" />
                                            <Box className="radio-btn">
                                                <input className="input-radio" type="radio" />
                                            </Box>
                                        </Box>
                                        <Box className="excel">
                                            <img className="export-icon" src={pptIcon} alt="close-circle-icon" />
                                            <Box className="radio-btn">
                                                <input className="input-radio" type="radio" />
                                            </Box>
                                        </Box>
                                        <Box className="excel">
                                            <img className="export-icon" src={pdfIcon} alt="close-circle-icon" />
                                            <Box className="radio-btn">
                                                <input className="input-radio" type="radio" />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="button-section">
                                        <Box className="button-box">
                                            <button className="export-button"> <Typography sx={{ textAlign: "center", fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "150%", color: "var(--pallate-base-white, #FFF)" }}>Export</Typography></button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box></Modal>
                        <Modal
                            open={open}
                            onClose={() => handleClose()}
                            closeAfterTransition
                        >
                            <Box sx={{ ...style }}>
                                <Box className="save-report-container">
                                    <Box className="title-section">
                                        <Box className="titleBox">
                                            <Typography sx={{ textAlign: "center", fontFeatureSettings: " 'clig' off, 'liga' off", fontFamily: "poppins", fontSize: mob?"22px":"32px", fontStyle: "normal", fontWeight: "700", lineHeight: "150%", color: "transparent", alignSelf: "stretch", background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", }}>SAVE REPORT</Typography>
                                            <Typography sx={{ fontFamily: "poppins", fontSize: mob?"14px":"18px", fontStyle: "normal", fontWeight: "400", lineHeight: "150%", color: "var(--pallate-blue-gray-300, #CBD5E1)", textAlign: "center", alignSelf: "stretch" }}>Save Your report in your Library</Typography>
                                        </Box>
                                        <img className="close-icon" src={closeIcon} alt="close-circle-icon" onClick={() => handleClose()} />
                                    </Box>
                                    <Box className="button-section">
                                        {/* <form className="button-section" onSubmit={Fav_Save}> */}
                                        <Box className="text-input">
                                            <input className="save-input" value={favourite}  onChange={(e)=>setFavourite(e.target.value)}  placeholder="File Name" />
                                        </Box>
                                        <Box className="button-box">
                                            <button className="export-button" onClick={Fav_Save}> <Typography sx={{ textAlign: "center", fontFamily: "Roboto", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "150%", color: "var(--pallate-base-white, #FFF)" }}>Save</Typography></button>
                                        </Box>
                                        {/* </form> */}
                                       
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}