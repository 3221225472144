import { Box, Typography, Button, IconButton, Tooltip } from "@mui/material"
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import { useState, useRef, useEffect } from "react";
import CustomAxios from "../utils/CustomAxios"
import { useSelector } from "react-redux";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useTheme } from '@mui/material/styles';

import MuiSearchBar from "../elements/Mui/mui_searchbar"
import MuiButton from "../elements/Mui/mui_button";



export const BankName=()=>{

    const theme = useTheme();

    const [records,setRecords] = useState([])
    const [newbank, setNewBank] = useState(false)
    const [bank, setBank] = useState('')
    const [selbank, setSelBank] = useState('')
    const [swiftcode, setSwiftcode] = useState('')
    const [bankdesc, setBankDesc] = useState('')
    const [update, setUpdate] = useState(false)
    const [success, setSucces] = useState(false)
    const [errors, setErrors] = useState('')
    useState(()=>{
        const getMaster= async ()=>{
            const response = await CustomAxios.get('oasis/form_details/')
            console.log(response.data)
            setRecords(response.data.bankName)
        }
        getMaster()
    },[])

    useEffect(()=>{
        if (bank==""){
            setSelBank([])

        }
    },[bank])

    useEffect(()=>{
        if(success==true){
         setTimeout(() => {
             setSucces(false);
         }, 5000);
        }
 },[success])

    const SelectedBank = async (rowdata) =>{
        let response
        console.log(rowdata)
        if (bank!==""){
            
        await CustomAxios.get(`oasis/master_records/?bankname=${bank}&param=bank`).then((response)=>setSelBank([response.data.data]))}
        else{
            response = await CustomAxios.get(`oasis/master_records/?bankname=${rowdata.bankname}&param=bank`)
            setUpdate(true)
            setNewBank(true)
            setBank(rowdata.bankname?.trim())
            setSwiftcode(rowdata.swiftcode?.trim())
            setBankDesc(rowdata.bank_description?.trim())

        }
        // console.log(response.data)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        let payload
        let error
        if (bank === ""){
            error = "Bank name is required"
            setErrors(error)
        }
        else{
            error = ""
            setErrors('')
        }
        if (update==true){
        payload = {
            bankname:bank,
            swiftcode:swiftcode??null,
            bank_description:bankdesc??null,
            update:update,
            param:"bank"
        }}
        else{
            payload = {
                bankname:bank,
                swiftcode:swiftcode??null,
                bank_description:bankdesc??null,
                update:update,
                param:"bank"
            }}
            handleApi(payload, error)
        }

        const handleApi = async (payload, error) => {
            if (error == ""){
                const response = await CustomAxios.post('oasis/master_records/', payload)
                    if (response.data?.message=="Success!"){
                        setSucces(true)
                        setBank(response.id)
                    }}

        }

    const clearform = (e) => {
        e.preventDefault()
        setUpdate(false)
        setNewBank(false)
        setBank('')
        setSwiftcode('')
        setBankDesc('')
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(records);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Bank');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    const [filters, setFilters] = useState({
        bankname:{value:null, matchMode:FilterMatchMode.CONTAINS},
        swiftcode:{value:null, matchMode:FilterMatchMode.CONTAINS},
        bank_description:{value:null, matchMode:FilterMatchMode.CONTAINS}
    })

    const selectBank = (rowData, rowIndex)=>{
        return(<Tooltip title="View & Edit" arrow><IconButton  onClick={()=>SelectedBank(rowData)}><ModeEditIcon/></IconButton></Tooltip>)
    }

    const [bankColumn, setBankColumn] = useState([
        {field:"bankname", header:"Bank Name", visible:true, style:{width:"100px"}, filter:true},
        {field:"swiftcode", header:"Swift Code", visible:true, style:{width:"200px"}, filter:true},
        {field:"bank_description", header:"Bank Description", visible:true, style:{width:"100px"}, filter:true},
        { field:"bankname",header:"Actions", visible:true,body:selectBank ,style:{width:"100px"}}
    ])
    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            SelectedBank()
        }
       }

       const header = (
        <div className="flex align-items-center justify-content-end gap-2" style={{display:"flex", justifyContent:"flex-end"}}>
            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Download BankName Master</Typography>
            <Button sx={{display:"flex", justifyContent:"flex-end" }} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
        </div>
    );


    return(
        <Box className="fulldiv" style={{ width: "100%", height: "calc(100vh - 64px)", marginTop:"60px"}} >
            {newbank==false?
            <Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", height: { sm: 'initial', padding: "0px 20px 0px 20px" }, width: "100%" }}>
                    <Box sx={{ top: "72px", left: "304px", padding: "8px 0px 8px 0px", display: "flex", justifyContent: "space-between", borderBottom: "1px solid #CBD5E1", alignItems: "center", height: "84px" }}>
                        <Box sx={{ maxWidth: "200px", height: "48px", padding: "8px 0px 8px 0px", gap: "12px", display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "32px", height: "32px" }}>
                                <AccountBalanceOutlinedIcon sx={{ width: "32px", height: "32px", color: theme.palette.primary.main }} />
                            </Box>
                            <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: theme.palette.primary.main }}>Bank Name</Typography>
                        </Box>
                            {/* <Box className="p-input-icon-left" sx={{ display: "flex", gap: "24px" }}>
                                <i className="pi pi-search" style={{ color: "#64748B", zIndex: "2" }} />
                                <InputText 
                                value={bank} 
                                placeholder="Bank Name" 
                                onKeyUp={(e) => handleEnter(e)} 
                                onChange={(e) => setBank(e.target.value)} 
                                style={{
                                    height: "36px",
                                    width: "400px",
                                    fontSize: "16px",
                                    color: "#64748B",
                                    }} />
                            </Box> */}

                        <MuiSearchBar
                            handleEnter={handleEnter}
                            search={bank}
                            setSearch={setBank}
                            placeholder="Bank Name"
                            />

                            <Box sx={{ display: "flex", gap: "20px", width:"330px", justifyContent: "flex-end" }}>
                            <MuiButton
                                variant="contained"
                                onClick={(e) => {
                                    clearform(e)
                                    setNewBank(true)
                                }} 
                                value="Add Bank"
                                icon={<AccountBalanceOutlinedIcon />}
                            />

                            {/* <Button 
                            onClick={(e) => {
                                clearform(e)
                                setNewBank(true)
                            }} 
                            variant="contained" 
                            sx={{ maxWidth: "150px", width: "100%", height: "36px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }}>
                                <AccountBalanceOutlinedIcon sx={{ width: "24px", height: "22px", color: "#FFFFF" }} /> <Typography sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "400", lineHeight: "24px", fontFamily: "inter" }}>Add Bank</Typography>
                            </Button> */}
                            </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <DataTable value={bank != "" && selbank.length > 0 ? selbank : records} header={header} scrollable style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filters={filters} filterDisplay="row" emptyMessage="No Bulks found.">
                        <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                        {bankColumn.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                    </DataTable>
                </Box>
            </Box> :
            <form onSubmit={(e)=>{handleSubmit(e)}}>
            <Box sx={{position:"relative"}}>
            {success==true&& <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #288d2c",background:"#DCFCE7",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#16A34A"}}>{update?"Bank Name updated successfully":"New Bank Name added successfully"}</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                </Box>}
                <Box sx={{height:"calc(100vh - 148px)",overflow:"auto"}}>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <AccountBalanceOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                        <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Bank Name</Typography>
                    </Box>
                    <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                        {/* <Button onClick={(e)=>clearform(e)} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> 
                            <ClearIcon sx={{color:"#64748B"}}/>
                            <Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Clear</Typography>
                        </Button> */}
                        <MuiButton
                            variant="text"
                            onClick={(e) => clearform(e)}
                            value="Clear"
                            icon={<ClearIcon/>}
                            iconsx={{
                                color: "#64748B"
                            }}
                        />
                        {/* <button type="submit" variant="contained" style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor:"pointer" }} onClick={(event) => handleSubmit(event)}>
                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}}>Submit</Typography>
                        </button> */}
                        <MuiButton
                            type="submit"
                            variant="contained"
                            onClick={(event) => handleSubmit(event)}
                            value="Submit"
                        />
                    </Box>
                    </Box>
                </Box>
                <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",justifyContent:"space-between",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <AccountBalanceOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>{update==true?"Edit Bank Name":"Create Bank Name"}</Typography>
                                        </Box>
                                </Box>
                              
                            <IconButton onClick={(e)=>{
                                clearform(e)
                                setNewBank(false)}} > <ClearIcon /></IconButton>
                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"289px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Bank Name <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                                <InputText
                                                    required
                                                    value={bank}
                                                    placeholder="Enter Bank Name"
                                                    onChange={(e) => setBank(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />
                                {errors !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors}</Typography>}

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"289px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Swift Code </Typography>
                                                <InputText
                                                    required
                                                    value={swiftcode}
                                                    placeholder="Enter Swift Code"
                                                    onChange={(e) => setSwiftcode(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"289px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Bank Description </Typography>
                                                <InputText
                                                    required
                                                    value={bankdesc}
                                                    placeholder="Enter Bank Description"
                                                    onChange={(e) => setBankDesc(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />

                                </Box>
                                </Box>
                                </Box>
                                </Box>
                </Box>
            </form>}
        </Box>
    )
}