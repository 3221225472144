import { createSlice } from "@reduxjs/toolkit";


const initialState = {

    sideBarActiveTab: 'CO',
    cutoffData: [],
    newMemberData: [],
    modalOpen:false,
    receiptIssuedData:[],
    withdrawalData:[],
    AgeingMemberData:[],
    NomineeStatusData:[],
    receiptIssuedType:"",
    NomineeType:"",
    withdrawalType:"",
    formPayload:{},
    memberLists:[],
    sidebar:false,
    loanLists: [],
    bulkList: [],
    open: true,
};
const IntialReducer = createSlice({
    name: "IntialReducer",
    initialState: initialState,
    reducers: {
        updateSideBarActiveTab: (state, { type, payload }) => {

            state.sideBarActiveTab = payload
        },
        
        updatecutoffData: (state, { type, payload }) => {

            state.cutoffData = payload
        },
        updateApiData: (state, action) => {
            const {type,data} = action.payload
            console.log(type)
            console.log(data)
            switch (type){
                case "CO":
                    state.cutoffData = data
                    break;
                case "NMC":
                    console.log("setting member data")
                    state.newMemberData = data
                    break;
                case "RI":
                    console.log("inside receipt update ")
                    state.receiptIssuedData=data
                    break;
                case "WD":
                    state.withdrawalData=data
                    break;
                case "AM":
                    state.AgeingMemberData=data;
                    break;
                case "NS":
                    console.log(data)
                    state.NomineeStatusData=data;
                    break;
            }
        },
        updateModalOpen:(state, { type, payload })=>{
            console.log(payload)
            state.modalOpen = payload
        },
        updateReceiptIssuedType:(state,action)=>{
            state.receiptIssuedType=action.payload
        },
        updateNomineeType:(state,action)=>{
            state.NomineeType=action.payload
        },
        updateWithdrawalType:(state,action)=>{
            state.withdrawalType=action.payload
        },
        updateFormPayload:(state,action)=>{
            const {type,payload} = action.payload
                    state.formPayload[type]= payload
            },
        updateMemberListPayload:(state=initialState,action)=>{
            console.log(state,action)
            return {
                ...state,
                memberLists:[...state.memberLists, ...action.payload]
              };
            },
        updateLoanListPayload:(state=initialState,action)=>{
            return {
                ...state,
                loanLists:[...action.payload]
              };
        },
        updateBulkListPayload:(state=initialState,action)=>{
            return {
                ...state,
                bulkList:[...state.bulkList, ...action.payload]
              };
        } ,    
        updateSidebar:(state,action)=>{
            console.log(action)
                    state.sidebar= action.payload
                    console.log(state.sidebar)
            }, 
        setOpen: (state, action) => {
            state.open = action.payload;
        },
    },

})

export const { updateSideBarActiveTab, updatecutoffData,updateApiData,
    updateModalOpen,updateReceiptIssuedType,updateNomineeType,updateMemberListPayload,
    updateWithdrawalType,updateFormPayload,updateLoanListPayload,updateSidebar,updateBulkListPayload,setOpen
} = IntialReducer.actions



export default IntialReducer.reducer;