import { Logout } from "@mui/icons-material";
import { Box, Button,  CircularProgress,  LinearProgress,  Stack, TextField, Tooltip, Typography } from "@mui/material";

import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import { Deposit } from "./deposit";
import { updateReceiptFormPayload } from "../../redux/executiveReducer";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { LinkHeader } from "../../components/linkHeader";
import { ProgressBar } from "primereact/progressbar";
import "../../styles/receipt.css"
import { Dialog } from "primereact/dialog";
import PrintIcon from '@mui/icons-material/Print';
import arial from '../../assets/fonts/Arialn.ttf'
import courier from '../../assets/fonts/cour.ttf'
import arialBold from '../../assets/fonts/Arial MT Black.ttf'

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CustomAxios from "../../utils/CustomAxios";

export function Receiptscopy() {
    const navigate = useNavigate()
    const [banks, setBanks] = useState([])
    const [Bl, setBL] = useState("")
    const [Coopbanks, setCoopBanks] = useState([])
    const [estateArray, setestateArray] = useState([])
    const [bank, setBank] = useState("")
    const [date, setDate] = useState(null)
    const [newDate, setNewDate] = useState(null)
    const [estate, setEstate] = useState()
    const [coopbank, setCoopbank] = useState()
    const [bulkAmount, setBulkAmount] = useState("")
    const [narration, setNarration] = useState("")
    const [currentState, setCurrentState] = useState("")
    const [chequeNo, setChequeNo] = useState("")
    const [checkbox, setCheckbox] = useState(false)
    const [manager, setManager] = useState('')
    const [specialNote, setSpecialNote] = useState("")
    const [memeberid, setMemberId] = useState("")
    const [OldNric, setOldNric] = useState("")
    const [newNric, setNewNric] = useState("")
    const [memberName, setMemberName] = useState("")
    const [amount, setAmount] = useState("")
    const [transtype, setTransType] = useState("")
    const [currentMember, setCurrentMember] = useState([])
    // const api = `${process.env.REACT_APP_OASIS_BE_URL}oasis/`
    const formRef = useRef(null);
    const formRefdeposit = useRef(null);
    const [payloadstatus, setpayloadStatus] = useState(false)
    const [transdetails, setTransdetails] = useState([])
    const [response, setResponse] = useState([])
    const [Total, setTotal] = useState(0)
    const [filteredMem, setFilteredMem] = useState([])
    const [memberShareCheck, setMemberSharecheck] = useState([])
    const [isLoading,setLoading] = useState(false)
    const [updatedTable,setUpdatedTable] = useState("")
    const [borderColor, setBorderColor] = useState('');
    const [errors,setErrors] = useState({share:"",member:""})
    const [bulkList,setBulkList]=useState()
    const [modalOpen,setModalOpen]=useState(false)
    const [searchEstate,setSearchEstate]=useState()
    const [checkEntry, setCheckEntry] = useState(false)
    // const [payloadUpdate,setPayloadupdate]=useState()
    const [payload,setPayload]=useState()
    let estateList = estateArray.map(data => data.estatename)
    let coopBankList = Coopbanks.map(data => data.coopbankname)
    let bankList = banks.filter((data) => data.bankname !== null).map((filteredData) => filteredData.bankname)
    const [bulkNo,setBulkNo]=useState()
    const [bulkTrans,setBulktrans]=useState([])
    const [finalMembers,setfinalMembers] = useState([])
    const [receiptNo,setReceiptNo]=useState()
    const [cheque,setCheque]=useState("")
    const [action,setAction]=useState()
    const [loanEnable,setLoanEnable]=useState(true)
    const [finalList,setFinalList]=useState([])
    const [loanList,setLoanList]=useState([])
    const [loanid,setLoanid]=useState("")
    const today = new Date();

    // Add 7 days to today's date
    const sevenDaysAfter = new Date(today);
    sevenDaysAfter.setDate(today.getDate() + 7);

    const [isCLLoading,setIsClLoading] = useState(false)
useEffect(()=>{
    const gettingStates = async () => {
        const finalMembers = await CustomAxios.get('oasis/form_details/').then((data) => {
            setBanks(data.data.bankName)
            setestateArray(data.data.estateList)
            setCoopBanks(data.data.coopBankname)
        })
    }
    gettingStates()
},[])

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };
    let dateFormate = ""
    useEffect(() => {
        // console.log("here", estateArray.length)
        if (estate&&estateArray.length > 0) {
            // console.log("inhere")
            //console.log(estateArray)
            const filtered = estateArray.filter((data) => estate == data.estatename || estate==data.estateid)
            //console.log(filtered)
            setCurrentState(filtered[0].state+"-"+filtered[0].add4)
            setManager(filtered[0].manager)
            setEstate(filtered[0].estatename)
            // console.log(filtered[0].state)
        }
        // console.log(dateFormate)
        if(date ){
            if(typeof(date)!=='string'){
            dateFormate = date.map(item => {
                // console.log(item)/

                const originalDate = new Date(item);
                //console.log(originalDate)
                const year = originalDate.getFullYear();
                const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                const day = String(originalDate.getDate()).padStart(2, '0');
                const hours = String(originalDate.getHours()).padStart(2, '0');
                const minutes = String(originalDate.getMinutes()).padStart(2, '0');
                const formattedDate = `${day}-${month}-${year}`;
                const seconds = String(originalDate.getSeconds()).padStart(2, '0');
                setNewDate(formattedDate)
            })
        }
         else{
                const formate = date.split("T")[0]
                const dateasDate = new Date(formate)
                const day = dateasDate.getDate();
                const month = dateasDate.getMonth() + 1; 
                const year = dateasDate.getFullYear();
                const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
                setNewDate(formattedDate)
            }}
    }, [estate,date])
    
    useEffect(()=>{
        setCheque(bank+" "+chequeNo)
       setPayload ((previous)=>{
        return {...previous,
            bankname: bank,
            coopbank:coopbank,
            date: newDate,
            estate: estate,
            state: currentState,
            transamount: parseInt(bulkAmount),
            narration: narration,
            currentState: currentState,
            chequeno: chequeNo,
            estatemanagername: manager,}

       } 
        
    )
        // setPayloadupdate(payload)
    },[bank,coopbank,newDate,estate,currentState,bulkAmount,narration,currentState,chequeNo,manager])
    const handlesubmit = (e) => {
        e.preventDefault()
       //console.log(bank+" "+chequeNo)
        // setPayloadupdate(payload)
        setCheque(bank+" "+chequeNo)
        setpayloadStatus(true)
    }
    const decimalshare=(rowData)=>{
        return parseFloat(rowData.sharebalance).toFixed(2)
   }
   const decimalsub=(rowData)=>{
       return parseInt(rowData.subscriptionbalance).toFixed(2)
   }
   const decimalloan=(rowData)=>{
    // console.log(rowData)
    return parseInt(rowData.loanbalance).toFixed(2)
   }
   const TransType=(rowData)=>{
    // console.log(rowData)
    if(rowData.transtypeid==1){
        return "Share"

    }
    else if(rowData.transtypeid==2){
        return "Subscription"
    }
    else if(rowData.transtypeid==5){
        return "Loan Repayment"
    }
    else if(rowData.transtypeid==7){
        return "Entrance Fee"
    }
   
   }
   useEffect(()=>{
    let memberlist=[]
    if(bulkTrans.length>0){
        //console.log("????????????????bulktrans",bulkTrans)
        bulkTrans.forEach(async(element)=>{
            //console.log(element)
            // let loan=""
            if(element.transtypeid==5){
                // loan=await axios.post(api+"loanDetails/",{memberid:element.memberid})
                // console.log(loan.data.success.loanid)
    
            }
            // console.log(item)
            // console.log(element)
            await CustomAxios.get(`oasis/specificDeposit/?mid=${parseInt(element.memberid)}`).then((data)=>{
                // console.log(data)
                // console.log(item)
                // console.log(data)
                let item= data.data
                
            //     console.log(item)67721
            
                item.loanid=element.loanno
            
            
            item.transamount=parseInt(element.transamount)
            item.amount=parseInt(element.transamount)
            item.narration=element.narration
            item.bankname=element.bankname
            item.transtypeid=element.transtypeid
            item.transdetailid=element.transdetailid
            if(action=="edit"){
                item.receiptid = element.transdetailid
                item.transmasterid=element.transmasterid
            }
           
            // finalMembers[count].transmasterid = data.transmasterid
            memberlist.push(item)
    setfinalMembers((previous)=>{return [...previous, item].sort((a,b)=>a.transdetailid-b.transdetailid )})
            
            }).catch(err=>{
                console.error(err)
               
                
            })
           
            
    
        
    })
    
    // });
    // console.log("????????????member list",memberlist)

    

    }
    
   },[bulkTrans])

   useEffect(()=>{
    console.log("insidetrantype change ")
let loan=""
if (transtype!=5){
    console.log("insidetrantype change ")
    setLoanEnable(true)
}
else {
    loan_Numbers()
   console.log(loan)

 

}
if(finalMembers.length>0&&finalMembers[0].receiptid!=''){
    console.log(finalMembers)
    finalMembers.sort((a,b)=>a.receiptid-b.receiptid)
    
}
   },[transtype,finalMembers])
   const loan_Numbers=async()=>{
   let loan=await CustomAxios.post("oasis/loanDetails/",{memberid:currentMember[0].memberid})
   setLoanList(loan.data.success)
   setLoanEnable(false)


   }
    const SelectedBulk=async(rowDate,action)=>{
        //console.log(rowDate)
        const load={transmaster:rowDate.transmasterid,}
        // console.log(load)
        const response= await CustomAxios.post("oasis/bulkTrans/",load)
        // console.log(response)
        // console.log(rowDate.transdate)
        const dateformat=rowDate.chequedate.split("T")
        // console.log(dateformat[0])
        const dateObject = new Date(dateformat[0]);
        // const 
        const sorted = response.data.memberList.sort((a,b)=> a.transdetailid - b.transdetailid)
        setBulktrans(sorted)
        // let memberlist=[]
        // const member=response.data.memberList.map(item=>item.memberid)
        // const memberList=await axios.post("http://127.0.0.1:8000/oasis/memberList")
        // console.log(memberList.data.memberList)
        // memberList.data.memberList.forEach(item => {
           

        // const memberList=await axios()


  // Get individual components of the date (day, month, year)
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Months are zero-based
  const year = dateObject.getFullYear();

  // Format the date components as a string in "dd-mm-yyyy" format
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
//   console.log(formattedDate)
        // payload.bank=rowDate.bankname
        // payload.coopbank=rowDate.coop_bank
        // payload.date=formattedDate
        // payload.estate=rowDate.estatemanagername
        // payload.state=rowDate.estatemanagername
        // payload.bulkAmount=rowDate.transamount
        // payload.narration=rowDate.narration
        // payload.currentState=rowDate.estatemanagername
        // payload.chequeNo=rowDate.chequeno
        // payload.manager=rowDate.estatemanagername
        // console.log(estateArray)
        const estateNo=estateArray.filter(item=>item.estateid==rowDate.estateid)
        // setDate(new Date(formattedDate))
        setNarration(rowDate.narration)
        setBank(rowDate.bankname)
        setCoopbank(rowDate.coop_bank)
        setDate(rowDate.chequedate)
        setEstate(rowDate.estateid)
        // setCurrentState(rowDate.estatemanagername)
        setBulkAmount(rowDate.transamount)
        setCheque(rowDate.chequeno+" "+rowDate.bankname)
        setNarration(rowDate.narration)
        setChequeNo(rowDate.chequeno)
        // setManager(rowDate.estatemanagername)
        if(action=="dup"){
            setPayload ({
                bankname: rowDate.bankname,
                coopbank:rowDate.coop_bank,
                date: formattedDate,
                estate: rowDate.estatemanagername,
                state: rowDate.estatemanagername,
                transamount: rowDate.transamount,
                narration: rowDate.narration,
                currentState: rowDate.estatemanagername,
                chequeno: rowDate.chequeno,
                estatemanagername: rowDate.estatemanagername,
                // bulkNo:rowDate.transmasterid
            })

        }
        else if(action=="edit"){
            //console.log("???????????????????????inside edit")
            setPayload ({
                bankname: rowDate.bankname,
                coopbank:rowDate.coop_bank,
                date: formattedDate,
                estate: rowDate.estatemanagername,
                state: rowDate.estatemanagername,
                transamount: rowDate.transamount,
                narration: rowDate.narration,
                currentState: rowDate.estatemanagername,
                chequeno: rowDate.chequeno,
                estatemanagername: rowDate.estatemanagername,
                bulkNo:rowDate.transmasterid
            })
        }
        // SettingsApplications()
        setAction(action)
        setpayloadStatus(true)
        setModalOpen(false)
        //  setPayloadupdate(payload)
    }
    const handleRemoveMember = async(rowData,data) => {
        console.log(rowData)
        if(rowData.receiptid){
            let payload = {
             transdetailid: rowData.receiptid,
             transmasterid: rowData.transmasterid,
             memberid: rowData.memberid
            }
            console.log(payload)
            const res = await CustomAxios.post(`oasis/remove_deposit/`,payload)
            console.log(res)
            if(res.data == 'deleted successfully'){
                const updatedMembers = [...finalMembers];
            updatedMembers.splice(data.rowIndex,1)
            setTotal(parseInt(Total)-parseInt(rowData.transamount))
            setfinalMembers(updatedMembers);
            }
            
        }
        else{
            const updatedMembers = [...finalMembers];
            updatedMembers.splice(data.rowIndex,1)
            setTotal(parseInt(Total)-parseInt(rowData.transamount))
            setfinalMembers(updatedMembers);
        }
            
    }
    const deleteMem=(rowData,rowIndex)=>{
       return <Button  sx={{color:"red"}} onClick={() => handleRemoveMember(rowData,rowIndex)}>Remove</Button>
    }
   const selectBulk=(rowDate)=>{
    return (< Box sx={{display:"flex",gap:"10px"}}><Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px",justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={()=>SelectedBulk(rowDate,"dup")}>Duplicate</Button>
<Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px",justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={()=>SelectedBulk(rowDate,"edit")}>Edit</Button>

    </Box>)
   }
   const handleFetchAndPrintReceipt = async (receiptId, type) => {
    try {
        // URL to your Django view that generates the PDF
        let receiptUrl;
        if(type == "single"){
            receiptUrl = `oasis/download-Dreceipt/${receiptId}`;
        }
        else{
            receiptUrl = `oasis/download-Dreceipt-all/${receiptId}`;
        }

        // Fetch the PDF receipt
        const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
        if (response.data) {
            // Create a URL for the PDF blob
            const fileURL = URL.createObjectURL(response.data);

            // Open the PDF in a new window/tab
            const win = window.open(fileURL, '_blank');

            // Optionally, automatically trigger the print dialog in the new window
            win.onload = () => {
                win.focus();
                win.print();
            };
        }
    } catch (error) {
        console.error("Error fetching the receipt: ", error);
    }
};

   const PrintData = (rowDate, rowIndex)=>{
    return(
        <Box>
            {rowDate.receiptid && <PrintIcon style={{cursor:"pointer"}} onClick={() => handleFetchAndPrintReceipt(rowDate.receiptid, "single")} />}
        </Box>
    )
   }
   const formattingdate = (date) => {
    //console.log(date)
    if (date) {
        const originalDate = new Date(date.transdate);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    else {
        return date.transdate
    }

}
   

    const [columnVisible, setColumneVisible] = useState([
        { field: "receiptid", header: "RN", visible: true},
        { field: "memberid", header: "M.NO", visible: true },
        { field: "membername", header: "Member Name", visible: true },
        { field: "estate", header: "EstateName", visible: true },
        // { field: "transmasterid", header: "Transaction", visible: true },
        { field: "narration", header: "Narration", visible: true },
        { field: "loanid", header: "LN/SD/BR", visible: true },
        { field: "amount", header: "Amt (RM)", visible: true },
        { field: "loanbalance",header:"LoanBal", visible:true,body:decimalloan},
        { field: "sharebalance", header: "Current Share Bal (RM)", visible: true ,body:decimalshare},
        { field: "subscriptionbalance", header: "Current SUBS Bal (RM)", visible: true,body:decimalsub },
        { field:"transtypeid",header:"TransType",visible:true,body:TransType},
    ])

    const exportColumns = columnVisible.map((col) => ({ title: col.header, dataKey: col.field }));
    exportColumns.splice(0,0,{title:"S/N", dataKey:"SN"})
    //console.log(exportColumns)

    const [bulkColumnVisible, setBulkColumneVisible] = useState([
        
        { field: "transmasterid", header: "Bulk No", visible: true ,style:{width:"100px"}},
        { field: "transdate", header: "Date", visible: true, body:formattingdate, style:{width:"200px"} },
        { field: "estatename", header: "Estate Name", visible: true,style:{width:"200px"}},
        { field: "estatemanagername", header: "Manager Name", visible: true ,style:{width:"200px"}},
        { field: "transamount", header: "Bulk Amount", visible: true,style:{width:"100px"} },
        { field: "bankname", header: "Bank Name", visible: true,style:{width:"100px"}},
        { field:"chequeno",header:"Cheq No",visible:true,style:{width:"100px"}},
        { field:"transmasterid",header:"Actions", visible:true,body:selectBulk ,style:{width:"100px"}}
       
        
    ])

    const exportBulkColumns = bulkColumnVisible.map((col) => ({ title: col.header, dataKey: col.field }));
   
    const clearform = (e) => {
        e.preventDefault()
        setCurrentMember([])
        setMemberId("")
        setOldNric("")
        setNewNric("")
        setMemberName("")
        setAmount("")
        setTransType("")
        setLoanid("")
        setLoanEnable(true)
        // setBulkNo("")
        // setReceiptNo("")
        // setAction("")
    }
    const clearFormMaster = (e) => {
        e.preventDefault()
        setBank("")
        setDate(null)
        setEstate("")
        setNarration("")
        setBulkAmount("")
        setCurrentState("")
        setChequeNo("")
        setCoopbank("")
        setCheckbox(false)
        setBulkNo("")
        setReceiptNo("")
        setAction("")
        setBulkList("")
        setPayload ({
            bankname: "",
            coopbank:"",
            date: "",
            estate:"",
            state: "",
            transamount: "",
            narration: "",
            currentState: "",
            chequeno: "",
            estatemanagername: "",
            bulkNo:""
        })
    }

    const exportReceipt = (e) => {
        // PURPOSE OF THIS FUNCTION. IN FUTURE WE CAN ENABLE DOWNLOAD OR PRINT OPTION HERE
        if(payload && payload.bulkNo){
            handleFetchAndPrintReceipt(payload.bulkNo, "multiple")
        }
    }
    useEffect(()=>{
        if (finalMembers.length>0){
            // console.log(finalMembers)
            const members=finalMembers.map(item=>{
                item.narration=narration
                return item
            })
            //console.log(members)
        }
    },[narration])
//console.log(date,newDate)
    const handleSave =async (e) => {
        const estateno=estateArray.filter(item=>item.estatename==estate)
        let formattedDate
        if(typeof(date)!=="string"){
        const year = date[0].getFullYear();
        const month = String(date[0].getMonth() + 1).padStart(2, '0');
        const day = String(date[0].getDate()).padStart(2, '0');
        const hours = String(date[0].getHours()).padStart(2, '0');
        const minutes = String(date[0].getMinutes()).padStart(2, '0');
        const seconds = String(date[0].getSeconds()).padStart(2, '0');
        formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    }
        payload.chequedate =typeof(date)!=="string"?formattedDate:date
        const FinalPayload = {
            ...payload,
            estateid:estateno[0].estateid,
            transdetails:finalMembers,
            
        }
        let response
        //console.log(payload)

        // payload={...payload,transdetails:finalMembers}
        setLoading(true)
        if(action=="dup"){
            response=await CustomAxios.post('oasis/deposit/',FinalPayload)
            setDate(response.data.success.chequedate)
        }
        else if(action=="edit"){
            response=await CustomAxios.post('oasis/edit/',FinalPayload)
            setDate([response.data.success.chequedate])
        }
        else{
            response=await CustomAxios.post('oasis/deposit/',FinalPayload)
            setDate(response.data.success.chequedate)
        }
        //console.log(response)
        setTransdetails(response.data.success.transdetails)
        setfinalMembers([])
        setResponse(response.data.success)
        setPayload((payload=>{
            return {...payload,bulkNo:response.data.success.masterid}}))
        setLoading(false)
        setChequeNo(response.data.success.chequeno)
        setBank(response.data.success.bankname)
        // setDate(response.data.success.chequedate)
    }
    useEffect(()=>{      
        if(currentMember.length > 0){
            const filtered = finalMembers.filter((mem)=>mem.memberid == currentMember[0].memberid)
            if(currentMember.length>0){
                (currentMember[0].sharebalance<1200||(memberShareCheck.length>0&&memberShareCheck[0].updatedShareBal<1200))?setTransType(1):setTransType(2)}
            setMemberSharecheck(filtered)
        }
        if(memeberid){
            setAmount("")
        }
    },[currentMember,memeberid]) 
    const handleAdd = async(e) =>{
        e.preventDefault()
        errors.share= ""
        let updated = 0
        const filteredMem = finalMembers.filter((member)=>member.memberid==currentMember[0].memberid)
        
        //console.log(filteredMem.length>0,amount!=="",transtype==1)
        if(filteredMem.length>0&&amount!==""&&transtype==1){
           if(((filteredMem[filteredMem.length-1].updatedShareBal)+parseInt(amount)<=1200)||((filteredMem[filteredMem.length-1].sharebalance)+parseInt(amount)<=1200)){
            errors.share= ""
            //console.log(((filteredMem[filteredMem.length-1].updatedShareBal),parseInt(amount)<=1200))
                   setFilteredMem(filteredMem)
                   updated = filteredMem[filteredMem.length-1].updatedShareBal?parseInt(filteredMem[filteredMem.length-1].updatedShareBal)+parseInt(amount):parseInt(filteredMem[filteredMem.length-1].sharebalance)+parseInt(amount)
                   //console.log(updated)
                   filteredMem.map((data)=>
                   data.updatedShareBal=updated
                   )
           }else{
               errors.share= "exceededSharebalance"
           }
        }
        //console.log("updated",updated,errors)
        // let loan=""
        // //console.log(transtype)
        if(transtype==5){
            // loan=await axios.post(api+"loanDetails/",{memberid:currentMember[0].memberid})
            //console.log(loan.data.success.loanid)
            currentMember[0].loanid=loanid
        }
        else{
            currentMember[0].loanid=""
        }
        // if(loan!=""){
        //     currentMember[0].loanid=loan.data.success.loanid
        // }
        // else{
        //     currentMember[0].loanid=""
        // }
                
        if(finalMembers.length==0||(updated<=1200&&errors.share=="")||transtype==2){
        currentMember[0].transtypeid = transtype
        currentMember[0].updatedShareBal =finalMembers.length!==0&&updated!=0? updated:transtype==1?parseInt(currentMember[0].sharebalance)+parseInt(amount):filteredMem.length!==0?filteredMem[0].updatedShareBal:0
        currentMember[0].updatedSubscriptionBal = parseInt(currentMember[0].subscriptionbalance)+parseInt(amount)
        currentMember[0].narration = narration
        currentMember[0].amount = amount
        currentMember[0].transamount =parseInt (amount)
        currentMember[0].bankname=bank
        
        //console.log(currentMember[0].memberid)
       
        //console.log(finalMembers)
        //console.log(currentMember)
        setfinalMembers([...finalMembers,currentMember[0]])}
        setCurrentMember([])
        clearform(e)
        setFilteredMem([])
    }
    const searchBulk= async()=>{
        //console.log(estate)
        let load
        if (searchEstate){
            const estateno= estateArray.filter(item=>item.estatename==searchEstate)
            load={estateid:estateno[0].estateid,bulkNo:bulkNo}
        }
        else if (bulkNo){
            load={bulkNo:bulkNo}
        }
        else if(receiptNo){
            load={receiptNo:receiptNo}
        }
        //console.log(load)
        const bulkList= await CustomAxios.post("oasis/bulklist/",load)
        const sortedArray = bulkList.data.memberList.sort((a, b) => new Date(b.transdate) - new Date(a.transdate));
        console.log(bulkList.data.memberList)
        setBulkList(sortedArray)
    }
    useEffect(()=>{
    let count = 0
     if(transdetails.length > 0){
       let updatepayment=[...finalMembers]
   transdetails.forEach(async(data)=>{
        console.log(count)
        console.log(updatepayment[count])
       const res= await CustomAxios.get(`oasis/specificDeposit/?mid=${parseInt(data.memberid)}`)
       console.log(res.data.subscriptionbalance)
       console.log(res.data.loanbalance)

    //    updatepayment[count].receiptid = data.receiptid
    //    updatepayment[count].transmasterid = data.transmasterid
        
       data.sharebalance = res.data.sharebalance
        
       data.subscriptionbalance = res.data.subscriptionbalance

        
       data.loanbalance = res.data.loanbalance



        
       count=count+1
       
        
        // count=count+1
        setfinalMembers((previous=>[...previous,data]))
    })
   
    }
    },[transdetails])
    let total = 0
    const grandTotal=()=>{
        if(finalMembers.length>0){
            total = 0
            finalMembers.map((data)=>{
          total += parseInt(data.amount)
          setTotal(total)  
        })
        return total}
    }
    let balance=0
    const Balance=()=>{ 
        balance = bulkAmount - total
        setBL(balance)
        return balance
    }
    const checkDOJ=(doj)=>{
        const date = new Date(doj.doj);
        const today = new Date();
        //console.log(doj.doj)
        //console.log(date)
        const differenceInMilliseconds = today - date;
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        //console.log(differenceInDays)
        if (differenceInDays <= 7) {
            //console.log("we are here")
            return true;
        } else {
            //console.log("we are not here")
            return false;
        }

    }

    const footGroup=(
        <ColumnGroup>
        <Row>
            <Column footer={"Total : "} colSpan={6} footerStyle={{ textAlign: 'right' }} />
            <Column footer={grandTotal}  footerStyle={{ textAlign: 'left' , fontWeight:'normal'}} />
            <Column footer={"Balance : "} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Balance}  footerStyle={{ textAlign: 'left' , fontWeight:'normal'}} />
        </Row>
    </ColumnGroup>
    
    )
    const backFunction = (e) => {
        setfinalMembers([])
        setResponse([])
        setTotal(0)
        clearform(e)
        setpayloadStatus(false)
        clearFormMaster(e)
    }
    const GetMemberDetails = async(e)=>{
        e.preventDefault()
        if(!isNaN(e.target.value) && Number.isInteger(parseInt(e.target.value))||(newNric!="")||(memberName!="")){
            let payload
            if (memeberid!=""){
                //console.log(memeberid)
                payload = {
                    "memberid":parseInt(memeberid)
                }
            }
                if (newNric!=""){
                    //console.log(newNric)
                    payload={
                    "icno":newNric}
                    //console.log(payload)
                }
                    if (memberName!=""){
                        payload={
                        "name":memberName}
                    }
            

            try{
                errors.member= "";
                setLoading(true);
                CustomAxios.post(`oasis/getmember/`, payload).then((data)=>{
                    // console.log(data)
                    let res_data = data.data
                    CheckEntryFee(data.data.memberid)
                    setCurrentMember([res_data])
                    setNewNric(data.data.newicno)
                    setMemberName(data.data.membername)
                    setMemberId(data.data.memberid)
                setLoading(false);
                    
                }).catch(err=>{
                    //console.error(err)
                    setLoading(false);
                errors.member= "not available";
                    
                })
            }
            catch(err){
                //console.error(err)
                errors.member= "not available";
            }
        }
        else{
            setCurrentMember([])
        }
        
        try {
                let payload = { memberid: memeberid };
                if(memeberid){
                    const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memeberid)}`);
                    //console.log("gggggggggggggggg", response.data);
                    let activeLoans = response.data.loan.filter((loan)=>loan.loanbalanceamount!=="0.00")
                    setLoanList(activeLoans)
                }
                
            } catch (error) {
                console.error("Error fetching member details:", error);
            }
    }
    const handleclick = (e) => {
        e.preventDefault()
        if(e.key=="Enter"){
            GetMemberDetails(e)
        }
    }
    const handleUpdate = (e) => { 
        //console.log(updatedTable, e.key)
        if(updatedTable!==""&& e.key=="Enter"&&borderColor=="green"&&updatedTable.event.data.transtypeid==1){
            setBorderColor('green')
            if (updatedTable.event.field=="amount"){
            //console.log((parseInt(updatedTable.event.data.updatedShareBal)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value))

            updatedTable.data[updatedTable.event.index].updatedShareBal =updatedTable.event.data.updatedShareBal?(parseInt(updatedTable.event.data.updatedShareBal)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value):(parseInt(updatedTable.event.data.sharebalance)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value)
            updatedTable.data[updatedTable.event.index].amount = parseInt(updatedTable.event.value);
            updatedTable.data[updatedTable.event.index].transamount = parseInt(updatedTable.event.value);
            const filteredMem = finalMembers.filter((member)=>member.memberid==updatedTable.data[updatedTable.event.index].memberid)
                filteredMem.map((data)=>
                data.updatedShareBal=updatedTable.data[updatedTable.event.index].updatedShareBal
                )
                //console.log(updatedTable)
            setfinalMembers(updatedTable.data)}
        }
        else if(updatedTable!==""&&e.key=="Enter"&&borderColor=="green"&&(updatedTable.event.data.transtypeid==2||updatedTable.event.data.transtypeid==5)){
            if(updatedTable.event.field=="amount"){
                 updatedTable.data[updatedTable.event.index].amount = updatedTable.event.value; 
            updatedTable.data[updatedTable.event.index].transamount = parseInt(updatedTable.event.value);
            //console.log(updatedTable)
            }
           
            if(updatedTable.event.field=="narration"){
                //console.log("updatedfinal",e)
                updatedTable.data[updatedTable.event.index].narration =updatedTable.event.value;

            }
            setfinalMembers(updatedTable.data)
        }
        setBorderColor("") 
        setUpdatedTable("")
    }
    const onEditorValueChange = (e) => {
        let updatedfinal = [...finalMembers];
        // console.log((total+parseInt(e.value)-updatedfinal[e.index].transamount)<=parseInt(bulkAmount))
        //console.log((total),e,parseInt(e.data.transamount))
        if(e.field=="narration"||(total+parseInt(e.value)-parseInt(e.data.transamount))<=parseInt(bulkAmount)){
        if(((updatedfinal[e.index].updatedShareBal)-parseInt(updatedfinal[e.index].transamount)<1200||(updatedfinal[e.index].sharebalance)-parseInt(updatedfinal[e.index].transamount)<1200 )&& updatedfinal[e.index].transtypeid==1){
            if(updatedfinal[e.index].updatedShareBal?((parseInt(updatedfinal[e.index].updatedShareBal)-parseInt(updatedfinal[e.index].transamount))+parseInt(e.value))<1201:((parseInt(updatedfinal[e.index].sharebalance)-parseInt(updatedfinal[e.index].transamount))+parseInt(e.value))<1201){
                //console.log("inside share")
                setBorderColor('green')
                setUpdatedTable({data:updatedfinal,event:e});
            }
            else if(e.field=="narration"){
                setBorderColor('green')
                updatedfinal[e.index].narration = e.value;
                setUpdatedTable({data:updatedfinal,event:e})
            }
            else{
                setBorderColor('red')
            }
        }
        else if(updatedfinal[e.index].transtypeid==2||updatedfinal[e.index].transtypeid==5){
            //console.log("inside subs okkkk")
            setBorderColor('green')
            setUpdatedTable({data:updatedfinal,event:e});

            
        }}
        else{
            //console.log(e.value,"===============================")
            setBorderColor('red')
            updatedfinal[e.index].transamount = finalMembers[e.index].transamount;
            updatedfinal[e.index][e.field] = finalMembers[e.index].amount;
        }

      };
     
    const inputEditor = (props) => {
    if((Object.keys(response).length==0 &&props.field=="amount")||(Object.keys(response).length==0 && props.field=="narration")){
        // setBorderColor("green")
        const inputStyle = {
            border: `1px solid ${borderColor}`,
            outline: `1px solid ${borderColor}`,
            height:"30px",
            borderRadius:"4px"
          };
        return (
            <InputText
            type={props.field=="amount"?"number":"text"}
            //   value={props.value}
            style={inputStyle}
            onKeyDown={(e)=>handleUpdate(e)}
            onChange={(e) => onEditorValueChange({ index: props.rowIndex, field: props.field, value: e.target.value,data:props.props.value[props.rowIndex] })}
            />
          );}
          else {
            return <Box>{props.field=="sharebalance"||props.field=="subscriptionbalance"||props.field=="loanbalance"? parseInt(props.value).toFixed(2):props.field=="transtypeid"?props.value==1?"Share":"Subscription":props.value}</Box>;
          }
      };
      //console.log(finalMembers)
    //   console.log(borderColor)
    //   console.log(updatedTable)
    const handleMemberChange = (e) => {
        setMemberName("")
        setNewNric("")
        setMemberId(e.target.value)
    }
    const handleNricChange = (e) => {
        setMemberName("")
        setNewNric(e.target.value)
        setMemberId("")
    }
    const handleNameChange = (e) => {
        setMemberName(e.target.value)
        setNewNric("")
        setMemberId("")
    }
    const downlaomanagerLetter=()=>{
        if(payload && payload.bulkNo){
            printManagerLetter(payload.bulkNo, )
        }
    }

    const printManagerLetter=async(receiptId, )=>{
        try {
            // URL to your Django view that generates the PDF
            let receiptUrl;
            // if(type == "single"){
                receiptUrl = `oasis/manager_letter/${receiptId}`;
            // }
            
    
            // Fetch the PDF receipt
            const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
    
                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');
    
                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    }

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 0, 0);
                doc.addFileToVFS('Arialn.ttf', arial);
                doc.addFileToVFS('cour.ttf', courier);
                doc.addFileToVFS('Arial MT Black.ttf', arialBold);
                doc.addFont('Arialn.ttf', 'Arial','normal');
                doc.addFont('Arial MT Black.ttf', 'ArialBold','normal');
                doc.addFont('cour.ttf', 'courierNew', 'normal');

                const pageWidth = doc.internal.pageSize.getWidth();
                const header1 = 'KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)';
                const header2 = 'Bulk Receipt List';
                // Set the title font size, type, and position correctly
                // doc.text('KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)', 14, 15); 
                // doc.text('Bulk Receipt list', 14, 15); 
                const header1Width = doc.getTextWidth(header1); // Calculate width of header
                const header2Width = doc.getTextWidth(header2); // Calculate width of header
                const headerFirst = (pageWidth - header1Width) / 2;
                const headerSecond = (pageWidth - header2Width) / 2;
                // doc.text(header1, headerFirst, 10);
                doc.setFontSize(12);
                doc.setFont('helvetica','bold');
                doc.text(header1, headerFirst+15, 10);
                doc.setFontSize(11);
                doc.setFont('courierNew','normal');
                doc.text(header2, headerSecond, 15);
                // doc.text('Deposit Bulk List', 14, 15); // Position the title
            
                const fieldsAndValues = [
                    { field: 'Manager Name', value: payload.estatemanagername },
                    { field: 'BN', value: payload.bulkNo },
                    { field: 'Estate', value: payload.estate },
                    { field: 'Chq Cleared Date', value:payload.date  },
                    { field: 'State', value: payload.state },
                    { field: 'Chq No', value:chequeNo!==""&&bank!==""?`${bank.trim()} ${chequeNo.trim()}`:"--"},
                    { field: 'Narration', value: payload.narration?payload.narration:narration }
                  ];
                  

    // Loop through field-value pairs and add to PDF
    let VerticalPosition = 23; // Initial vertical position
    fieldsAndValues.forEach(({ field, value },index) => {
      doc.setFontSize(8);
      const horizondal = index % 2 === 0 ? 7 : 230;
      doc.setFont('Arial', 'normal');
      doc.text(field, field=="BN"?horizondal+16:field=="Chq No"?horizondal+10:field=="Chq Cleared Date"?horizondal-5:horizondal, VerticalPosition); // Field label position
      doc.setFont('helvetica','bold');
      doc.text(`:${" "}${value}`, horizondal+22 , VerticalPosition); // Adjust position as needed
      VerticalPosition += index % 2 === 0 ?0:5; // Increment vertical position for the next line
    });
                // doc.setFont("Times", "bold");
                // doc.setFontSize(16);
                // doc.text("KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)", 18, 20, {align: "left"});

                // doc.setFont("Times");
                // doc.setFontSize(14);
                // doc.text("NATIONAL MULTI-PURPOSE CO-OPERATIVE SOCIETY LIMITED", 120, pageHeight - 70, {align: "left"});

                // // Tamil Text Logo
                // // doc.addImage(tamilTextLogoPath, 'PNG', 113, pageHeight - 103, 50, 15); // Adjust size accordingly

                // // Address and Contact
                // doc.setFontSize(11);
                // doc.text("No. 28-1, Jalan USJ 10/1E, Taipan Triangle 47620 Subang Jaya Selangor Darul Ehsan", 120, pageHeight - 118, {align: "left"});
                // doc.text("Tel : 03-5631 5736 / 03-5631 7712   Fax No: 03-5631 8749 Email: kskb4391@gmail.com", 120, pageHeight - 128, {align: "left"});

                // // Drawing a line
                // doc.setDrawColor(0, 0, 0); // Black color
                // doc.setLineWidth(1);
                // doc.line(40, pageHeight - 138, pageWidth - 40, pageHeight - 138);

                // // Transaction Receipt
                // doc.setFont("Times", "bold");
                // doc.setFontSize(18);
                // doc.text("Transaction Receipt", center_x, pageHeight - 163, {align: "center"});
                    
                // Assuming exportColumns is prepared from columnVisible
                // Prepare the data for autoTable based on visible columns
                // const desiredColumnOrder = ['Receipt No', 'M.NO', 'Member Name','Estate Name','Narration',"LN/SD/BR",'Amount (RM)','LoanBal','Current Share Bal (RM)',"Current SUBS Bal (RM)",'TransType']
                const members = [...finalMembers]
                const startY = 40
                members.map((mem,index)=>{
                    mem["SN"]= index+1
                    mem.amount= parseInt(mem.amount).toFixed(2)
                    mem.membername=mem.membername.toUpperCase()
                    mem.transtypeid=mem.transtypeid==1?"SHARE":mem.transtypeid==2?"SUBS":mem.transtypeid==5?"LOAN":mem.transtypeid==7?"ENT FEE":""})
                    //console.log(members)
                const options = {
                    startY: startY,
                    theme:'plain',
                    margin: { top:5,right: 5, bottom: 20, left: 5  },
                    headStyles: { 
                        fillColor: 'white',  
                        textColor: 'black',   
                        fontFamily:"Arial",
                        fontStyle:'normal',
                        fontSize:8,
                        valign: 'bottom',
                        halign:'left',
                        lineWidth: {
                            bottom: .3,
                        },
                        lineColor: 'black',
                        
                    },
                    columnStyles: {
                        0:{fontSize:8,cellWidth: 8},
                        1:{fontSize:8,cellWidth: 15},
                        2:{fontSize:8,cellWidth: 15},
                        3:{fontSize:8,cellWidth: 50},
                        4:{fontSize:8,cellWidth: 40},
                        5:{fontSize:8,cellWidth: 16},
                            6:{ halign: 'center',fontSize:8 ,cellWidth: 18},
                            7: { halign: 'right',fontSize:8 ,cellWidth: 16},
                            8: { halign: 'right' ,fontSize:7,cellWidth: 18},
                            9: { halign: 'right',fontSize:7,cellWidth: 35},
                            10: { halign: 'right',fontSize:7 ,cellWidth: 35},
                            11: { fontSize:7 ,cellWidth: 18},
                          },
                    columns: exportColumns,
                    body: members,
                    
                };
                const availableSpace = doc.internal.pageSize.height - 40 - 10; 
                const maxTableHeight = +availableSpace - +doc.autoTable.previous.finalY;
                //console.log(maxTableHeight)

                
                doc.autoTable(options);
                const tableHeight = doc.autoTable.previous.finalY - startY;
                const AfterTable = startY + tableHeight + 5;
                const boxWidth = 75; 
                const boxHeight = 11; 
                const xPos = 122; 
                const yPos = AfterTable; 
                doc.rect(xPos, yPos, boxWidth, boxHeight);
                let grandTotalinTable = parseInt(Total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const textxposition = xPos+10
                const textyposition = yPos+6
                doc.setFont('helvetica','normal');
                doc.setFontSize(8);
                doc.text(`Grand Total : `,textxposition,textyposition);
                doc.setFont('helvetica','bold');
                doc.text(`${" "} ${grandTotalinTable}`,textxposition+38,textyposition);
                const currentDate = new Date();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, hence the +1
                const day = String(currentDate.getDate()).padStart(2, '0');
                const year = currentDate.getFullYear();
                const totalPages = doc.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                  doc.setPage(i);
                  doc.setFontSize(8);
                  doc.setFont('helvetica', 'normal')
                  doc.text(`Page ${i} of ${totalPages}`, doc.internal.pageSize.width - 25, doc.internal.pageSize.height - 5);
                  doc.text(`Print Date: ${day}-${month}-${year}`, 5, doc.internal.pageSize.height - 5);
                }
                doc.save('receipts.pdf'); 
                
    
                
            });
        });
    };

    const exportBulkPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 0, 0);

                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                const center_x = (pageWidth - 100) / 2;
    
                // Set the title font size, type, and position correctly
                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.text('Deposit Bulk List', 14, 15); // Position the title

                const options = {
                    startY: 20, // Adjust startY as needed to avoid overlapping the title
                    headStyles: {
                        fillColor: 'white', // Sets the background color of the header
                        textColor: 'black', // Sets the text color of the header
                        fontStyle: 'bold', // Makes the header text bold
                        halign: 'center',
                        // Centers header text
                    },
                    columns: exportBulkColumns,
                    body: bulkList,
                };
    
                doc.autoTable(options); // Generate the table with the prepared data
    
                doc.save('Bulk_receipts.pdf'); // Save the PDF
            });
        });
    };

    const CheckEntryFee=async(memberid)=>{
        const payload={memberid:memberid}
        const response = await CustomAxios.post("oasis/getmember/",payload)
            //console.log(response.data)
                    
            if (response.data.entryfee==true) {
                // Create a URL for the PDF blob
                //console.log(response.data.entryfee)
                setCheckEntry(true);
            }
            else{
            setCheckEntry(false);
    }
    }

    const exportContributionList = (e)=>{
        setIsClLoading(true)
        e.preventDefault()
        let api_payload = {
            manager:payload.estatemanagername!==null?payload.estatemanagername.trim():"--",
            estate:payload.estate,
            state:payload.state,
            narration:payload.narration,
            bulkNo:payload.bulkNo,
            chqClearDate:payload.date,
            chqNo:payload.bankname!==""&&payload.chequeno!==""?payload.bankname.trim()+" "+payload.chequeno.trim():"--"
        }
        let empty_ValuedKeys = []
        for (let [key,value] of Object.entries(api_payload)){
            if (!value){
                empty_ValuedKeys.push(key)
            }
        }
        try {
            
            if(empty_ValuedKeys.length === 0){
                CustomAxios.post("oasis/download-contributionPdf/",api_payload,{ responseType: 'blob' }).then((res)=>{
                    
                    if (res.data) {
                        // Create a URL for the PDF blob
                        const fileURL = URL.createObjectURL(res.data);
                        //console.log(fileURL)
            
                        // Open the PDF in a new window/tab
                        const win = window.open(fileURL, '_blank');
                    }
                }).catch((err)=>console.error(err))
            }
            else{
                console.error("-----Values Required------",empty_ValuedKeys)
            }
            
        } catch (error) {
            console.error(error)
        }
        setIsClLoading(false)
    }
    const dateformatedStart = (rowData) =>{
        const date = new Date(rowData.loanstartdate)
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        console.log(formattedDate)
        return formattedDate;
    }
    const dateformatedEnd = (rowData) =>{
        const date =new Date(rowData.loanexpirydate)
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        console.log(formattedDate)
        return formattedDate;
    }
    const [ColumnVisibleLoan, setColumneVisibleLoan] = useState([
        
        { field: "loanid", header: "Loan#", visible: true },
        { field: "loanstartdate", header: "LoanDate", visible: true,body:dateformatedStart },
        { field: "principalamount", header: "Principal", visible: true},
        { field: "noofinstalment", header: "#inst", visible: true },
        { field: "loanexpirydate", header: "Expiry", visible: true ,body:dateformatedEnd},
        { field: "loanbalanceamount", header: "LoanBalance", visible: true},
       
        
    ])
    // console.log((parseInt(memberShareCheck[0].updatedShareBal)+parseInt(amount))>1200)
    return (
        <Box sx={{marginTop:"60px"}}>
            {payloadstatus !== true ? <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <LinkHeader/> */}
                <Box sx={{ padding: "27px" }}>
                    <form ref={formRef} onSubmit={(e)=>handlesubmit(e)} style={{ maxWidth: "672px", width: "100%", marginTop: "20px", gap: "40px", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bank (Deposit)<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <Dropdown required value={coopbank} onChange={(e) => setCoopbank(e.target.value)} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} options={coopBankList}
                                placeholder="All" filter />
                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Check cleared Date<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <Calendar required value={date?new Date(date[0]):null} dateFormat="dd-mm-yy" onChange={(e) => {setDate([e.value])}}  style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(!checkbox)} /><Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Cheque</Typography>
                        </Box>
                        {checkbox == true && <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Box sx={{ display: "flex", gap: "20px" }}><Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bank<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <Dropdown required={checkbox==true?true:false} value={bank} onChange={(e) => setBank(e.target.value)} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} options={bankList}
                                placeholder="All" filter />
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Cheque Number<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={chequeNo}
                                    required={checkbox==true?true:false}
                                    onChange={(e) => setChequeNo(e.target.value)}
                                    style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                /></Box>
                        </Box>}
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Estate Name<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <Dropdown required value={estate} onChange={(e) => setEstate(e.target.value)} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} options={estateList}
                                placeholder="All" filter />
                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>State</Typography>
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>{currentState}</Typography>

                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bulk Amount<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <InputText
                            required
                                value={bulkAmount}
                                type="number"
                                onChange={(e) => setBulkAmount(e.target.value)}
                                style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                            />

                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Please Enter Narration<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <InputText
                            required
                                value={narration}
                                onChange={(e) => setNarration(e.target.value)}
                                style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                            />

                        </Box>
                        <Box sx={{display:"flex",gap:"20px"}}>
                        <Button type="submit" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }}>
                            Submit
                        </Button>
                        <Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={(e)=> clearFormMaster(e)}>
                            Clear
                        </Button>
                        <Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={()=>setModalOpen(true)}>
                            Search
                        </Button>
                        </Box>
                    </form>
                </Box>
            </Box> :
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {/* <LinkHeader/> */}
                   {isLoading?
                    <Stack rowGap="10px" sx={{width:"100%",height:"87vh",alignItems:"center",justifyContent:"center"}}>
                        <Typography>Please Wait</Typography>
                        <ProgressBar mode="indeterminate" style={{ width:"25%", height: '6px' }}/>
                    </Stack> 
                   :<Box sx={{ padding: "27px", display: "flex", flexDirection: "column", gap: "20px", width: "100%", overflow: "auto" }}>
                        {/* <Dialog visible={modalOpen} style={{ width: '80%', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} finalMembersizable={false} >

                            <TransactionSummary />
                        </Dialog> */}

                        <Box sx={{ display: "flex", gap: "20px", width: "100%", overflow: "auto" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}><span style={{fontWeight:"700"}}>Manager Name:</span> {payload.estatemanagername}</Typography>
                                <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{  alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px"  }}>Estate Name</Typography>
                            <Dropdown required value={estate} onChange={(e) => setEstate(e.target.value)} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} options={estateList.sort()}
                                placeholder="All" filter />
                        </Box>
                        <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                            <Typography sx={{  alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px"  }}>State</Typography>
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>{currentState}</Typography>

                        </Box>
                                <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                                    <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Please Enter Narration</Typography>
                                    <InputText
                                        value={payload.narration? payload.narration:narration}
                                        onChange={(e) => setNarration(e.target.value)}
                                        style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />

                                </Box>
                                <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                                    <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>SpecialNote:</Typography>
                                    <InputText
                                        value={specialNote}
                                        onChange={(e) => setSpecialNote(e.target.value)}
                                        style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />

                                </Box></Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>

                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}><span style={{fontWeight:"700"}}>Bank Deposit:</span> {payload.coopbank}</Typography>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}><span style={{fontWeight:"700"}}>Bulk No:</span> {payload.bulkNo}</Typography>
                            <Box sx={{display:"flex",gap:"20px"}}>
                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}><span style={{fontWeight:"700"}}>Cheque Cleared Date:</span> {newDate}</Typography>
                                <Calendar required className="calender" value={dateFormate} onChange={(e) => setDate([e.value])}  style={{ width: "40px", height: "30px", display: "flex", alignItems: "center" }} showIcon/>
                            </Box>

                                {/* <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}><span style={{fontWeight:"700"}}>Cheque No:</span> </Typography> */}
                                <Box sx={{ display: "flex", maxWidth:"900px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                                    <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter",width: "200px",  fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Cheque No:</Typography>
                                    <Box sx={{ display: "flex", gap: "20px" }}><Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bank</Typography>
                            <Dropdown  value={bank} onChange={(e) => setBank(e.target.value)} style={{ width: "300px", height: "36px", display: "flex", alignItems: "center" }} options={bankList}
                                placeholder="All" filter />
                                <Typography sx={{ alignSelf: "stretch", width: "150px",fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Cheque Number</Typography>
                                <InputText
                                    value={chequeNo}
                                    onChange={(e) => setChequeNo(e.target.value)}
                                    style={{ padding: "12px", width: "300px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                /></Box>

                                </Box>
                                <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", alignItems: "flexstart", gap: "8px" }}>
                                    <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Bulk Amount</Typography>
                                    <InputText
                                        value={bulkAmount}
                                        type="number"
                                        onChange={(e) => setBulkAmount(e.target.value)}
                                        style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />

                                </Box>
                            </Box>
                        </Box>
                        <form ref={formRefdeposit} style={{ width: "100%", marginTop: "20px", gap: "40px", display: "flex",alignItems:"flex-end"}}>
                            <Box sx={{ width: "200px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>M#<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={memeberid}
                                    type="number"
                                    disabled={Total>=payload.transamount?true:false}
                                    onChange={(e) => handleMemberChange(e)}
                                    onKeyUp={(e)=>handleclick(e)}
                                    onBlur={(e)=>GetMemberDetails(e)}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box>
                            <Box sx={{ width: "200px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Old IC No</Typography>
                                <InputText
                                    value={currentMember.length>0?currentMember[0].oldicno:OldNric}
                                    disabled={Total>=payload.transamount?true:false}
                                    onChange={(e) => setOldNric(e.target.value)}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box>
                            <Box sx={{ width: "200px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>New Ic No</Typography>
                                <InputText
                                    value={newNric}
                                    disabled={Total>=payload.transamount?true:false}
                                    onChange={(e) => handleNricChange(e)}
                                    onKeyUp={(e)=>handleclick(e)}
                                    onBlur={(e)=>GetMemberDetails(e)}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box>
                            <Box sx={{ width: "200px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Member Name</Typography>
                                <InputText
                                    value={memberName}
                                    disabled={Total>=payload.tranamount?true:false}
                                    onChange={(e) => handleNameChange(e)}
                                    onKeyUp={(e)=>handleclick(e)}
                                    onBlur={(e)=>GetMemberDetails(e)}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box>
                            <Box sx={{ width: "200px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Amount<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={amount}
                                    type="number"
                                    disabled={Total>=payload.transamount?true:false}
                                    onChange={(e) => {
                                    setAmount(e.target.value)
                                    e.target.value>1200||currentMember.length>0&&currentMember[0].sharebalance>=1200||(memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseInt(e.target.value))>1200)?setTransType(2):setTransType(1)
                                    }}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box>
                            <Box sx={{ width: "200px" }}>
                                { <>
                                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Transaction<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                        {currentMember.length>0&&(((currentMember[0].loanbalance==0&&currentMember[0].sharebalance>=1200)||(amount>1200&&currentMember[0].loanbalance==0))||memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseInt(amount)>1200&&currentMember[0].loanbalance==0))?<InputText
                                        value="Subscription"
                                        style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />:
                                    <Select onChange={(e) => setTransType(e.target.value)}
                                    displayEmpty
                                    disabled={Total>=payload.transamount?true:false}
                                    value={transtype}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)", color:"black", height: "48px" }}
                                    >
                                    <MenuItem value="" disabled>Select Transaction Type</MenuItem>
                                    {currentMember.length>0&&(amount > 1200||currentMember[0].sharebalance>=1200||(memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseInt(amount))>1200)) ?null :<MenuItem value={1}>Share</MenuItem>}
                                    <MenuItem selected={amount > 1200} value={2}>Subscription</MenuItem>
                                    {currentMember.length>0&&currentMember[0].loanbalance >0? <MenuItem value={5}>Loan Repayment</MenuItem>:null
                                    
                                    }
                                    {amount==16&&currentMember.length>0&&checkEntry&&<MenuItem value={7}>Entrance Fee</MenuItem>}
                                    </Select>
                                    } 
                                    </>
                                        }
                            </Box >
                            <Box sx={{ width: "200px" }}>
                            { <>
                                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Loan No<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                        
                                    <FormControl disabled={loanEnable}>
                                        <Select
                                    // displayEmpty
                                    // className="Mui-disabled"
                                    
                                    value={loanid}
                                    onChange={(e)=>setLoanid(e.target.value)}
                                    style={{ padding: "12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)", color:"black", height: "48px" }}>
                                    {loanList.map(item=>{
                                        return <MenuItem  value={item.loanid}> {item.loanid}</MenuItem>
                                    })}
                                    
                                    </Select>
                                    </FormControl>
                                    
                                    
                                    </>
                                        }
                            </Box>
                            <Box sx={{height:"42px",display:"flex",gap:"20px"}}>
                            <button type="submit" variant="contained" disabled={memeberid!==""&&amount!==""&&transtype!==""&&Total+parseInt(amount)<=bulkAmount?transtype==1 &&parseInt(currentMember[0].sharebalance)+parseInt(amount)>1200?true:transtype==5 && loanid == ""?true:false:true} style={{ border: "none", color: "#FFF", display: "flex", width: "104px", padding: "12px",justifyContent: "center",alignItems:"center",fontSize:"16px", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)", cursor: memeberid!==""&&amount!==""&&transtype!=="" &&Total+parseInt(amount)<=bulkAmount?transtype==1&&parseInt(currentMember[0].sharebalance)+parseInt(amount)>1200?"not-allowed":transtype==5 && loanid == ""?"not-allowed":"pointer" : "not-allowed"  }} onClick={(e) => { handleAdd(e)}}>
                                ADD
                            </button>
                            <Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px",justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={(e) => clearform(e)}>
                                Clear
                            </Button>
                            {(payload && payload.bulkNo) &&
                            <Button type="button" variant="contained" sx={{ display: "flex", width: "110px", padding: "12px",justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={(e) => exportReceipt(e)}>
                                Print ALL
                            </Button>
                            }
                            </Box>
                        </form>
                        {currentMember.length>0&&<Box sx={{display:"flex",gap:"20px",width:"70%",boxShadow:"rgba(100, 116, 139, 0.5) 0px 0px 34px 0px",padding:"30px",justifyContent:"space-around"}}> 
                            
                            <Box >
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "34px",borderBottom:"1px solid grey" }}>Transaction Summary </Typography>
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px",marginTop:"10px" }}>Share Balance: <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "black", fontWeight: "600" }}>{currentMember[0].sharebalance == null ? 0.00 : parseInt(currentMember[0].sharebalance).toFixed(2)}</Typography> </Typography>
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>subscription Balance: <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "black", fontWeight: "600" }}>{currentMember[0].subscriptionbalance == null ? 0.00 : parseInt(currentMember[0].subscriptionbalance).toFixed(2)}</Typography></Typography> 
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>loan Balance: <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "black", fontWeight: "600" }}>{currentMember[0].loanbalance == null ? 0.00 : parseInt(currentMember[0].loanbalance).toFixed(2)}</Typography></Typography> 
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>specific deposit Balance: <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "black", fontWeight: "600" }}>{currentMember[0].sdbalance == null ? 0.00 : parseInt(currentMember[0].sdbalance).toFixed(2)}</Typography></Typography> 
                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Borrowing Balance: <Typography sx={{ display: "inline-block", background: "var(--pallate-green-500, #288d2c)", padding: "4px 10px", borderRadius: "50px", color: "black", fontWeight: "600" }}>{currentMember[0].brbalance == null ? 0.00 : parseInt(currentMember[0].brbalance).toFixed(2)}</Typography></Typography> 
                            </Box>
                            <Box>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "34px",borderBottom:"1px solid grey" }}>Active Loans</Typography>

                            <DataTable   value={loanList} rows={5}   style={{ backgroundColor: "#F1F5F9",height:loanList.length>0?"220px":"auto",fontSize:"12px"}} scrollable scrollHeight="200px"  columnfinalMembersizeMode="expand" emptyMessage="No Active Loans Found">
                            {ColumnVisibleLoan.filter(col => col.visible).map((col,ind) => <Column key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                            </Box>
                        </Box>}
                        {errors.share!==""&&<Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"red" }}>Member Sharebalance cannot exceed 1200</Typography>}
                        {errors.member!==""&&<Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"red" }}>Please enter valid MemberId</Typography>}
                        <Box>
                            <Box sx={{ marginTop: "24px", }}>
                                <div className="card">
                                    <Box sx={{display:"flex",justifyContent:"flex-end"}}>
                                    {payload && payload.bulkNo && <Box sx={{textAlign:"end"}}>
                                        <Button type="button" onClick={(e) => exportContributionList(e)}>
                                            {/* {console.log("--------------",isCLLoading)} */}
                                            Contribution List <DownloadForOfflineIcon />
                                            </Button>
                                    </Box>}
                                    <Box sx={{textAlign:"end"}}>
                                        <Button type="button" onClick={exportPdf} data-pr-tooltip="PDF" >
                                            Download List
                                        <DownloadForOfflineIcon />
                                        </Button>
                                    </Box>
                                    <Box sx={{textAlign:"end"}}>
                                        <Button type="button" onClick={downlaomanagerLetter} data-pr-tooltip="PDF" >
                                            Download Manager Letter
                                        <DownloadForOfflineIcon />
                                        </Button>
                                    </Box>
                                    </Box>
                                    <DataTable value={finalMembers}  rows={10} footerColumnGroup={footGroup}  dataKey="sn" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px"  columnfinalMembersizeMode="expand" removableSort finalMembersizableColumns
                                        emptyMessage="No customers found." >
                                        
                                        <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1}></Column>
                                        {columnVisible.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={{ width: "500px" }} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} editor={(rowData)=> finalMembers.length>0?inputEditor(rowData):null}/>)}
                                        <Column header ='Print' body={PrintData} />
                                        <Column header="Remove" body={deleteMem} />
                                    </DataTable>
                                </div>
                            </Box>
                            <Box sx={{display:"flex",gap:"20px",marginTop:"20px"}}>
                           {Object.keys(response).length==0 && <button type="submit" variant="contained" disabled={finalMembers.length !==0 && Total==bulkAmount ?false:true} style={{ border: "none", color: "#FFF",fontSize:"16px", display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)", cursor: finalMembers.length !==0 && Total==bulkAmount ? "pointer" : "not-allowed" }} onClick={(event) => handleSave(event)}>
                                SAVE
                            </button>}
                            <button type="submit" variant="contained"  style={{ border: "none", color: "#FFF",fontSize:"16px", display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)", cursor: "pointer"  }} onClick={(e) => backFunction(e)}>
                                BACK
                            </button>
                        </Box></Box>
                    </Box>}
                </Box>
            }
            <Dialog visible={modalOpen} style={{ width: '1200px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false}>
            <Stack direction="row" className="fulldiv" sx={{justifyContent:"space-around",alignItems:"center",width:"100%"}}>
                <Box style={{ boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 30px 30px", borderRadius: "0px", paddingRight: "24px" }}>
                <Box sx={{ display: "flex", maxWidth: "669px", alignItems: "center", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Estate Name</Typography>
                <Dropdown required value={searchEstate} onChange={(e) => setSearchEstate(e.target.value)} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center", }} options={estateList}
                                placeholder="All" filter /></Box>
                                <Box sx={{ display: "flex", maxWidth: "669px",  alignItems: "center", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Bulk No</Typography>
                                <InputText
                                    value={bulkNo}
                                    type="number"
                                //   /  disabled={Total>=payload.bulkAmount?true:false}
                                    onChange={(e) => {
                                        setBulkNo(e.target.value)
                                    // e.target.value>1200?setTransType(2):setTransType("")
                                    }}
                                    style={{ marginTop:"10px",padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                /></Box>
                                <Box sx={{ display: "flex",  alignItems: "center", gap: "8px" }}>
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Receipt No</Typography>
                                <InputText
                                    value={receiptNo}
                                    type="number"
                                //   /  disabled={Total>=payload.bulkAmount?true:false}
                                    onChange={(e) => {
                                        setReceiptNo(e.target.value)
                                    // e.target.value>1200?setTransType(2):setTransType("")
                                    }}
                                    style={{ marginTop:"10px",padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                /></Box>
                                <Button type="reset" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" ,marginTop:"30px"}} onClick={searchBulk}>
                            Search
                        </Button>
                        <Box>
                            <Box sx={{textAlign:"end"}}>
                                <Button type="button" onClick={exportBulkPdf} data-pr-tooltip="PDF" >
                                    Download List
                                <DownloadForOfflineIcon />
                                </Button>
                            </Box>
                            <DataTable value={bulkList} rows={10}   dataKey="sn" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="450px"  columnfinalMembersizeMode="expand" removableSort finalMembersizableColumns
                                    emptyMessage="No customers found." >
                                    <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                                    {bulkColumnVisible.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </Box>
                        
                        <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-24px",flexDirection:"column" }}>
                <Box sx={{alignSelf:"start",display:"flex",justifyContent:"space-between",width:'100%'}}>
                    

                    <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "end", cursor: "pointer", textTransform: "none" }} onClick={()=>setModalOpen(false)}>

                        {/* <ClearIcon /> */}
                        <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}></Typography>
                        Close
                    </Box>
                    </Box>
                    

                </Box>
                
                </Box>
                
                
                </Stack>
                
            </Dialog>
        </Box>
    )
}