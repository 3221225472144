import { Box, Button, TextField, Typography } from "@mui/material";
import Header from "../components/header";
import { BorderColor, HelpOutlineOutlined } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Sidebar from "../components/sidebar";
import { InputText } from "primereact/inputtext";
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const formRef = useRef(null);
    const isMobile = useMediaQuery({ maxWidth: 600 })
    
    const userdetails =JSON.parse(window.sessionStorage.getItem('details'))
    async function handlesubmit(event) {
        event.preventDefault()
        const { data } = await axios.post(`${process.env.REACT_APP_CONTACTUS_URL}default/oasis_contact`, {
            "name": userdetails.membername,
            "email": userdetails.email,
            "memberId": userdetails.memeberid,
            "subject": subject,
            "message": message
        })

        formRef.current.reset()
        setSubject('')
        setMessage('')
    }
    return (
        <Box >
            <Header title="Koperasi" color={true}/>
            <Box sx={{ display: { xs: "block", md: "flex" } }} >
                {!isMobile&&<Sidebar color={true} />}
                <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
                    <Box sx={{ padding:"27px", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }} >
                            <HelpOutlineOutlined sx={{ width: "40px", height: "40px", color: "#288d2c" }} />
                            <Box className="fn-h2 fw-500" sx={{ pl: 1 }}>Help</Box>
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography sx={{ color: "var(--Pallate-green-500, #288d2c)", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "500", lineHeight: "32px" }}> Need help? We're here for you.</Typography>
                            <Typography sx={{ maxWidth: "672px", width: "100%", color: "var(--Light-Text-Default-Text, #101010)", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "400", lineHeight: "28px" }}> We're ready to help you with any questions or issues you're having. Just submit your query below and we'll get back to you as soon as possible.</Typography>
                        </Box>
                        <form ref={formRef} style={{ maxWidth: "672px", width: "100%", marginTop: "20px", gap: "40px", display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", maxWidth: "669px", width: "100%", flexDirection: "column", alignItems: "flexstart", gap: "8px" }}>
                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Subject Line</Typography>
                                <InputText
                                    placeholder="Max 100 Characters is allowed"
                                    sx={{ padding: "12px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}

                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </Box> 
                            <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                            <Box sx={{ display: "flex", maxWidth: "669px", height: "242px", width: "100%", flexDirection: "column", alignItems: "flexstart", gap: "8px",'&.Mui-focused': {borderColor:"green"}}}>
                                <Typography sx={{ alignSelf: "stretch", color: "var(--Light-Text-Default-Text, #101010)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Message for us</Typography>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", flex: "1 0 0", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}>
                                <textarea
                                    maxlength="500"
                                    placeholder="Max 500 Characters is allowed"
                                    style={{ outline:"none",border:"none",display: "flex",alignItems: "flex-start", flex: "1 0 0", alignSelf: "stretch", borderRadius: "4px", background: " var(--Light-Forms-Background, #FFF)",resize:"none",color:"var(--Light-Text-Secondary-Text, #64748B)"}}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                </Box>
                            </Box>
                                {message.length==500 && <Typography sx={{ alignSelf: "stretch",color: "#EF4444" , fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px" }}>Oops! Looks like you've exceeded the character limit. Please shorten your entry.<span style={{ color: "var(--Light-Text-Default-Text, #101010)" }}>Max 500 Characters is allowed</span></Typography>}
                            </Box>
                            <Button type="submit" variant="contained" sx={{ display: "flex", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} onClick={(event) => handlesubmit(event)}>
                                Submit
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Contact;