import { useEffect } from 'react';

const useDisableScroll = () => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.type === 'number') {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (event.target.type === 'number' && (event.keyCode === 38 || event.keyCode === 40)) {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useDisableScroll;
