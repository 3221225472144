import React, { useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';
import { Box, Button, Select, Typography, TextField, CircularProgress, Table, Alert, TableHead, TableRow, TableCell, TableBody, DialogTitle, DialogContent, DialogActions, IconButton, Accordion, AccordionSummary, AccordionDetails, MenuItem, Checkbox, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Stack, Menu } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LinkHeader } from '../../components/linkHeader';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import { BakeryDining, PaymentsOutlined, SettingsRemote } from '@mui/icons-material';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/primereact.min.css'; // Import the PrimeReact styles
import 'primeicons/primeicons.css'
import { CutOff } from '../../components/Cut-Off';
import '../../styles/form.css'
import CustomAxios from '../../utils/CustomAxios';
import { useMediaQuery } from 'react-responsive';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dialog } from "primereact/dialog";
import DoneIcon from '@mui/icons-material/Done';

const TransactionModal = ({
    paymentOpen,
    setPaymentOpen,
    open,
    transaction,
    onSave,
    onClose,
    banks,
    transInTable,
    loan,
    Guarantor,
    SDlist,
    guarantorForList,
    getMemberDetails, // Assuming this is passed as a prop from the parent component
    transactions,
    onRemove,
    openDialogToAdd,
    handleFetchAndPrintReceipt,
    setTransactions,
    submitAllTransactions,
    formatDateTransDate,
    bulkNum,
    bulkCSV,
    banktempCSV,
    handlePrintAll,
    setBulkNo,
    columnVisibleTransactions
}) => {
    const [editedTransaction, setEditedTransaction] = useState(transaction);
    // const [share, setShare] = useState(false);
    // const [subscription, setSubscription] = useState(false);
    // const [specificDeposit, setSpecificDeposit] = useState(false);
    // const [bonus, setBonus] = useState(false)
    const [sharetrans, setShareTrans] = useState(0);
    const [subscriptionTrans, setSubscriptionTrans] = useState(0);
    const [specificDepositTrans, setSpecificDepositTrans] = useState(0);
    const [bonusTrans, setBonusTrans] = useState(0)
    const [allTrans, setAllTrans] = useState([]);
    const [errors, setErrors] = useState({ balance: '', fields: '', deduct: '', trans: '', transamount: '', member: '', bankerror: '' });
    const [save, setSave] = useState(false);
    const [memberDetails, setMemberDetails] = useState([]);
    // const [radio, setRadio] = useState("Part");
    const [shareWithdrawType, setShareWithdrawType] = useState("Part");
    const [subsWithdrawType, setSubsWithdrawType] = useState("Part");
    const [bonusWithdrawType, setBonusWithdrawType] = useState("Part");
    const [sdWithdrawType, setSdWithdrawType] = useState("Part");
    const [deductFrom, setDeductFrom] = useState("");
    const [shareNarration, setShareNaraation] = useState("PwShare");
    const [subsNarration, setSubsNarration] = useState("PwSubs");
    const [sdNarration, setSdNaraation] = useState("PwSd");
    const [boNarration, setboNarration] = useState("PwBonus");

    const [toggle, setToggle] = useState("SD");
    const [bankName, setBankName] = useState("")
    const [transdate, setTransdate] = useState(new Date());
    const [cutoffDate, setCutoffDate] = useState([]);
    const [bankerror, setBankError] = useState('')

    const [anchorEl, setAnchorEl] = useState(null);

    //const [banksarray,setBanksarray] = useState([]);
    const inputRef = useRef(null);
    const formReff = useRef(null);

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
        };

        // Create Date object from the provided string
        const date = new Date(dateString);

        // Format the date string
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        console.log(formattedDate)

        return new Date(formattedDate);
    };

    let bank_array = []
    useEffect(() => {
        banks.map((mem) => bank_array.push(mem.bankname))
    }, [banks])

    useEffect(() => {
        //console.log(transaction)
        const mem = allTrans.filter((mem) => transaction.memberId == mem.memberid)
        setErrors(prev => ({
            ...prev,
            bankerror: ''
        }))
        setEditedTransaction(transaction); // Reset the editedTransaction when the modal opens with new transaction datae
        if (transaction.memberId !== "") {
            editedTransaction.memberId = transaction.memberId
            fetchMemberDetails("edit")
            const filltered = transInTable.filter((mem) => mem.id == transaction.id)
            filltered.map((mem) => {
                editedTransaction.bank = mem.bank
                if (mem.transtype == 21 || mem.transtypeid == 21) {
                    console.log(mem.transdate)
                    console.log(mem.bank)
                    if (mem.remarks) {
                        //editedTransaction["newbank"] = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    setTransdate(mem.transdate)
                    // setBankName(mem.bank)
                    setShareTrans(mem.transamount)
                    // setShare(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.prFee) > 0) {
                        editedTransaction.prFee = mem.prFee
                        setDeductFrom("ShareDeduct")
                    }
                    if (mem.radio == "Part") {
                        setShareWithdrawType("Part")
                        setShareNaraation("PwShare")
                    } else if (mem.radio == "Full") {
                        setShareWithdrawType("Full")
                        setShareNaraation("FwShare")
                    }
                } else if (mem.transtype == 22 || mem.transtypeid == 22) {
                    console.log(mem.bank)
                    // editedTransaction.bank = mem.bank+ "                                               "
                    console.log(mem.transdate)
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        console.log("remarks")
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    setSubscriptionTrans(mem.transamount)
                    // setSubscription(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.prFee) > 0) {
                        editedTransaction.prFee = mem.prFee
                        setDeductFrom("SubscriptionDeduct")
                    }
                    if (mem.radio == "Part") {
                        setSubsWithdrawType("Part")
                        setSubsNarration("PwSubs")
                    } else if (mem.radio == "Full") {
                        setSubsWithdrawType("Full")
                        setSubsNarration("FwSubs")
                    }
                }
                else if (mem.transtype == 24 || mem.transtypeid == 24) {
                    console.log(mem.bank)
                    // editedTransaction.bank = mem.bank
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        //editedTransaction.bankname = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    setSpecificDepositTrans(mem.transamount)
                    // setSpecificDeposit(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.prFee) > 0) {
                        editedTransaction.prFee = mem.prFee > 0 && mem.prFee
                        setDeductFrom("SpecificDepositDeduct")
                    }
                    if (mem.radio == "Part") {
                        setSdWithdrawType("Part")
                        setSdNaraation("PwSd")
                    } else if (mem.radio == "Full") {
                        setSdWithdrawType("Full")
                        setSdNaraation("FwSd")
                    }
                }
                else if (mem.transtype == 34 || mem.transtypeid == 34) {
                    console.log(mem.bank)
                    // editedTransaction.bank = mem.bank+ "                                               "
                    console.log(mem.transdate)
                    setTransdate(mem.transdate)
                    if (mem.remarks) {
                        console.log("remarks")
                        //editedTransaction.bank = mem.bank
                        //editedTransaction["newbank"]  = mem.bank
                        editedTransaction.transdate = formatDate(mem.transdate)
                    }
                    console.log(mem.bank)
                    // setBankName(mem.bank)
                    setBonusTrans(mem.transamount)
                    // setBonus(true)
                    editedTransaction.ChequeNo = mem.ChequeNo
                    if (parseInt(mem.insurance) > 0 || parseInt(mem.prFee) > 0) {
                        editedTransaction.prFee = mem.prFee
                        setDeductFrom("BonusDeduct")
                    }
                    if (mem.radio == "Part") {
                        setBonusWithdrawType("Part")
                        setboNarration("PwBonus")
                    } else if (mem.radio == "Full") {
                        setBonusWithdrawType("Full")
                        setboNarration("FwBonus")
                    }
                }
            })
        }
        else {
            editedTransaction.prFee = 50
            // editedTransaction.bank = mem.bank + "                                               "
        }
    }, [transaction, open]);
    const handleChange = (e, type) => {
        const name = e.target.name;
        let value = e.target.value;
        //console.log(name, value, type)
        if (name == "bank") {
            setErrors(prev => ({
                ...prev,
                bankerror: ''
            }))
            value = value.bankname
        }
        setEditedTransaction(prev => { console.log("???????????????????handle change"); return ({ ...prev, [name]: value }) });
    };
    let transid_Array = []
    useEffect(() => {
        if (allTrans.length > 0 && transInTable.length > 0 && transInTable[0].transdetailid && allTrans[0].transdetailid && transInTable[0].transdetailsid && allTrans[0].transdetailsid) {
            const trans_filter = transInTable.filter((mem) => mem.memberid == allTrans[0].memberid)
            console.log(allTrans, trans_filter)
            trans_filter.map((mem, index) => {
                allTrans[index].transdetailid = mem.transdetailid
                allTrans[index].transdetailsid = mem.transdetailsid
            })
        }
    }, [allTrans])
    //console.log(allTrans)
    const handleSave = (e) => {
        //console.log(cutoffDate)
        console.log("$$$$$$$$$$$$$$$$$ Inside Handle Save $$$$$$$$$$$$$$$$$");

        e.preventDefault();
        if (editedTransaction.bank == '') {
            console.log("we are in bankerror")
            setErrors(prev => ({
                ...prev,
                bankerror: 'please enter bank name'
            }))
        }
        else {
            setErrors(prev => ({
                ...prev,
                bankerror: ''
            }))
            console.log("we are not in bank")
            handleCheckValid()
            console.log("############ Edited Transaction ##################",editedTransaction)
            const randomInteger = Math.floor(Math.random() * (10000 - 50) + 50)
            if (memberDetails.length>0&& memberDetails[0].sharebalance>0 || memberDetails[0].subscriptionbalance>0 || memberDetails[0].bonus > 0 || memberDetails[0].sdbalance>0) {
                if ((memberDetails[0].sharebalance>0 && (sharetrans>0 || deductFrom == "ShareDeduct")) && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transdate = transdate
                    val[0].transtype = 21
                    val[0].transtypeid = 21
                    val[0].narration = shareNarration
                    val[0].id = randomInteger
                    val[0].transamount = sharetrans
                    val[0].radio = shareWithdrawType
                    val[0].transtotalamount = sharetrans > 0 && deductFrom == "ShareDeduct" ? parseInt(sharetrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : sharetrans > 0 ? sharetrans : deductFrom == "ShareDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : 0
                    deductFrom == "ShareDeduct" ? val[0].prFee = val[0].prFee : val[0].prFee = 0
                    deductFrom == "ShareDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    deductFrom == "ShareDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                if ((memberDetails[0].subscriptionbalance>0 && (subscriptionTrans>0 || deductFrom == "SubscriptionDeduct")) && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 22
                    val[0].transtypeid = 22
                    val[0].narration = subsNarration
                    val[0].id = randomInteger
                    val[0].transdate = transdate
                    val[0].transamount = subscriptionTrans
                    val[0].radio = subsWithdrawType
                    val[0].transtotalamount = subscriptionTrans > 0 && deductFrom == "SubscriptionDeduct" ? parseInt(subscriptionTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : subscriptionTrans > 0 ? subscriptionTrans : deductFrom == "SubscriptionDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : 0
                    //console.log(val)
                    deductFrom == "SubscriptionDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    deductFrom == "SubscriptionDeduct" ? val[0].prFee = val[0].prFee : val[0].prFee = 0
                    deductFrom == "SubscriptionDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                if ((memberDetails[0].sdbalance>0 && (specificDepositTrans>0 || deductFrom == "SpecificDepositDeduct")) && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 24
                    val[0].transtypeid = 24
                    val[0].narration = sdNarration
                    val[0].id = randomInteger
                    val[0].transamount = specificDepositTrans
                    val[0].transdate = transdate
                    val[0].radio = sdWithdrawType
                    val[0].transtotalamount = specificDepositTrans > 0 && deductFrom == "SpecificDepositDeduct" ? parseInt(specificDepositTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : specificDepositTrans > 0 ? specificDepositTrans : deductFrom == "SpecificDepositDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : 0
                    //console.log(val)
                    deductFrom == "SpecificDepositDeduct" ? val[0].prFee = val[0].prFee : val[0].prFee = 0
                    deductFrom == "SpecificDepositDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    deductFrom == "SpecificDepositDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                if ((memberDetails[0].bonus>0 && (bonusTrans>0 || deductFrom == "BonusDeduct")) && errors.balance == '') {
                    const val = [{ ...editedTransaction }]
                    console.log(val[0])
                    val[0].transtype = 34
                    val[0].transtypeid = 34
                    val[0].narration = boNarration
                    val[0].id = randomInteger
                    val[0].transamount = bonusTrans
                    val[0].transdate = transdate
                    val[0].radio = bonusWithdrawType
                    val[0].transtotalamount = bonusTrans > 0 && deductFrom == "BonusDeduct" ? parseInt(bonusTrans) + (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : bonusTrans > 0 ? bonusTrans : deductFrom == "BonusDeduct" ? (editedTransaction.insurance != '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee) : 0
                    //console.log(val)
                    deductFrom == "BonusDeduct" ? val[0].prFee = val[0].prFee : val[0].prFee = 0
                    deductFrom == "BonusDeduct" ? val[0].insurance = val[0].insurance : val[0].insurance = 0
                    deductFrom == "BonusDeduct" ? val[0].processingfee = val[0].processingfee : val[0].processingfee = 0
                    setAllTrans(prev => [...prev, val[0]])
                }
                setPaymentOpen(true)

            }
        }
    }

    const handleCheckValid = () => {
        console.log(bankerror)
        errors.balance == '' && errors.fields == '' && errors.deduct == "" && errors.trans == "" && errors.bankerror == "" ? setSave(true) : setSave(false)
    }
    useEffect(() => {
        if (save == true && deductFrom != "") {
            onSave(allTrans)
        }
        setSave(false)
    }, [save])
    useEffect(() => {
        if (memberDetails.length > 0 && (memberDetails[0].sharebalance>0 && ((memberDetails[0].sharebalance - sharetrans) < 0) || memberDetails[0].subscriptionbalance>0 && ((memberDetails[0].subscriptionbalance - subscriptionTrans) < 0) || memberDetails[0].sdbalance>0 && ((memberDetails[0].sdbalance - specificDepositTrans) < 0) || memberDetails[0].bonus>0 && ((memberDetails[0].bonus - bonusTrans) < 0))) {
            setErrors(prev => ({
                ...prev,
                balance: 'Insufficient Balance'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                balance: ''
            }));
        }
    }, [sharetrans, subscriptionTrans, specificDepositTrans, bonusTrans])

    useEffect(() => {
        console.log("????????????????????????????inside edited useeffect", editedTransaction)
        if (editedTransaction.bank) {
            let bankname = editedTransaction.bank.split(" ")
            console.log("????????????????????????splitted bankname", bankname)
        }

    }, [editedTransaction])
    const fetchMemberDetails = async (edit) => {
        const memberId = editedTransaction.memberId;
        //console.log("memberId: " + memberId);
        if (memberId) {
            try {
                const details = await getMemberDetails(memberId);
                //console.log("details: ", typeof(details));
                if (typeof (details) == "object") {
                    let sharetotal = 0
                    let substotal = 0
                    let sdtotal = 0
                    let bototal = 0
                    // const memberfilter = transInTable.filter((mem)=>mem.memberId == details.memberid)
                    // xikjven2lqfyscupuhu446vmlyai6idobfjqot7vrf5e4fr2bcqamemberfilter.red((mem)=>parseInt(sharetotal)= parseInt(sharetotal)+parseInt(mem.sharebalance))
                    transInTable.reduce((acc, curr) => {
                        if (curr.memberId == details.memberid) {
                            //console.log(transaction,edit)

                            if (curr.transtype == 21) {
                                sharetotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 22) {
                                substotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 24) {
                                sdtotal += parseInt(curr.transtotalamount)
                            }
                            if (curr.transtype == 34) {
                                bototal += parseInt(curr.transtotalamount)
                            }
                            // if(edit=='edit'){
                            //     console.log(transaction)
                            //     if(curr.transtype==21){
                            //         details.sharebalance = parseInt(details.sharebalance)-parseInt(curr.transtotalamount)
                            //     }
                            //     if(curr.transtype==22){
                            //         details.subscriptionbalance = parseInt(details.subscriptionbalance)-parseInt(curr.transtotalamount)
                            //     }
                            //     if(curr.transtype==24){
                            //         details.sdbalance = parseInt(details.sdbalance)-parseInt(curr.transtotalamount)
                            //     }}
                        }
                        return acc;
                    }, 0);
                    // details.sharebalance = parseInt(details.sharebalance) - parseInt(sharetotal)
                    // details.subscriptionbalance = parseInt(details.subscriptionbalance) - parseInt(substotal)
                    // details.sdbalance = parseInt(details.sdbalance) - parseInt(sdtotal)
                    // details.bonus = parseInt(details.bonus) - parseInt(bototal)
                    //console.log(sharetrans,details,sharetotal,substotal,sdtotal)

                    setMemberDetails([details])
                    // Assuming details contain memberName, oldIcNo, newIcNo, etc.
                    setEditedTransaction(prev => {
                        console.log("hw?????????????????????????????????????????", editedTransaction.bank)
                        return ({
                            ...prev,
                            memberDetails: {
                                ...prev.memberDetails,
                                memberName: details.membername, // Update these keys based on your actual data structure
                                oldIcNo: details.oldicno,
                                newIcNo: details.newicno,
                                age: details.age,
                                state: details.state,
                                estate: details.estate,
                                accountno: details.m_acctno,
                                email: details.emailid,
                                mobileno: details.mobileno,
                                membank: details.m_bankname,
                            }
                        })
                    });
                    // Assuming details contain memberName, oldIcNo, newIcNo, etc.
                    setEditedTransaction(prev => {
                        console.log("hw?????????????????????????????????????????", editedTransaction.bank)
                        return ({
                            ...prev,
                            memberDetails: {
                                ...prev.memberDetails,
                                memberName: details.membername, // Update these keys based on your actual data structure
                                oldIcNo: details.oldicno,
                                newIcNo: details.newicno,
                                age: details.age,
                                state: details.state,
                                estate: details.estate,
                                accountno: details.m_acctno,
                                email: details.emailid,
                                mobileno: details.mobileno
                            }
                        })
                    });

                    setErrors(prev => ({
                        ...prev,
                        member: ""
                    }));
                } else {
                    //console.log("hiuhiuh")
                    setErrors(prev => ({
                        ...prev,
                        member: "Please Enter Valid MemberId"
                    }));
                    errors.member = "Please Enter Valid MemberId"
                }

            } catch (error) {
                console.error("Failed to fetch member details:", error);
            }
        } else {
            console.error("Member ID is undefined");
        }
    };
    // const handleCheck = (event, type) => {
    //     console.log(type)
    //     type == "share" ? setShare(!share) : type == "subscription" ? setSubscription(event.target.checked) : type == "specificDeposit" ? setSpecificDeposit(event.target.checked) : setBonus(!bonus)
    // }

    useEffect(() => {
        if (memberDetails.length > 0) {       
            if(deductFrom == "ShareDeduct"){
                handleShareWithdrawType("None", "prFeeIns")
             } else if(deductFrom == "SubscriptionDeduct") {
                handleSubsWithdrawType("None", "prFeeIns")
             } else if(deductFrom == "BonusDeduct") {
                handleBonusWithdrawType("None", "prFeeIns")
             } else if(deductFrom == "SpecificDepositDeduct") {
                handleSdWithdrawType("None", "prFeeIns")
             }
        }
    }, [editedTransaction.prFee, editedTransaction.insurance])

    const handleSearch = (e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
            fetchMemberDetails(e);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const date = (rowData) => {
        const date = rowData.loanstartdate ? new Date(rowData.loanstartdate) : rowData.loanexpirydate ? new Date(rowData.loanexpirydate) : rowData.depositdate ? new Date(rowData.depositdate) : new Date(rowData.expirydate)
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    }
    const [ColumnVisible, setColumneVisible] = useState([

        { field: "loanid", header: "Loan#", visible: true },
        { field: "loanstartdate", header: "LoanDate", visible: true, body: date },
        { field: "principalamount", header: "Principal", visible: true },
        { field: "noofinstalment", header: "#inst", visible: true },
        { field: "loanexpirydate", header: "Expiry", visible: true, body: date },
        { field: "loanbalanceamount", header: "LoanBalance", visible: true },


    ])
    const [GuarantorColumnVisible, setGuarantorColumnVisible] = useState([
        { field: "guarantorid", header: "Guarantor", visible: true, style: { width: '80px' } },
        { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
        { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
        { field: "loanno", header: "Loan#", visible: true },
    ])
    const [sdColumnVisible, setSdColumnVisible] = useState([
        { field: "depositid", header: "depositid", visible: true },
        { field: "depositdate", header: "depositdate", visible: true, body: date },
        { field: "period", header: "period", visible: true },
        { field: "expirydate", header: "expirydate", visible: true, body: date },
        { field: "sdbalance", header: "sdbalance", visible: true },
    ])
    const [mgColumnVisible, setmgColumnVisible] = useState([
        { field: "memberid", header: "memberID", visible: true, style: { width: '80px' } },
        { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
        { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
        { field: "loanno", header: "Loan#", visible: true },
    ])
    const checkval = (e) => {
        console.log(e.target.value)

    }
    // console.log(editedTransaction.bank)
    console.log(sharetrans)


    const paymentTypeArr = [
        { name: "New Bulk", code: "NBulk" },
        { name: "Existing Bulk", code: "EBulk" }
    ]

    const withdrawalTypeArr = [
        { name: "Full Withdrawn", code: "Full" },
        { name: "Partial Withdrawn", code: "Part" }
    ]

    const deductionFromArr = [
        { name: "Share", code: "ShareDeduct" },
        { name: "Subscription", code: "SubscriptionDeduct" },
        { name: "Bonus", code: "BonusDeduct" },
        { name: "Specific Deposit", code: "SpecificDepositDeduct" }
    ]

    const handleCancelANP = (type) => {

        const setters1 = [setShareTrans, setSubscriptionTrans, setSpecificDepositTrans, setBonusTrans]
        const setters2 = (type === "cancel")? [setAllTrans, setMemberDetails, setTransactions] : [setMemberDetails, setAllTrans]
        const setters3 = [setShareWithdrawType, setSubsWithdrawType, setBonusWithdrawType, setSdWithdrawType]

        setters1.forEach(setState => setState(0))
        setters2.forEach(setState => setState([]))
        setters3.forEach(setState => setState("Part"))
    
        setErrors({ balance: '', fields: '', deduct: '', trans: '', transamount: '', member: '', bankerror: '' });
        setDeductFrom("");
        setShareNaraation("PwShare");
        setSubsNarration("PwSubs");
        setSdNaraation("PwSd");
        setboNarration("PwBonus");
        setToggle("SD");
        setTransdate(new Date());
        setPaymentOpen(false)
        setEditedTransaction(createNewTransaction());
        setBulkNo(0)
    }

    const handleShareWithdrawType = (e, inpEle) => {
        if (memberDetails.length > 0) {
            const deductionFrom = (inpEle ==="deductFrom")? e?.target?.value:e!=="Once"?deductFrom:"Once"
            if((inpEle==="withdrawType"?e.target.value:shareWithdrawType) === "Full") {
                if(deductionFrom == "ShareDeduct") {
                    const sharededucted = parseInt((memberDetails[0].sharebalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)))
                    setShareTrans(sharededucted)
                } else 
                    setShareTrans(memberDetails[0].sharebalance)
                setShareNaraation("FwShare")
            } else {
                const shareTransAmount = (inpEle === "withdrawAmount")? e.target.value : inpEle!=="withdrawType"?sharetrans:0
                const isFullWithdrawal = (parseInt(shareTransAmount) == parseInt(memberDetails[0].sharebalance) || (parseInt(shareTransAmount) + parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0].sharebalance))
                if(parseInt(shareTransAmount)>0 && deductionFrom == "ShareDeduct" && isFullWithdrawal) {
                    setShareTrans(parseInt((memberDetails[0].sharebalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance))))
                    setShareNaraation("FwShare")
                 } else {
                    setShareTrans(shareTransAmount)
                    setShareNaraation("PwShare")
                 }
                 isFullWithdrawal?setShareNaraation("FwShare"):setShareNaraation("PwShare")
            }
        }
        if(inpEle==="withdrawType")
            setShareWithdrawType(e.target.value)
        else if(inpEle ==="deductFrom" && (deductFrom === "" || e !== "Once")) {
            if(deductFrom === "") {
                setDeductFrom(e.target.value)
            }
            else if(e !== "Once") {
                if(deductFrom == "SubscriptionDeduct") {
                    handleSubsWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "BonusDeduct") {
                    handleBonusWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "SpecificDepositDeduct") {
                    handleSdWithdrawType("Once", "deductFrom")
                 }
                 setDeductFrom(e.target.value)
            }
        }
    }

    const handleSubsWithdrawType = (e, inpEle)  => {
        if (memberDetails.length > 0) {
            const deductionFrom = (inpEle ==="deductFrom")? e?.target?.value:e!=="Once"?deductFrom:"Once"
            if((inpEle==="withdrawType"?e.target.value:subsWithdrawType) === "Full") {
                if(deductionFrom == "SubscriptionDeduct") {
                    const subscriptionDeducted = parseInt((memberDetails[0].subscriptionbalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)))
                    setSubscriptionTrans(subscriptionDeducted)
                } else 
                    setSubscriptionTrans(memberDetails[0].subscriptionbalance)
                setSubsNarration("FwSubs")
            } else {
                const subscriptionTransAmount = (inpEle === "withdrawAmount")? e.target.value : inpEle!=="withdrawType"?subscriptionTrans:0
                const isFullWithdrawal = (parseInt(subscriptionTransAmount) == parseInt(memberDetails[0].subscriptionbalance) || (parseInt(subscriptionTransAmount) + parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0].subscriptionbalance))
                if(parseInt(subscriptionTransAmount)>0 && deductionFrom == "SubscriptionDeduct" && isFullWithdrawal) {
                    setSubscriptionTrans(parseInt((memberDetails[0].subscriptionbalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance))))
                    setSubsNarration("FwSubs")
                } else {
                    setSubscriptionTrans(subscriptionTransAmount)
                    setSubsNarration("PwSubs")
                }
                isFullWithdrawal?setSubsNarration("FwSubs"):setSubsNarration("PwSubs")
            }
       }
       if(inpEle==="withdrawType") 
            setSubsWithdrawType(e.target.value)
        else if(inpEle ==="deductFrom" && (deductFrom === "" || e !== "Once")) {
            if(deductFrom === "")
                setDeductFrom(e.target.value)
            else if(e !== "Once") {
                if(deductFrom == "ShareDeduct"){
                    handleShareWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "BonusDeduct") {
                    handleBonusWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "SpecificDepositDeduct") {
                    handleSdWithdrawType("Once", "deductFrom")
                 }
                 setDeductFrom(e.target.value)
            }
        }
    }

    const handleBonusWithdrawType = (e, inpEle) => {
        if (memberDetails.length > 0) {

            const deductionFrom = (inpEle ==="deductFrom")? e?.target?.value:e!=="Once"?deductFrom:"Once"
            if((inpEle==="withdrawType"?e.target.value:bonusWithdrawType) === "Full") {                
                if(deductionFrom == "BonusDeduct") {
                    const bonusDeducted = parseInt((memberDetails[0].bonus) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)))
                    setBonusTrans(bonusDeducted) 
                } else 
                    setBonusTrans(memberDetails[0].bonus)
                setboNarration("FwBonus")
            } else {                
                const bonusTransAmount = (inpEle === "withdrawAmount")? e.target.value : inpEle!=="withdrawType"?bonusTrans:0
                const isFullWithdrawal = (parseInt(bonusTransAmount) == parseInt(memberDetails[0].bonus) || (parseInt(bonusTransAmount) + parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0].bonus))
                if(parseInt(bonusTransAmount)>0 && deductionFrom == "BonusDeduct" && isFullWithdrawal) { 
                    setBonusTrans(parseInt((memberDetails[0].bonus) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance))))
                    setboNarration("FwBonus")
                } else {
                    setBonusTrans(bonusTransAmount)
                    setboNarration("PwBonus")
                }
                isFullWithdrawal?setboNarration("FwBonus"):setboNarration("PwBonus")
            }
        }
        
        if(inpEle==="withdrawType") 
            setBonusWithdrawType(e.target.value)
        else if(inpEle ==="deductFrom" && (deductFrom === "" || e !== "Once")) {
            if(deductFrom === "")
                setDeductFrom(e.target.value)
            else if(e !== "Once") {
                if(deductFrom == "ShareDeduct"){
                    handleShareWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "SubscriptionDeduct") {
                    handleSubsWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "SpecificDepositDeduct") {
                    handleSdWithdrawType("Once", "deductFrom")
                 }
                 setDeductFrom(e.target.value)
            }
        }
    }

    const handleSdWithdrawType = (e, inpEle) => {
        if (memberDetails.length > 0) {

            const deductionFrom = (inpEle ==="deductFrom")? e?.target?.value:e!=="Once"?deductFrom:"Once"
            if((inpEle==="withdrawType"?e.target.value:sdWithdrawType) === "Full") {
                if(deductionFrom == "SpecificDepositDeduct") {
                    const specificDepositDeducted = parseInt((memberDetails[0].sdbalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)))
                    setSpecificDepositTrans(specificDepositDeducted) 
                } else 
                    setSpecificDepositTrans(memberDetails[0].sdbalance)
                setSdNaraation("FwSd")
            } else {
                const specificDepositTransAmount = (inpEle === "withdrawAmount")? e.target.value : inpEle!=="withdrawType"?specificDepositTrans:0
                const isFullWithdrawal = (parseInt(specificDepositTransAmount) == parseInt(memberDetails[0].sdbalance) || (parseInt(specificDepositTransAmount) + parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance)) >= parseInt(memberDetails[0].sdbalance))
                if(parseInt(specificDepositTransAmount)>0 && deductionFrom == "SpecificDepositDeduct" && isFullWithdrawal){
                     setSpecificDepositTrans(parseInt((memberDetails[0].sdbalance) - (parseInt(editedTransaction.prFee) + parseInt(editedTransaction.insurance))))
                     setSdNaraation("FwSd")
                 } else {
                    setSpecificDepositTrans(specificDepositTransAmount)
                    setSdNaraation("PwSd")
                }
                isFullWithdrawal?setSdNaraation("FwSd"):setSdNaraation("PwSd")
            }
        }
        
        if(inpEle==="withdrawType")
            setSdWithdrawType(e.target.value)
        else if(inpEle ==="deductFrom" && (deductFrom === "" || e !== "Once")) {
            if(deductFrom === "")
                setDeductFrom(e.target.value)
            else if(e !== "Once") {
                if(deductFrom == "ShareDeduct"){
                    handleShareWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "SubscriptionDeduct") {
                    handleSubsWithdrawType("Once", "deductFrom")
                 } else if(deductFrom == "BonusDeduct") {
                    handleBonusWithdrawType("Once", "deductFrom")
                 }
                 setDeductFrom(e.target.value)
            }
        }
    }

    const handleDeductRadio = (e) => {
        if(e.target.value == "ShareDeduct"){
            handleShareWithdrawType(e, "deductFrom")
         } else if(e.target.value == "SubscriptionDeduct") {
            handleSubsWithdrawType(e, "deductFrom")
         } else if(e.target.value == "BonusDeduct") {
            handleBonusWithdrawType(e, "deductFrom")
         } else if(e.target.value == "SpecificDepositDeduct") {
            handleSdWithdrawType(e, "deductFrom")
         }
    }

    const editData = (rowData,rowIndex) => {
        return(
            <IconButton onClick={() => openDialogToAdd(rowIndex.rowIndex)}>
              <EditIcon />
            </IconButton>
        )   
    }

    const printData = (rowData) => {
        return (rowData.transdetailsid ?
                  <PrintIcon style={{ cursor: "pointer" }} onClick={() => handleFetchAndPrintReceipt(rowData.transdetailsid, "single")} />
                : <div>-</div>)
        
    }

    const deleteTrans = (rowData,rowIndex) => {
        return(<IconButton onClick={() => onRemove(rowIndex.rowIndex)}>
                  <RemoveIcon />
                </IconButton>)
    }

    const handleSubmitAllTransactions = (e) => {
        submitAllTransactions(e)
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <form ref={formReff} onSubmit={(e) => handleSave(e)} onKeyDown={handleKeyDown}>
        <Box>
        <Box sx={{height:"calc(100vh - 244px)",overflow:"auto"}}>
        <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"300px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <PaymentsIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                     <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Payment Transactions</Typography>
                </Box>
            </Box>
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}> */}

            <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",justifyContent:"space-between",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <PaidIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Add Payment</Typography>
                                        </Box>
                                </Box>
                              
                            <IconButton onClick={onClose} > <ClearIcon /></IconButton>
                            </Box>
{/* 
                <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "100%" }}>
                        <Typography sx={{ alignSelf: "stretch", width: "289px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Payment Type<Typography component="span" sx={{ color: "red" }}>*</Typography></Typography>
                        <Dropdown required style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={paymentTypeArr} optionLabel="name" optionValue="code"
                            placeholder="Select Payment Type" />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "100%" }}>
                        <Typography sx={{ alignSelf: "stretch", width: "289px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Bulk No:</Typography>
                        <InputText
                            type="number"
                            // value={bulkNum}
                            // onChange={(e) => handleBulkNumChange(e)}
                            style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                            placeholder="Enter Bulk Number"
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "100%" }}>
                        <Typography sx={{ alignSelf: "stretch", width: "289px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Bulk Amount<Typography component="span" sx={{ color: "red" }}>*</Typography></Typography>
                        <InputText
                            required
                            type="number"
                            // value={bulkAmount}
                            // onChange={(e) => handleBulkAmountChange(e)}
                            style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "12px", fontStyle: "inter", fontWeight: "400", lineHeight: "16px", color: "#101010" }}>Available Balance: 0</Typography>
                    </Box>
                </Box> */}

                {/* <Box sx={{ width: "100%", borderBottom: "1px solid #E2E8F0" }}></Box> */}

                <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdrawal date<Typography component="span" sx={{ color: "red" }}>*</Typography></Typography>
                        <Box sx={{ display: "flex", alignItems: "center", position: "relative"}}>
                            <Calendar required value={editedTransaction?.transdate || transdate} onChange={(e) => setTransdate(e.value)} style={{ padding: "0px", width: "100%", height: "36px", display: "flex", alignItems: "center", borderRadius: "4px", fontSize: "14px" }} placeholder='dd-mm-yyyy' dateFormat="dd-mm-yy" />
                            <CalendarMonthIcon sx={{ position: "absolute", right: "21px", color: "#64748B", height: "17px", width: "15px" }} /></Box>
                        {/* {doberror !== "" && <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "red" }}>{doberror}</Typography>} */}
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Member No<Typography component="span" sx={{ color: "red" }}>*</Typography></Typography>
                        <InputText
                            required
                            type="number"
                            value={editedTransaction.memberId || ''}
                            name="memberId"
                            onChange={(e)=>handleChange(e)}
                            onKeyUp={(e) => handleSearch(e)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                        {errors.member !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.member}</Typography>}
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px"}}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Member Name</Typography>
                        <InputText
                            type="text"
                            readOnly={true}
                            name="memberName"
                            value={editedTransaction.memberDetails?.memberName || ''}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px"}}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Age</Typography>
                        <InputText
                            type="number"
                            readOnly={true}
                            name="age"
                            value={editedTransaction.memberDetails?.age || ''}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: "1228px", borderBottom: "1px solid #E2E8F0" }}></Box>

                <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "500", lineHeight: "28px", color: "#64748B" }}>Withdraw from Any one or ALL</Typography>
                
                <Box sx={{display: "flex", gap: "24px", flexWrap: "wrap"}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px",maxWidth: "289px" }}>
                <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px", color: "#000000" }}>Share</Typography>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column"}}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>Current Balance</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#288d2c" }}>RM: {memberDetails[0]?.sharebalance == null ? "0.00" : parseInt(memberDetails[0].sharebalance).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Type</Typography>
                        <Dropdown style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={withdrawalTypeArr} optionLabel="name" optionValue="code"
                            placeholder="Select Withdrawal Type" value={shareWithdrawType} onChange = {(e)=>handleShareWithdrawType(e, "withdrawType")}/>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Amount RM</Typography>
                        <InputText
                            required
                            disabled={ shareWithdrawType=="Part"?memberDetails.length>0&&memberDetails[0].sharebalance>0? false:true : true}
                            type="number"
                            name="share"
                            value={memberDetails.length > 0 && memberDetails[0].sharebalance > 0?sharetrans:0}
                            onChange={(e) => handleShareWithdrawType(e, "withdrawAmount")}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Notes / Narration</Typography>
                        <InputText
                            required
                            disabled={ memberDetails.length>0&&memberDetails[0].sharebalance>0? false:true}
                            type="text"
                            name="narration"
                            value={shareNarration}
                            onChange={(e) => setShareNaraation(e.target.value)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#101010" }}>Available Balance (after withdrawal)</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#101010" }}>
                        RM: {shareWithdrawType === "Part" ? (
                                        memberDetails.length > 0 && memberDetails[0].sharebalance > 0 ? (
                                            sharetrans > 0 && deductFrom === "ShareDeduct" ? (
                                                (memberDetails[0].sharebalance - (parseInt(sharetrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                sharetrans > 0 ? (memberDetails[0].sharebalance - parseInt(sharetrans)).toFixed(2) : deductFrom === "ShareDeduct" ? (memberDetails[0].sharebalance - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - parseInt(editedTransaction.prFee)).toFixed(2) : memberDetails[0]?.sharebalance == null ? "0.00" : parseInt(memberDetails[0].sharebalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.sharebalance == null ? "0.00" : parseInt(memberDetails[0].sharebalance).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && memberDetails[0].sharebalance > 0 ? (
                                            sharetrans > 0 && deductFrom === "ShareDeduct" ? (
                                                (memberDetails[0].sharebalance - (parseInt(sharetrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                sharetrans > 0 ? (memberDetails[0].sharebalance - parseInt(sharetrans)).toFixed(2) : memberDetails[0]?.sharebalance == null ? "0.00" : parseInt(memberDetails[0].sharebalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.sharebalance == null ? "0.00" : parseInt(memberDetails[0].sharebalance).toFixed(2)
                                    )}
                        </Typography>
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px",maxWidth: "289px" }}>
                <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px", color: "#000000" }}>Subscription</Typography>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column"}}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>Current Balance</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#288d2c" }}>RM: {memberDetails[0]?.subscriptionbalance == null ? "0.00" : parseInt(memberDetails[0].subscriptionbalance).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Type</Typography>
                        <Dropdown style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={withdrawalTypeArr} optionLabel="name" optionValue="code"
                            placeholder="Select Withdrawal Type" value={subsWithdrawType} onChange = {(e)=>handleSubsWithdrawType(e, "withdrawType")}/>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Amount RM</Typography>
                        <InputText
                            required
                            disabled = {subsWithdrawType=="Part"?memberDetails.length>0&&memberDetails[0].subscriptionbalance>0? false:true :true}
                            type="number"
                            name="subscription"
                            value={memberDetails.length > 0 && memberDetails[0].subscriptionbalance > 0?subscriptionTrans:0}
                            // (e) => e.target.value <= memberDetails[0].subscriptionbalance && e.target.value > 0? setSubscriptionTrans(e.target.value): setSubscriptionTrans(0)
                            onChange={(e) => handleSubsWithdrawType(e, "withdrawAmount")}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Notes / Narration</Typography>
                        <InputText
                            required
                            disabled = {memberDetails.length>0&&memberDetails[0].subscriptionbalance>0? false:true}
                            type="text"
                            name="narration"
                            value={subsNarration}
                            onChange={(e) => setSubsNarration(e.target.value)}
                            style={{ width: "100%",padding: "8px 12px",borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0",width: "313px" }}></Box>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#101010" }}>Available Balance (after withdrawal)</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#101010" }}>
                        RM: {subsWithdrawType === "Part" ? (
                                        memberDetails.length > 0 && memberDetails[0].subscriptionbalance > 0 ? (
                                            subscriptionTrans > 0 && deductFrom === "SubscriptionDeduct" ? (
                                                (memberDetails[0].subscriptionbalance - (parseInt(subscriptionTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                subscriptionTrans > 0 ? (memberDetails[0].subscriptionbalance - parseInt(subscriptionTrans)).toFixed(2) : deductFrom === "SubscriptionDeduct" ? (memberDetails[0].subscriptionbalance - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - parseInt(editedTransaction.prFee)).toFixed(2) : memberDetails[0].subscriptionbalance == null ? "0.00" : parseInt(memberDetails[0].subscriptionbalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.subscriptionbalance == null ? "0.00" : parseInt(memberDetails[0].subscriptionbalance).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && memberDetails[0].subscriptionbalance > 0 ? (
                                            subscriptionTrans > 0 && deductFrom === "SubscriptionDeduct" ? (
                                                (memberDetails[0].subscriptionbalance - (parseInt(subscriptionTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                subscriptionTrans > 0 ? (memberDetails[0].subscriptionbalance - parseInt(subscriptionTrans)).toFixed(2) : memberDetails[0].subscriptionbalance == null ? "0.00" : parseInt(memberDetails[0].subscriptionbalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.subscriptionbalance == null ? "0.00" : parseInt(memberDetails[0].subscriptionbalance).toFixed(2)
                                    )}
                        </Typography>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px",maxWidth: "289px" }}>
                <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px", color: "#000000" }}>Bonus</Typography>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column"}}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>Current Balance</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#288d2c" }}>RM: {memberDetails[0]?.bonus == null ? "0.00" : parseInt(memberDetails[0].bonus).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Type</Typography>
                        <Dropdown style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={withdrawalTypeArr} optionLabel="name" optionValue="code"
                            placeholder="Select Withdrawal Type" value={bonusWithdrawType} onChange = {(e)=>handleBonusWithdrawType(e, "withdrawType")}/>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Amount RM</Typography>
                        <InputText
                            required
                            disabled={bonusWithdrawType == "Part" ? memberDetails.length > 0 && memberDetails[0].bonus > 0 ? false : true : true}
                            type="number"
                            name="bonus"
                            value={memberDetails.length > 0 && memberDetails[0].bonus > 0?bonusTrans:0}
                            onChange={(e) => handleBonusWithdrawType(e, "withdrawAmount")}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Notes / Narration</Typography>
                        <InputText
                            required
                            disabled={memberDetails.length > 0 && memberDetails[0].bonus > 0 ? false : true}
                            type="text"
                            name="narration"
                            value={boNarration}
                            onChange={(e) => setboNarration(e.target.value)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0",width: "313px" }}></Box>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#101010" }}>Available Balance (after withdrawal)</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#101010" }}>
                        RM: {bonusWithdrawType === "Part" ? (
                                        memberDetails.length > 0 && memberDetails[0]?.bonus > 0 ? (
                                            bonusTrans > 0 && deductFrom === "BonusDeduct" ? (
                                                (memberDetails[0]?.bonus - (parseInt(bonusTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                bonusTrans > 0 ? (memberDetails[0]?.bonus - parseInt(bonusTrans)).toFixed(2) : deductFrom === "BonusDeduct" ? (memberDetails[0]?.bonus - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - parseInt(editedTransaction.prFee)).toFixed(2) : memberDetails[0]?.bonus == null ? "0.00" : parseInt(memberDetails[0].bonus).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.bonus == null ? "0.00" : parseInt(memberDetails[0].bonus).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && memberDetails[0]?.bonus > 0 ? (
                                            bonusTrans > 0 && deductFrom === "BonusDeduct" ? (
                                                (memberDetails[0]?.bonus - (parseInt(bonusTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                bonusTrans > 0 ? (memberDetails[0]?.bonus - parseInt(bonusTrans)).toFixed(2) : memberDetails[0]?.bonus == null ? "0.00" : parseInt(memberDetails[0].bonus).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.bonus == null ? "0.00" : parseInt(memberDetails[0].bonus).toFixed(2)
                                    )}
                        </Typography>
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px",maxWidth: "289px" }}>
                {/* <Box sx={{ display: "flex", gap: "10px", width: "20%" }}> */}
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px", color: "#000000" }}>SD</Typography>
                    {/* <Dropdown style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={deductionFromArr} optionLabel="name" optionValue="code"
                        placeholder="Select Deduction from" /> */}
                {/* </Box> */}
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column"}}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>Current Balance</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#288d2c" }}>RM: {memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0].sdbalance)?"0.00":parseInt(memberDetails[0].sdbalance).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Type</Typography>
                        <Dropdown style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={withdrawalTypeArr} optionLabel="name" optionValue="code"
                            placeholder="Select Withdrawal Type" value={sdWithdrawType} onChange = {(e)=>handleSdWithdrawType(e, "withdrawType")}/>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Withdraw Amount RM</Typography>
                        <InputText
                            required
                            disabled = {sdWithdrawType=="Part"?memberDetails.length>0&&memberDetails[0].sdbalance>0? false:true :true}
                            type="number"
                            name="specificDeposit"
                            value={memberDetails.length>0&&memberDetails[0].sdbalance>0?specificDepositTrans:0}
                            onChange={(e) => handleSdWithdrawType(e, "withdrawAmount")}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column",  }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Notes / Narration</Typography>
                        <InputText
                            required
                            disabled = {memberDetails.length>0&&memberDetails[0].sdbalance>0? false:true}
                            type="text"
                            name="narration"
                            value={sdNarration}
                            onChange={(e) => setSdNaraation(e.target.value)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0",width: "313px" }}></Box>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#101010" }}>Available Balance (after withdrawal)</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#101010" }}>
                        RM: {sdWithdrawType === "Part" ? (
                                        memberDetails.length > 0 && memberDetails[0]?.sdbalance > 0 ? (
                                            specificDepositTrans > 0 && deductFrom === "SpecificDepositDeduct" ? (
                                                (memberDetails[0]?.sdbalance - (parseInt(specificDepositTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                specificDepositTrans > 0 ? (memberDetails[0]?.sdbalance - parseInt(specificDepositTrans)).toFixed(2) : deductFrom === "SpecificDepositDeduct" ? (memberDetails[0]?.sdbalance - (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) - parseInt(editedTransaction.prFee)).toFixed(2) : memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0]?.sdbalance)?"0.00":parseInt(memberDetails[0]?.sdbalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0]?.sdbalance)?"0.00":parseInt(memberDetails[0]?.sdbalance).toFixed(2)
                                    ) : (
                                        memberDetails.length > 0 && memberDetails[0]?.sdbalance > 0 ? (
                                            specificDepositTrans > 0 && deductFrom === "SpecificDepositDeduct" ? (
                                                (memberDetails[0]?.sdbalance - (parseInt(specificDepositTrans) + (editedTransaction.insurance !== '' ? parseInt(editedTransaction.insurance) : 0) + parseInt(editedTransaction.prFee))).toFixed(2)
                                            ) : (
                                                specificDepositTrans > 0 ? (memberDetails[0]?.sdbalance - parseInt(specificDepositTrans)).toFixed(2) : memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0]?.sdbalance)?"0.00":parseInt(memberDetails[0]?.sdbalance).toFixed(2)
                                            )
                                        ) : memberDetails[0]?.sdbalance==null||isNaN(memberDetails[0]?.sdbalance)?"0.00":parseInt(memberDetails[0]?.sdbalance).toFixed(2)
                                    )}
                        </Typography>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px",maxWidth: "289px", justifyContent: "space-between" }}>
                <Box>
                <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px", color: "#000000" }}>Loan</Typography>
                <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", paddingTop: "24px"}}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>Current Balance</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#288d2c" }}>RM: {memberDetails[0]?.loanbalance == null ? "0.00" : parseInt(memberDetails[0].loanbalance).toFixed(2)}</Typography>
                </Box>
                </Box>
                <Box sx={{ borderBottom: "1px solid #E2E8F0", width: "313px" }}></Box>
                </Box>
                </Box>
                <Typography sx={{fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "500", lineHeight: "28px", color: "#64748B" }}>Bank and Cheque Details</Typography>

                <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Bank Name</Typography>
                        <Dropdown value={editedTransaction?.bank || ''} style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} onChange={(e)=>handleChange(e)} name="bank" options={banks} optionLabel='bankname'
                            editable placeholder='Select Bank Name' />
                        {errors.bankerror !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.bankerror}</Typography>}
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Cheque Number</Typography>
                        <InputText
                            name="ChequeNo"
                            value={editedTransaction?.ChequeNo}
                            onChange={(e)=>handleChange(e)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>

                </Box>
                <Box sx={{ width: "1228px", borderBottom: "1px solid #E2E8F0" }}></Box>
                <Typography sx={{ width: "564.5px", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "500", lineHeight: "28px", color: "#64748B" }}>Processing details</Typography>

                <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Deduction from</Typography>
                        <Dropdown required style={{ color: "#CBD5E1", fontSize: "14px", padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", height: "36px", display: "flex", alignItems: "center" }} options={deductionFromArr} optionLabel="name" optionValue="code"
                            placeholder="Select Withdrawal Type" value={deductFrom} onChange={(e) => handleDeductRadio(e)} />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Processing fee</Typography>
                        <InputText
                            required
                            type="number"
                            name="prFee"
                            value={editedTransaction?.prFee}
                            onChange={(e)=>handleChange(e)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>DBS (Insurance)</Typography>
                        <InputText
                            required
                            type="number"
                            name="insurance"   
                            value={editedTransaction?.insurance}
                            onChange={(e)=>handleChange(e)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", maxWidth: "289px", width: "289px" }}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>GST %</Typography>
                        <InputText
                            type="number"
                            // value={memNum}
                            // onChange={(e) => handleMemNumChange(e)}
                            style={{ width: "100%",padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", fontSize: "14px" }}
                        />
                    </Box>

                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: "10px 0px 0px 0px" }}>
                                <Box sx={{ width: "15%", height: "35px", background: "whitesmoke", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderBottom: toggle == "SD" ? "2px solid var(--pallate-green-500, #288d2c)" : "none", borderRadius: "10px 10px 0px 0px" }} onClick={() => setToggle("SD")}>
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>Specific Deposit</FormLabel>
                                </Box>
                                <Box sx={{ width: "15%", height: "35px", background: "whitesmoke", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderBottom: toggle == "LN" ? "2px solid var(--pallate-green-500, #288d2c)" : "none", borderRadius: "10px 10px 0px 0px" }} onClick={() => setToggle("LN")}>
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>Active Loan[s]</FormLabel>
                                </Box>
                                <Box sx={{ width: "15%", height: "35px", background: "whitesmoke", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderBottom: toggle == "GU" ? "2px solid var(--pallate-green-500, #288d2c)" : "none", borderRadius: "10px 10px 0px 0px" }} onClick={() => setToggle("GU")}>
                                    <FormLabel component="legend" sx={{ fontWeight: "700" }}>As Guarantor</FormLabel>
                                </Box>
                            </Box>
                            {toggle == "SD" &&
                                <Box>
                                    <DataTable className="custom-datatable" value={SDlist} rows={5} style={{ backgroundColor: "#F1F5F9", height: "100%" }} scrollable scrollHeight="250px" columnfinalMembersizeMode="expand" >
                                        {sdColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                </Box>}
                            {toggle == "LN" &&
                                <><Box>
                                    <DataTable value={loan} rows={5} style={{ backgroundColor: "#F1F5F9", height: loan.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                        {ColumnVisible.filter(col => col.visible).map((col, ind) => <Column key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                </Box>
                                    <Box>
                                        <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Loans Above</FormLabel>
                                        <DataTable value={Guarantor} rows={5} style={{ backgroundColor: "#F1F5F9", height: Guarantor.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                            {GuarantorColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                        </DataTable>
                                    </Box>
                                </>}
                                {toggle == "GU" &&
                                    <Box>    
                                    <DataTable value={guarantorForList} rows={5} style={{ backgroundColor: "#F1F5F9", height: guarantorForList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                    {mgColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                    </Box>
                                }
                        </Box>

                {/* <DialogActions sx={{ pl: 3, pr: 3, display: "flex", justifyContent: errors.balance == '' && errors.member == '' ? "flex-end" : "space-between" }}>
                    {errors.balance !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.balance}</Typography>}
                    {errors.trans !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.trans}</Typography>}
                    {errors.member !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "500", fontSize: "18px" }}>{errors.member}</Typography>}
                    <Box sx={{ display: "flex", gap: "20px", padding: "10px 0px " }}>
                        <Button sx={{ fontWeight: "700" }} onClick={onClose}>Cancel</Button>
                        <button type='submit' style={{ background: "#288d2c", border: "none", width: "100px", height: "40px", borderRadius: "10px", color: "#FFF", fontWeight: "700", cursor: "pointer" }} >SAVE</button></Box>
                </DialogActions> */}
                        <Box sx={{position:"absolute",top:"50%",left:"50%"}}>
                        <Dialog visible={paymentOpen} style={{maxWidth:"1500px", height: "90vh", width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false} >
                        
                                <Box sx={{height:"calc(90vh - 160px)", padding:"30px",display:"flex",flexDirection:"column",gap:"24px",background:"#f2f6fc",overflow: "auto"}}>

                                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <PaidIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>{!bulkNum?"Add":"Edit"} Payment Preview</Typography>
                                        </Box>
                                </Box>
                                </Box>
                                {bulkNum !== 0 && <Box sx={{display:"flex",justifyContent:"end"}}>
                                <IconButton sx={{display:"flex"}} onClick={handleMenuClick}> <MoreHorizIcon/></IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    sx={{marginRight:"20px",padding:"0px"}}
                                 >
                                    {/* Menu options */}
                                    <CSVLink data={bulkCSV} filename={'Bulk-transfer.csv'}><MenuItem disabled={!bulkNum} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} >Bulk Data</MenuItem></CSVLink>
                                    <CSVLink data={banktempCSV} filename={'Bank-template.csv'}><MenuItem disabled={!bulkNum} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} >Bank Template</MenuItem></CSVLink>
                                    <MenuItem disabled={!bulkNum} onClick = {() => handlePrintAll()} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} >Print All</MenuItem>
                                </Menu>
                                </Box>}
                                <DataTable value={transactions} paginator rows={5} dataKey="memberid" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" columnResizeMode="expand" removableSort resizableColumns
                                filterIcon={false} emptyMessage="No transactions found.">
                                    <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>
                                    {columnVisibleTransactions.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} header={col.header} body={col.body} alignFrozen="left" sortable />)}
                                    <Column header ='Actions' body={editData} />
                                    <Column header ='Print' body={printData} />
                                    <Column header="Remove" body={deleteTrans} />
                                    </DataTable>
                                <Box sx={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                                    <Button onClick={() => handleCancelANP("ANP")} sx={{height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c"}} variant="contained" startIcon={<PaidOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>}>                                    
                                    <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>Add New Payment</Typography>
                                    </Button>
                                </Box>
                                </Box>
                                
                                <Box sx={{position:"sticky",bottom:0}}>
                    <Box sx={{display:"flex",height:"64px",justifyContent:"space-between",padding:"16px 42px 16px 30px",background:"#E2E8F0"}}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Typography sx={{alignSelf: "center", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Total Bulk amount</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#64748B" }}>RM: {editedTransaction?.prFee > 0 || editedTransaction?.insurance > 0?(parseFloat(editedTransaction?.prFee) + parseFloat(editedTransaction?.insurance)).toFixed(2):"0.00"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "12px" }}>
                        <Typography sx={{alignSelf: "center", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Total Payment:</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "24px", fontStyle: "inter", fontWeight: "800", lineHeight: "32px", color: "#EF4444" }}>
                        RM: {
                                        (
                                            parseFloat(sharetrans) +
                                            parseFloat(subscriptionTrans) +
                                            parseFloat(specificDepositTrans) +
                                            parseFloat(bonusTrans) +
                                            (deductFrom === "ShareDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SubscriptionDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SpecificDepositDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "BonusDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0)
                                        ).toFixed(2)
                                    }
                        </Typography>
                    </Box>
                 </Box>
                 <Box sx={{height:"96px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"24px 30px",background:"#F1F5F9"}}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Button onClick={()=> handleCancelANP("cancel")} sx={{width: "178px", height: "48px", borderRadius: "4px", padding: "12px"}} startIcon={<ClearIcon sx={{ color: "#64748B", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Cancel</Typography>
                        </Button>
                        <Button onClick={(e) => handleSubmitAllTransactions(e)} sx={{ height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c"}} variant="contained" startIcon={<AddIcon sx={{ color: "#FFFFFF", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>{!bulkNum? "Submit" : "Edit All Transactions"}</Typography>
                        </Button>
                    </Box>
                              
                </Box> 
                 </Box>
               
                            </Dialog>
                            </Box>
            </Box>
        </Box>
        <Box sx={{position:"sticky",bottom:0}}>
                    <Box sx={{display:"flex",height:"64px",justifyContent:"space-between",padding:"16px 42px 16px 30px",background:"#E2E8F0"}}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Total deduction</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#64748B" }}>RM: {editedTransaction?.prFee > 0 || editedTransaction?.insurance > 0?(parseFloat(editedTransaction?.prFee) + parseFloat(editedTransaction?.insurance)).toFixed(2):"0.00"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "12px" }}>
                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Total Withdrawal amount (after deductions):</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "24px", fontStyle: "inter", fontWeight: "800", lineHeight: "32px", color: "#101010" }}>
                        RM: {
                                        (
                                            parseFloat(sharetrans) +
                                            parseFloat(subscriptionTrans) +
                                            parseFloat(specificDepositTrans) +
                                            parseFloat(bonusTrans) +
                                            (deductFrom === "ShareDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SubscriptionDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SpecificDepositDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "BonusDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0)
                                        ).toFixed(2)
                                    }
                        </Typography>
                    </Box>
                 </Box>
                 <Box sx={{height:"96px",width:"100%",display:"flex",justifyContent:"space-between",gap:"24px",padding:"24px 30px",background:"#F1F5F9", flexWrap: "wrap"}}>
                 <Box sx={{ display: "flex", gap: "8px" }}>
                        <Button onClick={()=>setPaymentOpen(true)} sx={{width: "204px", height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#475569"}} startIcon={<VisibilityOutlinedIcon sx={{ color: "#FFFFFF", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>Preview</Typography>
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Button onClick={() => handleCancelANP("ANP") } sx={{width: "178px", height: "48px", borderRadius: "4px", padding: "12px"}} startIcon={<ClearIcon sx={{ color: "#64748B", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Clear</Typography>
                        </Button>
                        <Button type='submit' sx={{width: "204px", height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c"}} startIcon={<AddIcon sx={{ color: "#FFFFFF", height: "24px", width: "24px" }} />} variant="contained">
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>Save, Add New</Typography>
                        </Button>
                    </Box>
                              
                </Box> 
                 </Box>
        </Box>
        </form>
    );
};


// Helper function to create a new transaction object template
const createNewTransaction = () => ({
    memberId: '',
    bank: '',
    ChequeNo: '',
    transtype: '',
    narration: '',
    insurance: 0,
    prFee: 0,
    transdetailsid: '',
    memberDetails: {
        memberName: '',
        oldIcNo: '',
        newIcNo: '',
        state: '',
        estate: '',
        accountno: '',
        mobileno: '',
        email: '',
        membank: ''
    },
});


export const PaymentsCopy = () => {


    const [transactions, setTransactions] = useState([]);
    const [finatransactions, setFinatransactions] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [transactionTypes, setTransactionTypes] = useState([{ id: 21, name: 'Share' }, { id: 22, name: 'Subscription' }, { id: 24, name: 'SpecificDeposit' }]);
    const [successMessage, setSuccessMessage] = useState(false);
    const [exportCSV, setExportCSV] = useState([])
    const [bankCSV, setBankCSV] = useState([])
    const [bulkCSV, setBulkCSV] = useState([])
    const [originbulkCSV, setOriginBulkCSV] = useState([])
    const [originbankCSV, setOriginBankCSV] = useState([])
    const [loanList,setLoanList ] = useState([])
    const [guarantorList,setGuarantorList ] = useState([])
    const [specificList,setspecificList ] = useState([])
    const [guarantorForList,setguarantorForList ] = useState([])
    const [banktempCSV, setBankTempCSV] = useState([])
    const [estateList, setEstateList] = useState([])
    const [addTransRestrict, setAddTransRestrict] = useState(false)
    const [downloadexcel, setdownloadexcel] = useState(false)
    const [bulkno, setBulkNo] = useState(0)
    const [searchbulkdialogopen, setSearchBulkDialogopen] = useState(false)
    const [editbutton, setEditButton] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [printModal, setPrintModal] = useState(false);
    const [bulkHeading, setBulkHeading] = useState("");
    const [printModalButton, setprintModalButton] = useState("");
    const [paymentModal,setPaymentModal]=useState(false)
    const [lastBulkTrans, setLastBulkTrans] = useState([])
    const [pageNo,setPageNo] = useState(1)
    const [searchBulkNo, setSearchBulkNo] = useState("");

    const formatDateTransDate = (date) => {
        if (typeof (date) != "string") {
            const new_date = new Date(date)
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const day = String(new_date.getDate()).padStart(2, '0');
            const monthIndex = String(new_date.getMonth() + 1).padStart(2, '0');
            const month = months[monthIndex];
            const year = new_date.getFullYear();
            console.log(day)
            return `${day}-${monthIndex}-${year}`;
        }
        else {
            const dd = String(new Date(date).getUTCDate()).padStart(2, '0');
            const mm = String(new Date(date).getUTCMonth() + 1).padStart(2, '0'); // January is 0!
            const yy = String(new Date(date).getUTCFullYear());

            return `${dd}-${mm}-${yy}`;
        }

    };
    const dateTransDate = new Date(); // Assuming input date is in ISO 8601 format
    const formattedDate = formatDateTransDate(dateTransDate);
    //console.log(formattedDate)

    const [columnVisibleTransactions, setColumneVisibleTransactions] = useState([
        { field: "remarks", header: "Bulk #", visible: true },
        { field: "transdate", header: "Date", visible: true, body: row => formatDateTransDate(row.transdate) },
        { field: "transdetailsid", header: "RN", visible: true },
        { field: "memberId", header: "M#", visible: true },
        { field: "memberName", header: "Member Name", visible: true, body: row => row.memberDetails?.memberName },
        { field: "age", header: "Age", visible: true, body: row => row.memberDetails?.age },
        { field: "transtype", header: "Transaction Type", visible: true, body: row => row.transtype == 21 || row.transtypeid == 21 ? "Share" : row.transtype == 22 || row.transtypeid == 22 ? "Subscription" : row.transtype == 24 || row.transtypeid == 24 ? "specificDeposit" : "Bonus" },
        
        { field: "transtotalamount", header: "Total Amount", visible: true },
        { field: "transamount", header: "Withdrawal Amount", visible: true, body: row => parseInt(row.transamount).toFixed(2) },
        { field: "bank", header: "Bank", visible: true },
        { field: "ChequeNo", header: "Chq#", visible: true },
        { field: "narration", header: "Narration", visible: true },
        { field: "insurance", header: "Insurance", visible: true },
        { field: "prFee", header: "Processing Fee", visible: true }
    ])
    // Fetch initial data for banks on component mount
    useEffect(() => {
        const fetchBanksAndTypes = async () => {
            setLoading(true);
            try {
                const bankResponse = await CustomAxios.get(`oasis/form_details/`);
                setBanks(bankResponse.data.bankName); // Assuming the response structure
                setEstateList(bankResponse.data.estateList)
                //console.log("bank info", bankResponse.data.bankName);
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
            setLoading(false);
        };
        fetchBanksAndTypes();
    }, []);
    // Function to fetch member details

    const onRemove = (index) => {
        const updatedTransactions = transactions.filter((_, i) => i !== index);
        setTransactions(updatedTransactions);
    };
    const handlePrintAll = async () => {
        try {
            let printurl;
            printurl = `oasis/download-Wreceipt-all/${bulkno}`;
            const response = await CustomAxios.get(printurl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
                console.log(fileURL)
                // Open the PDF in a new window/tab
                // const win =
                window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                // win.onload = () => {
                //     win.focus();
                //     win.print();
                // };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    }

    const handleFetchAndPrintReceipt = async (receiptId, type) => {
        try {
            // URL to your Django view that generates the PDF
            let receiptUrl;
            // if(type == "single"){
            receiptUrl = `oasis/download-Wreceipt/${receiptId}`;
            // }
            // else{
            //     receiptUrl = `${process.env.REACT_APP_OASIS_BE_URL}oasis/download-Wreceipt-all/${receiptId}`;
            // }

            // Fetch the PDF receipt
            const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
                console.log("------------", fileURL)
                // Open the PDF in a new window/tab
                // const win = 
                window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                // window.open(fileURL, )
                // win.onload = () => {
                //     win.focus();
                //     win.print();
                // };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };

    const getLoanGuarantor = async (memberId) => {
        try {
            let payload = { memberid: memberId };
            if(memberId){
                const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberId)}`);
                //console.log("gggggggggggggggg", response.data);
                setLoanList(response.data.loan)
                setGuarantorList(response.data.guarantor)
                setspecificList(response.data.sd)
                setguarantorForList(response.data.memberGuarantor)
            }

        } catch (error) {
            console.error("Error fetching member details:", error);
        }
    }
    // Function to fetch member details by memberId
    const getMemberDetails = async (memberId) => {
        //console.log("inside getMemberDetails")
        //console.log("Member ID: ", memberId)

        let payload = {};

        // Directly use memberId to create the payload
        payload = { memberid: memberId };

        // Proceed with the API call
        if (memberId) {
            try {
                const response = await CustomAxios.post(`oasis/getmember/`, payload);
                getLoanGuarantor(memberId)
                // Handle the response accordingly
                const currentDate = new Date()
                const birthDateObj = new Date(response.data.dob)
                const currentDateObj = new Date(currentDate)
                const timeDiff = currentDateObj - birthDateObj
                const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
                response.data.age = age + 1
                //console.log("Member details fetched successfully", response.data, new Date(response.data.dob).getFullYear());
                return response.data;
                // Update state or perform further actions
            } catch (error) {
                console.error("Error fetching member details:", error);
            }
        } else {
            console.error("No valid memberId provided for fetching member details.");
        }


    };



    // const getMemberDetails = async (index) => {
    //     console.log("inside getMemberDetails")
    //     console.log("index: ", index)
    //     setLoading(true);
    //     // Retrieve the current transaction based on index
    //     const transaction = transactions[index];
    //     // Initialize the payload with an empty object
    //     let payload = {};

    //     // Check which field(s) has/have been provided and adjust the payload accordingly
    //     if (transaction.memberId) {
    //         payload = { memberid: transaction.memberId };
    //     } else if (transaction.memberDetails.newIcNo) {
    //         payload = { icno: transaction.memberDetails.newIcNo };
    //     } else if (transaction.memberDetails.memberName) {
    //         payload = { name: transaction.memberDetails.memberName };
    //     }

    //     // Make sure there's at least one criteria before making the API call
    //     if (Object.keys(payload).length > 0) {
    //         try {
    //             const response = await axios.post(`${process.env.REACT_APP_OASIS_BE_URL}oasis/getmember/`, payload);
    //             // Assuming the API response format aligns with our expectations
    //             const newTransactions = [...transactions];
    //             const { membername, oldicno, newicno, memberid } = response.data;
    //             newTransactions[index].memberDetails = { memberName: membername, oldIcNo: oldicno, newIcNo: newicno, memberId: memberid };
    //             setTransactions(newTransactions);
    //         } catch (error) {
    //             console.error("Error fetching member details:", error);
    //             // Optionally handle errors, such as setting an error state or displaying a message
    //         }
    //     } else {
    //         // Handle the case where no valid input was given
    //         console.error("No valid input provided for fetching member details.");
    //     }

    //     setLoading(false);
    // }; 

    const openDialogToAdd = (index) => {
        typeof index == "number" ? setCurrentTransaction({ ...transactions[index], index }) : setCurrentTransaction(createNewTransaction());
        setDialogOpen(true);
        setPaymentModal(false)
    };

    const openDialogToEdit = (index) => {
        //console.log(transactions[index])
        setCurrentTransaction({ ...transactions[index], index }); // Load the current transaction for editing
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleTransactionChange = (name, value) => {
        setCurrentTransaction((prev) => ({ ...prev, [name]: value }));
    };

    // const saveTransaction = () => {
    //     if (currentTransaction.index >= 0) {
    //         // Update existing transaction
    //         const updatedTransactions = transactions.map((t, idx) => idx === currentTransaction.index ? currentTransaction : t);
    //         setTransactions(updatedTransactions);
    //     } else {
    //         // Add new transaction
    //         setTransactions([...transactions, currentTransaction]);
    //     }
    //     setDialogOpen(false);
    // };

    const saveTransaction = (updatedTransaction) => {
        //console.log(currentTransaction,updatedTransaction)
        if (typeof currentTransaction.index === "number") {
            const itemTosplice = transactions.filter((data) => data.id == currentTransaction.id)
            const index = transactions.findIndex(item => item.id === itemTosplice[0].id);
            //console.log(index,currentTransaction.index,itemTosplice.length, ...updatedTransaction)
            transactions.splice(index, itemTosplice.length, ...updatedTransaction)
        }
        else {
            const filtered = transactions.filter((data) => data.id !== currentTransaction.id)
            setTransactions([...filtered, ...updatedTransaction]);
        }


        // setDialogOpen(false);
    };

    // useEffect(() => {
    //     if (successMessage) {
    //         const timer = setTimeout(() => setSuccessMessage(false), 3000); // Clear message after 3 seconds
    //         return () => clearTimeout(timer);
    //     }
    // }, [successMessage]);

    // Submit all transactions
    const handleSubmitAllTransactions = async (event) => {
        setdownloadexcel(true)
        //console.log("inside submitAllTransactions")
        //console.log(transactions)
        //setFinatransactions(transactions);
        event.preventDefault();
        // const newExportArray = exportCSV.slice(0, -1);
        // setExportCSV(newExportArray)
        // const newBankArray = bankCSV.slice(0, -1);
        // setBankCSV(newBankArray)
        // setExportCSV([])
        // setBankCSV([])
        setIsSubmitting(true);

        setLoading(true);
            const dateformate = (date) =>{
                let formattedDate
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');
                    formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
                    console.log(formattedDate)
                    return formattedDate
                }
            
        // Prepare the payload by mapping over transactions and adjusting the structure
        const payload = transactions.map(transaction => ({
            transdate: dateformate(transaction.transdate),
            memberid: parseInt(transaction.memberId),
            transtypeid: parseInt(transaction.transtype),
            transamount: parseInt(transaction.transamount), // Ensure this is a number
            bankname: transaction.bank.trim(), // Remove any trailing spaces
            narration: transaction.narration,
            transmasterid: parseInt(-5), // Assuming a static value here; adjust as needed
            insurance: parseInt(transaction.insurance), // Ensure this is a number
            processingfee: parseInt(transaction.prFee), // Ensure this is a number
            transtotalamount: parseInt(transaction.transtotalamount), // Ensure this is a number
            chequeno: transaction.ChequeNo, // Ensure this is a number
            // transdate: transaction.transdate, // Ensure this is a number
        }));

        try {
            // Adjust endpoint and payload structure as needed
            //console.log(payload)
            setLoading(true)
            const response = await CustomAxios.post(`oasis/withdrawal/`, payload);
            //console.log(response.data);

            if (response.data.success) {
                setLoading(false);
                setBulkNo(response.data.success[0].remarks)
                // Assuming response.data.success is an array with corresponding transactions including transdetailsid
                const updatedTransactions = transactions.map((transaction, index) => {
                    // Find the corresponding response transaction by index or other criteria
                    const responseTransaction = response.data.success.find(rt =>
                        rt.memberid === parseInt(transaction.memberId) &&
                        rt.transamount === parseInt(transaction.transamount) &&
                        rt.transtypeid === parseInt(transaction.transtype)
                    );
                    // If found, append transdetailsid to the transaction
                    return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
                });

                // Update transactions state with transdetailsid
                setTransactions(updatedTransactions);
                const final = [...updatedTransactions]
                console.log("#### Transactions #####", transactions);
                console.log("#### Updated Transactions #####", updatedTransactions);
                console.log("#### Final Transactions #####", updatedTransactions);
                setFinatransactions(final);
                //GetCsvValues(final)
                //console.log(exportCSV)
                //console.log(bankCSV)
                //console.log(transactions) 
                setSuccessMessage(true);
                setAddTransRestrict(true)
            }

            // setTransactions([])
            // Handle success (e.g., show message, clear form)
            // setTransactions([createNewTransaction()]);
        } catch (error) {
            //console.error("Error submitting transactions:", error);
            setLoading(false);
        }
        setLoading(false);
    };
    function formatDate() {
        const day = new Date().getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = (new Date().getMonth() + 1).toString().padStart(2, '0'); // Get the month (adding 1 to account for zero-based index) and pad with leading zero if needed
        const year = new Date().getFullYear(); // Get the full year
        return `${day}-${month}-${year}`; // Combine day, month, and year with hyphens
    }

    const excel_formatDate = (date) => {
        console.log(date)
        if(typeof(date)!="string"){
            const new_date = new Date(date)
         const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
         const day = String(new_date.getDate()).padStart(2, '0');
         const monthIndex = String(new_date.getMonth() + 1).padStart(2, '0');
         const month = months[monthIndex];
         const year = new_date.getFullYear();
         return `${day}-${monthIndex}-${year}`; 
         }
         else{
             const new_date = new Date(date)
        const dd = String(new_date.getUTCDate()).padStart(2, '0');
        const mm = String(new_date.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
        const yy = String(new_date.getUTCFullYear());
        
        return `${dd}-${mm}-${yy}`;
         }
       
    };

    useEffect(() => {
        //console.log(finatransactions)
        const bankList = banks.filter((bank)=>bank.bankname!==null)
        if (finatransactions.length > 0) {
            let pfee = 0
            let ins = 0
            //console.log("we are here")
        for (let i=0;i<=finatransactions.length-1; i++){
            // console.log(finatransactions[i])
            //console.log(finatransactions[i])
            const csvValues ={
                MNo:'',
                Name:'',
                State:'',
                Estate:'',
                Narration:'',
                TransactionDate:'',
                Cheque_No:'',
                SH_PV_No:'',
                SH_Amt_RM:0,
                SUBS_PV_No:'',
                SUBS_Amt_RM:0,
                Bonus_PV_No:'',
                Bonus_Amt_RM:0,
                SD_PV_No:'',
                SD_Amt_RM:0,
                BR_PV_No:'',
                BR_Amt_RM:0,
                P_Fee_RM:0,
                DBS_Insurance_RM:0,
                Gst_RM:0,
                PF_Deduction:'',
                Total_RM:0,
                Total_Deduction_RM:0,
                Cheque_Amount_RM:0
            
            };
        
            const bankcsvValues = {
                Payment_Type_IBG_PBB_REN:'',
                Bene_Account_No:'',
                BIC:'',
                Bene_Full_Name:'',
                ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT:'',
                Bene_Identification_No:'',
                Payment_Amount:0,
                Recipient_Reference:'',
                Other_Payment_Details:'',
                Bene_Email:'',
                Bene_Mobile_No:'',
                Joint_Bene_Name:'',
                Joint_Beneficiary_Identification_No:'',
                Joint_ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT:'',
                Email_Content_Line_1:'',
                Email_Content_Line_2:'',
                Email_Content_Line_3:'',
                Email_Content_Line_4:'',
                Email_Content_Line_5:'',
            }
            // pfee+=parseInt(finatransactions[i].prFee)
            // ins+=parseInt(finatransactions[i].insurance)
            csvValues.MNo = finatransactions[i].memberId;
            csvValues.Name = finatransactions[i].memberDetails.memberName;
            csvValues.Estate = finatransactions[i].memberDetails.estate;
            const Findstate = estateList.filter((data)=> data.estatename == finatransactions[i].memberDetails.estate )
            if (Findstate.length>0){
            csvValues.State =  Findstate[0].state;}
            console.log(finatransactions[i].transdetailsid)
            if (finatransactions[i].transtype==21||finatransactions[i].transtypeid==21){
            csvValues.SH_Amt_RM = finatransactions[i].transtotalamount
            csvValues.SH_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==22||finatransactions[i].transtypeid==22){
                csvValues.SUBS_Amt_RM = finatransactions[i].transtotalamount
                csvValues.SUBS_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==24||finatransactions[i].transtypeid==24){
                csvValues.SD_Amt_RM = finatransactions[i].transtotalamount
                csvValues.SD_PV_No =finatransactions[i].transdetailsid}
            if (finatransactions[i].transtype==34||finatransactions[i].transtypeid==34){
                csvValues.Bonus_Amt_RM = finatransactions[i].transtotalamount
                csvValues.Bonus_PV_No =finatransactions[i].transdetailsid}
            csvValues.Narration = finatransactions[i].narration;
            csvValues.DBS_Insurance_RM = parseInt(finatransactions[i].insurance)
            csvValues.TransactionDate = excel_formatDate(finatransactions[i].transdate);
            csvValues.Cheque_No = finatransactions[i].bank.split(" ")[0] + " " + finatransactions[i].ChequeNo
            csvValues.P_Fee_RM = parseInt(finatransactions[i].prFee)
            csvValues.Total_Deduction_RM = csvValues.P_Fee_RM + csvValues.DBS_Insurance_RM
            csvValues.Total_RM = parseInt(csvValues.SH_Amt_RM) + parseInt(csvValues.SUBS_Amt_RM) + parseInt(csvValues.SD_Amt_RM) + parseInt(csvValues.Bonus_Amt_RM)
            csvValues.Cheque_Amount_RM = parseInt(csvValues.Total_RM) - parseInt(csvValues.Total_Deduction_RM)
            bankcsvValues.Payment_Amount =    (parseInt(csvValues.SH_Amt_RM) + parseInt(csvValues.SUBS_Amt_RM) + parseInt(csvValues.SD_Amt_RM) + parseInt(csvValues.Bonus_Amt_RM))- parseInt(csvValues.Total_Deduction_RM)
            bankcsvValues.Bene_Account_No = finatransactions[i].memberDetails.accountno+""
            bankcsvValues.Bene_Full_Name = finatransactions[i].memberDetails.memberName
            bankcsvValues.Bene_Email = finatransactions[i].memberDetails.email
            bankcsvValues.Bene_Mobile_No = finatransactions[i].memberDetails.mobileno
            bankcsvValues.Bene_Identification_No = finatransactions[i].memberDetails.newIcNo
            bankcsvValues.Recipient_Reference = "WITHDRAWAL"
            bankcsvValues.Other_Payment_Details = finatransactions[i].memberId
            bankcsvValues.ID_Type_For_Intrabank_IBG_NI_OI_BR_PL_ML_PP_For_Rentas_NI_OI_BR_OT = "NI"
            console.log(banks)
            for (let j=0;j<bankList.length-1;j++){
                if(bankList[j].bankname.trim()==finatransactions[i].memberDetails.membank){
                    bankcsvValues.BIC = bankList[j].swiftcode
                    if (bankList[j].swiftcode=="PBBEMYKL"){
                        bankcsvValues.Payment_Type_IBG_PBB_REN = "PBB"
                    }
                    else{
                        bankcsvValues.Payment_Type_IBG_PBB_REN = "IBG"
                    }
                   
                }
            }
           
            setExportCSV(prev => [...prev, csvValues]);
        setBankCSV(prev => [...prev, bankcsvValues])
            
            
        }}
        
        // setExportCSV((prev) => [
        //     ['KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
        //     ,...prev
        //   ]);

    },[finatransactions])

    // useEffect(()=> {
    //     const combinedDictList=[]
    // originbulkCSV.forEach((dict, index) => {
    //   // Check if this dictionary has already been combined
    //   if (!dict._combined) {
    //     // Create a new combined dictionary
    //     const combinedDict = { ...dict };

    //     // Search for other dictionaries with the same key value
    //     for (let i = index + 1; i < originbulkCSV.length; i++) {
    //       if (originbulkCSV[i].MNo === dict.MNo) {
    //         if (dict.SH_Amt_RM==0 && originbulkCSV[i].SH_Amt_RM>0){
    //             dict.SH_Amt_RM=originbulkCSV[i].SH_Amt_RM
    //             dict.SH_PV_No=originbulkCSV[i].SH_PV_No
    //         }
    //         if (dict.SUBS_Amt_RM==0 && originbulkCSV[i].SUBS_Amt_RM>0){
    //             dict.SUBS_Amt_RM=originbulkCSV[i].SUBS_Amt_RM
    //             dict.SUBS_PV_No=originbulkCSV[i].SUBS_PV_No
    //         }
    //         if (dict.SD_Amt_RM==0 && originbulkCSV[i].SD_Amt_RM>0){
    //             dict.SD_Amt_RM=originbulkCSV[i].SD_Amt_RM
    //             dict.SD_PV_No=originbulkCSV[i].SD_PV_No
    //         }
    //         if (dict.DBS_Insurance_RM==0 && originbulkCSV[i].DBS_Insurance_RM>0){
    //             dict.DBS_Insurance_RM=originbulkCSV[i].DBS_Insurance_RM
    //         }
    //         if (dict.DBS_Insurance_RM==0 && originbulkCSV[i].DBS_Insurance_RM>0){
    //             dict.DBS_Insurance_RM=originbulkCSV[i].DBS_Insurance_RM
    //         }
    //         dict.P_Fee_RM = originbulkCSV[i].prFee
    //         dict.Total_Deduction_RM = originbulkCSV[i].P_Fee_RM + originbulkCSV[i].DBS_Insurance_RM
    //         dict.Total_RM = originbulkCSV[i].SH_Amt_RM + originbulkCSV.SUBS_Amt_RM + originbulkCSV[i].SD_Amt_RM + originbulkCSV[i].Total_Deduction_RM
    //         dict.Cheque_Amount_RM = originbulkCSV[i].Total_RM -originbulkCSV[i].Total_Deduction_RM

    //       }
    //     }

    //     // Add the combined dictionary to the new list
    //     combinedDictList.push(combinedDict);
    //   }
    // })

    // // Update the state with the combined list
    // setExportCSV(combinedDictList)
    // setBankCSV(originbankCSV)
    // },[originbankCSV, originbulkCSV])

    const formatTodayDate = () => {
        const date = new Date()
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate();
        const month = String(date.getMonth()+1).padStart(2,'0');
        // const month = months[monthIndex];
        const year = date.getFullYear();
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        // const seconds = date.getSeconds();
        //console.log(`${day}/${month}/${year} ${hours}:${minutes} ${seconds}`)
        return `${day}-${month}-${year}`;
    };

    const fetchBulkTransDetails = async(bulkNum) => {
        const response = await CustomAxios.get(`oasis/bulk-withdrawal-edit/?bulkno=${parseInt(bulkNum)}`);
        return response;
    }

    const handleBulkEdit = async (bulkNum) => {
        setdownloadexcel(false)
        setLoading(true);
        try {
            const response = await fetchBulkTransDetails(bulkNum)
            console.log(response)
            if (response.data.data) {
                setLoading(false);
                console.log(transactions)
                setSearchBulkDialogopen(false);
                const updatedTransaction = response.data.data
                updatedTransaction.map((transaction) =>
                    transaction["id"] = transaction.memberid)
                updatedTransaction.map((trans) =>
                    trans.bank = trans.bank + "                                               ")
                const updatedTransactions = transactions.map((transaction, index) => {
                    // Find the corresponding response transaction by index or other criteria
                    const responseTransaction = response.data.data.find(rt =>
                        rt.memberid === parseInt(transaction.memberId) &&
                        rt.transamount === parseInt(transaction.transamount) &&
                        rt.transtypeid === parseInt(transaction.transtype)
                    );
                    console.log(responseTransaction)
                    // If found, append transdetailsid to the transaction
                    return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
                });
                //console.log(updatedTransactions)
                setTransactions(updatedTransaction);
                const final = [...response.data.data]
                setFinatransactions(final.sort((a,b)=>a.transdetailid - b.transdetailid));
                setIsSubmitting(false)
                setEditButton(true)
            }
        } catch (error) {
            console.error("Error submitting transactions:", error);
            setLoading(false);
        }
    }
    const HanldeSearchBulk = () => {
        setdownloadexcel(false)
        setTransactions([])
        setFinatransactions([])
        setBankCSV([])
        setExportCSV([])
        setSearchBulkDialogopen(true)
    }

    const handleEditAllTransactions = async () => {
        setBankCSV([])
        setExportCSV([])
        setIsSubmitting(false);

    try{
        setLoading(true);
        transactions.map((trans)=>{
           if(typeof(trans.transdate)!=='string'){
            const year = trans.transdate.getFullYear();
        const month = String(trans.transdate.getMonth() + 1).padStart(2, '0');
        const day = String(trans.transdate.getDate()).padStart(2, '0');
        const hours = String(trans.transdate.getHours()).padStart(2, '0');
        const minutes = String(trans.transdate.getMinutes()).padStart(2, '0');
        const seconds = String(trans.transdate.getSeconds()).padStart(2, '0');
        trans.transdate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    }
})
    const payload={
        transactions:transactions
    }
    setdownloadexcel(true)
    const response = await CustomAxios.post(`oasis/bulk-withdrawal-edit/`, payload);
    console.log(response)
    if (response.data.success){
        setLoading(false);
        console.log(transactions)
        const updatedTransactions = transactions.map((transaction, index) => {
            // Find the corresponding response transaction by index or other criteria
            const responseTransaction = response.data.success.find(rt => 
                rt.memberid === parseInt(transaction.memberId) && 
                rt.transamount === parseInt(transaction.transamount) && 
                rt.transtypeid === parseInt(transaction.transtype)
            );
            console.log(responseTransaction)
            // If found, append transdetailsid to the transaction
            return responseTransaction ? { ...transaction, transdetailsid: responseTransaction.transdetailsid } : transaction;
        });
        setLoading(false);
        console.log(updatedTransactions)
        setTransactions(updatedTransactions) ;
        const final = [...updatedTransactions]
        setFinatransactions(final.sort((a,b)=>a.transdetailid - b.transdetailid));
        //GetCsvValues(final)
        //console.log(exportCSV)
        //console.log(bankCSV)
        console.log(transactions) 
        setSuccessMessage('All transactions have been Editted successfully!');
    }
    
}catch (error) {
    console.error("Error submitting transactions:", error);
    setLoading(false);
}
}

    useEffect(() => {
        let sh_total = 0;
        let subs_total = 0;
        let sd_total = 0;
        let bonus_total = 0;
        let br_total = 0;
        let pr_total = 0;
        let ins_total = 0;
        let gst_total = 0;
        let overall_total = 0;
        let overall_deduct = 0;
        let overall_cheque = 0;
        if (exportCSV.length > 0) {
            const arrayedownload = exportCSV.reduce((acc, data) => {
                const existingObjIndex = acc.findIndex(item => item.MNo === data.MNo);
                if (existingObjIndex !== -1) {
                    acc[existingObjIndex].Narration +="+"+data.Narration
                    acc[existingObjIndex].SH_Amt_RM = parseInt(acc[existingObjIndex].SH_Amt_RM )+parseInt(data.SH_Amt_RM)
                    acc[existingObjIndex].SUBS_Amt_RM =parseInt(acc[existingObjIndex].SUBS_Amt_RM) + parseInt(data.SUBS_Amt_RM)
                    acc[existingObjIndex].SD_Amt_RM = parseInt(acc[existingObjIndex].SD_Amt_RM)+parseInt(data.SD_Amt_RM)
                    acc[existingObjIndex].Bonus_Amt_RM = parseInt(acc[existingObjIndex].Bonus_Amt_RM)+parseInt(data.Bonus_Amt_RM)
                    acc[existingObjIndex].BR_Amt_RM = parseInt(acc[existingObjIndex].BR_Amt_RM )+parseInt(data.BR_Amt_RM)
                    acc[existingObjIndex].P_Fee_RM = parseInt(acc[existingObjIndex].P_Fee_RM) +parseInt(data.P_Fee_RM)
                    acc[existingObjIndex].DBS_Insurance_RM = parseInt(acc[existingObjIndex].DBS_Insurance_RM)+parseInt(data.DBS_Insurance_RM)
                    acc[existingObjIndex].Gst_RM = parseInt(acc[existingObjIndex].Gst_RM )+parseInt(data.Gst_RM)
                    acc[existingObjIndex].Total_RM = parseInt( acc[existingObjIndex].Total_RM )+parseInt(data.Total_RM)
                    acc[existingObjIndex].Total_Deduction_RM = parseInt(acc[existingObjIndex].Total_Deduction_RM)+parseInt(data.Total_Deduction_RM)
                    acc[existingObjIndex].Cheque_Amount_RM = parseInt(acc[existingObjIndex].Cheque_Amount_RM )+parseInt(data.Cheque_Amount_RM)
                    if(data.SH_PV_No!=""){
                        acc[existingObjIndex].SH_PV_No = data.SH_PV_No
                    }
                    if(data.SUBS_PV_No!=""){
                        acc[existingObjIndex].SUBS_PV_No = data.SUBS_PV_No
                    }
                    if(data.SD_PV_No!=""){
                        acc[existingObjIndex].SD_PV_No = data.SD_PV_No
                    }
                    if(data.Bonus_PV_No!=""){
                        acc[existingObjIndex].Bonus_PV_No = data.Bonus_PV_No
                    }
                   
                } else {
                    acc.push({ ...data }); 
                }
                sh_total += parseInt(data.SH_Amt_RM)
                subs_total += parseInt(data.SUBS_Amt_RM)
                sd_total += parseInt(data.SD_Amt_RM)
                bonus_total += parseInt(data.Bonus_Amt_RM)
                br_total += parseInt(data.BR_Amt_RM)
                pr_total += parseInt(data.P_Fee_RM)
                ins_total += parseInt(data.DBS_Insurance_RM)
                gst_total += parseInt(data.Gst_RM)
                overall_total += parseInt(data.Total_RM)
                overall_deduct += parseInt(data.Total_Deduction_RM)
                overall_cheque += parseInt(data.Cheque_Amount_RM)
                return acc;
            }, []); 
            let combined_earray = [
                ["KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)"], // String
                ["Withdrawal Bulk List "],
                ["Bulk # :", bulkno],
                [],
                [],
                ["Print Date:", formatTodayDate()],
                [],
                [],
                // Empty row for spacing
                Object.keys(arrayedownload[0]), // Headers
                ...arrayedownload.map(obj => Object.values(obj)),
                [],
                [],
                ["", "", "", "", "", "", "TOTAL:", "", sh_total, "", subs_total, "", bonus_total, "", sd_total, "", br_total, pr_total, ins_total, gst_total, "", "RM" + overall_total + ".00", "RM" + overall_deduct + ".00", "RM" + overall_cheque + ".00"]]
            // Object values
            setBulkCSV(combined_earray)
        }

        if (bankCSV.length > 0) {
            const arraybdownload = bankCSV.reduce((acc, data) => {
                const existingObjIndex = acc.findIndex(item => item.Other_Payment_Details == data.Other_Payment_Details);
                if (existingObjIndex !== -1) {
                    acc[existingObjIndex].Payment_Amount = parseInt(acc[existingObjIndex].Payment_Amount)+parseInt(data.Payment_Amount)
                } else {
                    acc.push({ ...data }); 
                }
                return acc;
            }, []); 
            let combined_barray = [
                [], // String
                [],
                [],
                [],
                [],
                ["", "", "Payment Date", formatTodayDate()],
                [],
                [],
                // Empty row for spacing
                Object.keys(arraybdownload[0]), // Headers
                ...arraybdownload.map(obj => Object.values(obj)), // Object values
            ];
            setBankTempCSV(combined_barray)
        }
    }, [exportCSV, bankCSV])

    const handlebulkexcel = (exportCSV) => {
        const uniqueArr = exportCSV.filter((obj, index, self) =>
            index === self.findIndex((o) => (
                Object.keys(obj).every((key) => obj[key] === o[key])
            ))

        );
        //console.log(uniqueArr)
        setBulkCSV(uniqueArr)
    }

    const handlebankexcel = (bankCSV) => {
        const uniqueArr = bankCSV.filter((obj, index, self) =>
            index === self.findIndex((o) => (
                Object.keys(obj).every((key) => obj[key] === o[key])
            ))

        );
        //console.log(uniqueArr)
        setBankTempCSV(uniqueArr)
    }
    const handleclear = () => {
        setAddTransRestrict(false)
        setdownloadexcel(false)
        setBulkNo('')
        setEditButton(false)
        setTransactions([])
        setBankCSV([])
        setExportCSV([])
        setIsSubmitting(false);

    }
    console.log(bulkCSV)
    console.log(banktempCSV)
    
    const isMobile = useMediaQuery({ maxWidth: 600 })

    // const [bulkColumnVisible, setBulkColumneVisible] = useState([
    //     { field: "transmasterid", header: "Bulk #", sortable: true,  visible: true},
    //     { field: "receiptid", header: "RN", sortable: true,  visible: true},
    //     { field: "memberid", header: "Member #", sortable: true,  visible: true },
    //     { field: "membername", header: "Member Name", sortable: true,  visible: true },
    //     { field: "estate", header: "Estate Name", sortable: true,  visible: true },
    //     { field: "narration", header: "Narration", sortable: true,  visible: true },
    //     { field: "loanid", header: "LN/SD/BR", sortable: true,  visible: true },
    //     { field: "amount", header: "Amt in RM", sortable: true,  visible: true },
    //     // { field: "loanbalance",header:"Loan Bal.", sortable: true,  visible:true,body:decimalloan},
    //     // { field: "sharebalance", header: "Share Bal.", sortable: true,  visible: true ,body:decimalshare},
    //     // { field: "subscriptionbalance", header: "Subscription Bal.", sortable: true,  visible: true,body:decimalsub },
    //     // { field:"transtypeid",header:"TransType", sortable: true, visible:true,body:TransType},
       
        
    // ])

    // const exportBulkColumns = bulkColumnVisible.map((col) => ({ title: col.header, dataKey: col.field }));

    const renderHeader = (column) => {
        return (
            <div >
                <span style={{position:"absolute",right:"30px"}}>{column.sortable}</span>
            </div>
        );
    };

    const inputEditor = (props) => {
        // if((Object.keys(response).length==0 &&props.field=="amount")||(Object.keys(response).length==0 && props.field=="narration")){
        //     // setBorderColor("green")
        //     const inputStyle = {
        //         border: `1px solid ${borderColor}`,
        //         outline: `1px solid ${borderColor}`,
        //         height:"30px",
        //         borderRadius:"4px"
        //       };
        //     return (
        //         <InputText
        //         type={props.field=="amount"?"number":"text"}
        //         //   value={props.value}
        //         style={inputStyle}
        //         onKeyDown={(e)=>handleUpdate(e)}
        //         onChange={(e) => onEditorValueChange({ index: props.rowIndex, field: props.field, value: e.target.value,data:props.props.value[props.rowIndex] })}
        //         />
        //       );}
        //       else {
        //         return <Box>{props.field=="sharebalance"||props.field=="subscriptionbalance"||props.field=="loanbalance"? parseInt(props.value).toFixed(2):props.field=="transtypeid"?props.value==1?"Share":"Subscription":props.value}</Box>;
        //       }
          };

          const PrintData = (rowDate, rowIndex)=>{
            // return(
            //     <Box>
            //         {rowDate.receiptid && <PrintIcon style={{cursor:"pointer"}} onClick={() => handleFetchAndPrintReceipt(rowDate.receiptid, "single")} />}
            //     </Box>
            // )
           }

        useEffect(() => {
            const fetchLastBulkTransData = async()=> {

                try {
                    setLoading(true)
                    const response = await CustomAxios.get(`oasis/last_transactions_payments/?page=${pageNo}`)
                    const {transactions} = response.data
                    setLastBulkTrans(prev=> [...prev,...transactions])
                } catch (error) {
                    console.error("Error fetching banks:", error);
                }
                setLoading(false)
            }
        
            fetchLastBulkTransData()
        }, [pageNo])

    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            // SelectedBulk()
        }
       }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (type) => {
         console.log(type.type)
        if(type.type =="edit"){
            setBulkHeading("Edit Bulk")
            setprintModalButton("Edit")
            setPrintModal(true)
        }
        setAnchorEl(null);
    };

    const handlePage = (e) =>{
        e.preventDefault()
        
        let count = pageNo+1
        setPageNo(count)
        
      }

      const handleEditBulkTransaction = (bulkNum) => {
        setPrintModal(false)
        handleBulkEdit(bulkNum)
        setPaymentModal(true)
      }

    //   const handleBulkDataDownload = async(bulkNum) => {
    //     const response = await fetchBulkTransDetails(bulkNum)
    //     const {transactions} = response.data
    //     setFinatransactions(transactions)
    //   }

    //   const handleBankTemplateDownload = async(bulkNum) => {  
    //     const response = await fetchBulkTransDetails(bulkNum)
    //     const {transactions} = response.data
    //     setFinatransactions(transactions)
    //   }

    const paginatorLeft = () => {
        return (
                <Button onClick={(e)=>handlePage(e) } sx={{width:"289px"}}>Load more</Button>
        );
    };
    const paginatorRight = () => {
        return (
            <Box sx={{width:"289px"}}></Box>
        );
    };

    return (
        <Box sx={{backgroundColor:isMobile&&"#f2f6fc",height:isMobile?"100vh":"calc(100vh - 84px)",width:"100%",position:"relative",overflow:"auto"}}>
            {/* <LinkHeader /> */}
            {isLoading ?
                <Stack rowGap="10px" sx={{ width: "100%", height: "87vh", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Please Wait</Typography>
                    <ProgressBar mode="indeterminate" style={{ width: "25%", height: '6px' }} />
                </Stack> : !dialogOpen?
                <Box>
                <Box sx={{position:"relative"}}>
               {successMessage && <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #288d2c",background:"#DCFCE7",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon sx={{width:"24px",height:"24px"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#16A34A"}}>New Payment added successfully</Typography>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#16A34A"}}>Bulk Number <b>{bulkno}</b> and RN has been generated for the Payment.</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setSuccessMessage(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
               </Box>}
               </Box>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"300px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <PaymentsIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                     <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Payment Transactions</Typography>
                </Box>
                <Button onClick={openDialogToAdd} variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}} startIcon={<PaidOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>}> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Payments</Typography></Button>
            </Box>
          </Box>
          <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px",alignItems:"center",overflowX:"auto"}}>
               <Box sx={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                     <Box className="p-input-icon-left">
                            <i className="pi pi-search" style={{color:"#64748B",zIndex:"2"}}/>
                            <Dropdown /*value={bulkno} onChange={(e) => setBulkNo(e.target.value)}*/ onKeyUp={(e)=>handleEnter(e)} options={estateList} editable placeholder="Bulk #" style={{height:"48px",width:"400px",fontSize:"16px",color:"#64748B",paddingLeft:"30px"}}/>
                            {/* <InputText style={{height:"48px",width:"400px",fontSize:"16px",color:"#64748B"}} placeholder='Bulk # / Estate Name'  value={bulkNo} onChange={(e) => setBulkNo(e.target.value)}></InputText> */}
                        </Box>
                     <Box >
                          <IconButton sx={{display:"flex"}} onClick={handleMenuClick}> <MoreHorizIcon/></IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{marginRight:"20px",padding:"0px"}}
                        >
                            {/* Menu options */}
                            <MenuItem sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} onClick={()=>handleMenuClose({type:"edit"})}>Edit</MenuItem>
                        </Menu>
                     </Box>
               </Box>
               <Box sx={{width:"100%"}}>
               <DataTable value={lastBulkTrans}  rowGroupMode="rowspan" groupRowsBy="remarks" rows={7} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} dataKey="receiptid" style={{ backgroundColor: "#F1F5F9",position:"relative" }} scrollable   removableSort loading={isLoading} paginator 
                      emptyMessage="No Bulks found." >
                      {/* <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1}></Column> */}
                      {columnVisibleTransactions.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={{ position:"relative",minWidth: "250px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={renderHeader(col)} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                      <Column header ='Actions' body={(rowData,rowIndex) => <IconButton onClick={() => openDialogToAdd(rowIndex.rowIndex)}><EditIcon /></IconButton>} />
                      <Column header ='Print' body={(rowData) => rowData.transdetailsid?<PrintIcon style={{ cursor: "pointer" }} onClick={() => handleFetchAndPrintReceipt(rowData.transdetailsid, "single")} />:<div>-</div>} />
                      <Column header="Remove" body={(rowData,rowIndex) => <IconButton onClick={() => onRemove(rowIndex.rowIndex)}><RemoveIcon /></IconButton>} />
                  </DataTable>
              </Box>
              <Box sx={{position:"absolute",top:"50%",left:"50%"}}>
                        <Dialog visible={paymentModal} style={{maxWidth:"1500px", height: "90vh", width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false} >
                        
                                <Box sx={{height:"calc(90vh - 160px)", padding:"30px",display:"flex",flexDirection:"column",gap:"24px",background:"#f2f6fc",overflow: "auto"}}>

                                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <PaidIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Edit Bulk Transaction</Typography>
                                        </Box>
                                </Box>
                            
                                </Box>
                                <DataTable value={transactions}  rowGroupMode="rowspan" groupRowsBy="transmasterid" rows={7} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} dataKey="receiptid" style={{ backgroundColor: "#F1F5F9",position:"relative" }} scrollable   removableSort loading={isLoading} paginator 
                                emptyMessage="No Bulks found." >
                                {/* <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1}></Column> */}
                                {columnVisibleTransactions.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={{ position:"relative",minWidth: "250px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={renderHeader(col)} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                <Column header ='Actions' body={(rowData,rowIndex) => <IconButton onClick={() => openDialogToAdd(rowIndex.rowIndex)}><EditIcon /></IconButton>} />
                                <Column header ='Print' body={(rowData) => rowData.transdetailsid?<PrintIcon style={{ cursor: "pointer" }} onClick={() => handleFetchAndPrintReceipt(rowData.transdetailsid, "single")} />:<div>-</div>} />
                                <Column header="Remove" body={(rowData,rowIndex) => <IconButton onClick={() => onRemove(rowIndex.rowIndex)}><RemoveIcon /></IconButton>} />
                                </DataTable>
                                {/* <Box sx={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                                    <Button onClick={() => handleCancelANP("ANP")} sx={{height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c"}} variant="contained" startIcon={<PaidOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/>}>                                    
                                    <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>Add New Payment</Typography>
                                    </Button>
                                </Box>
                                </Box>
                                
                                <Box sx={{position:"sticky",bottom:0}}>
                    <Box sx={{display:"flex",height:"64px",justifyContent:"space-between",padding:"16px 42px 16px 30px",background:"#E2E8F0"}}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Typography sx={{alignSelf: "center", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Total Bulk amount</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px", color: "#64748B" }}>RM: {editedTransaction?.prFee > 0 || editedTransaction?.insurance > 0?(parseFloat(editedTransaction?.prFee) + parseFloat(editedTransaction?.insurance)).toFixed(2):"0.00"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "12px" }}>
                        <Typography sx={{alignSelf: "center", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#101010" }}>Total Payment:</Typography>
                        <Typography sx={{ fontFamily: "inter", fontSize: "24px", fontStyle: "inter", fontWeight: "800", lineHeight: "32px", color: "#EF4444" }}>
                        RM: {
                                        (
                                            parseFloat(sharetrans) +
                                            parseFloat(subscriptionTrans) +
                                            parseFloat(specificDepositTrans) +
                                            parseFloat(bonusTrans) +
                                            (deductFrom === "ShareDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SubscriptionDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "SpecificDepositDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0) +
                                            (deductFrom === "BonusDeduct" ? (editedTransaction.insurance !== '' ? parseFloat(editedTransaction.insurance) : 0) + parseFloat(editedTransaction.prFee) : 0)
                                        ).toFixed(2)
                                    }
                        </Typography>
                    </Box>
                 </Box> */}
                 <Box sx={{height:"96px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"24px 30px",background:"#F1F5F9"}}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        <Button onClick={()=> setPaymentModal(false)} sx={{width: "178px", height: "48px", borderRadius: "4px", padding: "12px"}} startIcon={<ClearIcon sx={{ color: "#64748B", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Cancel</Typography>
                        </Button>
                        <Button sx={{height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c"}} variant="contained" startIcon={<AddIcon sx={{ color: "#FFFFFF", height: "24px", width: "24px" }} />}>
                        <Typography sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF" }}>Edit All Transactions</Typography>
                        </Button>
                    </Box>
                              
                </Box>  
                 </Box>
               
                            </Dialog>
                            </Box>
              <Dialog visible={printModal} style={{maxWidth:"553px",width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false}>
                <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                     <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#000000"}}>{bulkHeading}</Typography>
                     <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",color:"#101010"}}>Bulk Number  <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                        <InputText
                            value={searchBulkNo}
                            placeholder="Bulk Number"
                            onChange={(e)=>setSearchBulkNo(e.target.value)}
                            style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                        />
                     </Box>
                </Box>
                     <Box sx={{display:"flex",justifyContent:"center",height:"96px",padding:"24px 30px",background:"#F1F5F9"}}>
                            <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                                <Button onClick={()=>setPrintModal(false)} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Cancel</Typography></Button>
                                <button onClick={() => handleEditBulkTransaction(searchBulkNo)} variant="contained" style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: "pointer" }}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}}>{printModalButton}</Typography>
                                </button>
                            </Box>
                     </Box>
              </Dialog>
          </Box>
            </Box>
                :
                <TransactionModal
                open={dialogOpen}
                transaction={currentTransaction}
                onChange={handleTransactionChange}
                onSave={saveTransaction}
                onClose={closeDialog}
                getMemberDetails={getMemberDetails}
                banks={banks}
                transInTable={transactions}
                transactionTypes={transactionTypes}
                loan={loanList}
                Guarantor={guarantorList}
                SDlist={specificList}
                guarantorForList={guarantorForList}
                transactions={transactions} 
                openDialogToAdd={openDialogToAdd} 
                onRemove={onRemove} 
                handleFetchAndPrintReceipt={handleFetchAndPrintReceipt}
                paymentOpen={paymentModal} 
                setPaymentOpen={setPaymentModal} 
                setTransactions={setTransactions}
                submitAllTransactions ={handleSubmitAllTransactions}
                formatDateTransDate = {formatDateTransDate}
                bulkNum = {bulkno}
                bulkCSV = {bulkCSV}
                banktempCSV = {banktempCSV}
                handlePrintAll = {handlePrintAll}
                setBulkNo = {setBulkNo}
                columnVisibleTransactions={columnVisibleTransactions}
            />}
        </Box>
    );
};
