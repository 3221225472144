import { configureStore, combineReducers } from "@reduxjs/toolkit";
import IntialReducer from "./executiveReducer";
import ToggleBarReducer from "./toggleBarReducer";
import userDataReducer from "./userDetails"

const rootReducer = combineReducers({
    // Add your reducers here
    executive: IntialReducer,
    toggleBar: ToggleBarReducer,
    userData:userDataReducer
  });

const store = configureStore({
    reducer:rootReducer,
});

export default store;