import { createSlice } from "@reduxjs/toolkit";
const userDataReducer = {
    member:{}

    
};

const IntialReducer = createSlice({
    name: "userDatails",
    initialState:userDataReducer,
    reducers:{

        updateUser:(state, action) => {
            console.log(action.payload)
            state.member = action.payload
        },
    }
})

export const {updateUser}=IntialReducer.actions

export default IntialReducer.reducer;