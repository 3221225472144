import { Logout, PersonPinCircle } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useRef,useState,useEffect} from"react"
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { updateSidebar } from "../redux/executiveReducer";
import TransLogo from "../assets/KSKB_Logo_TransparentBG.PNG"
import "../styles/linkheader.css"

export const LinkHeader = () => {
    const navigate=useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    }; 
    const pathsWithNavBar = ["/add_member","/executive_summary","/Receipts"];
    // const [pageName,setPageName] = useState('')
    // useEffect(()=>{
    //     switch(location.pathname){
    //         case "/specificDeposit":
    //             setPageName("Specific Deposit Issurance")
    //             break;
    //         case "/payments":
    //             setPageName("Payments Update Information")
    //             break;
    //         case "/Receipts":
    //             setPageName("Receipt Master Information")
    //             break;
    //         case "/executive_summary":
    //             setPageName("Executive Summary")
    //             break;
    //         case "/loan":
    //             setPageName("Loan")
    //             break
    //         case "/account-statement":
    //             setPageName("Account Statement")
    //             break;
    //     }
    // },[])
    return (
        <Box sx={{ background: 'var(--pallate-green-500, #288d2c)', padding: "7px 5px", display: "flex", alignItems: "center", justifyContent: "space-between",position:"sticky",top:0,zIndex:2 }}>
           
            <Box sx={{display:"flex",gap:"24px",alignItems:"center"}}>
                <IconButton onClick={()=>dispatch(updateSidebar(true))}><MenuIcon sx={{color:"#FFF"}}/></IconButton>
                <Box component="img" sx={{width:"42px", height:"42px"}} src={TransLogo}></Box>
                <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px", color: "#fff" }}>KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)</Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "centr", gap: "16px" }}>
                {!pathsWithNavBar.includes(location.pathname) &&<> 
                    <Button component={Link} to="/account-statement" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<AccountBalanceIcon />} >Account Statement</Button>
                    </>}
                <Tooltip title="Logout" arrow>
                    <Button
                        sx={{ color: "#fff" }}
                        onClick={handleLogout}
                    >
                        <Logout />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    )
}
