import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#288d2c',
    },
    secondary: {
        main: '#64748B',
    },
    link: {
        // default: '#288d2c',
        // hover: '#455A64',
        // active: '#263238',
        secondary: '#64748B', // Secondary link color
    },
  },
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-disabled': {
    //         color: 'orange'
    //       }
    //     }
    //   }
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: 35,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-input': {
            padding: '8px 14px',
            color: '#333333', // Label color #74ace5(blue) #44a348(Green) #288d2c(green) #007eff(initial blue) 49a76b
            fontWeight: 'bold', // Bold label for better visibility
          },
          
          '& .MuiInputLabel-root': {
            top: '50%',
            transform: 'translate(14px, -12px) scale(1)',
            transition: 'all 0.2s linear',
            marginLeft: '4px',
            pointerEvents: 'none',
            transition: 'all 0.2s linear',
            color: '#837a7f', // Label color
            // fontWeight: 'bold', // Bold label for better visibility
          },
          '& .MuiInputLabel-shrink': {
            top: 0,
            transform: 'translate(14px, -8px) scale(0.70)',
            transition: 'all 0.2s linear',
            maxWidth: '75%',
            color: '#288d2c'
          },
          '& .MuiOutlinedInput-root .MuiInputBase-input::placeholder': {
            color: '#FFF', // Global placeholder color
            opacity: 1, // To ensure placeholder color is applied
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            // transition: 'all 0.2s linear',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline' : {
            borderColor: '#288d2c',
          },
          '& .MuiInputLabel-outlined.Mui-focused' : {
            color:"#288d2c"
          },
          // .css-1lhszso-MuiFormLabel-root-MuiInputLabel-root.Mui-focused
          // MuiInputLabel-outlined

          // '& .MuiInputLabel-root': {
          //   top: '50%',
          //   transform: 'translateY(-50%) scale(1)',
          //   marginLeft: '4px',
          //   pointerEvents: 'none',
          //   transition: 'color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          //   // transition: 'all 0.2s ease-out, transform 0.2s ease-out',
          // },
          // '& .MuiInputLabel-shrink': {
          //   top: 0,
          //   transform: 'translate(9px, -9px) scale(0.75)',
          //   transition: 'color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          //   marginLeft: '4px',
          // },
          // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //   borderColor: 'rgba(0, 0, 0, 0.87)',
          // },
          // '& .MuiOutlinedInput-notchedOutline': {
          //   // transition: 'border-color 0.2s ease-out, border-width 0.2s ease-out',
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#288d2c',
            color: '#FFFFFF',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em',
            padding:'12px'
          },
        },
      },
    },
    MuiLink: {
        styleOverrides: {
          root: {
            color: '#288d2c',
            textDecoration: 'none'
          },
          secondary: {
            color: '#64748B', // Secondary link color
            textDecoration: 'none'
          },
        },
    },
  },
});

export default theme;