import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggleBarActiveTab: 'dashboard',
    isExpanded: false,
    memberid:"",
    key:false,
    trans:"",
    loansel:[],
};

const ToggleBarReducer = createSlice({
    name: "ToggleBarReducer",
    initialState: initialState,
    reducers: {
        updateToggleBarActiveTab: (state, { type, payload }) => {
            state.toggleBarActiveTab = payload
        },
        updateIsExpanded: (state, { type, payload }) => {
            state.isExpanded = payload
        },
        updateKeyIn: (state, { payload }) => {
            state.key = payload
        },
        updateMemberid: (state, { payload }) => {
            state.memberid = payload
        },
        updateTrans: (state, { payload }) => {
            state.trans = payload
        },
        updateLoansel: (state, { payload }) => {
            state.loansel = payload
        },
    }

})
export const { updateToggleBarActiveTab, updateIsExpanded ,updateKeyIn ,updateMemberid, updateTrans, updateLoansel
} = ToggleBarReducer.actions

export default ToggleBarReducer.reducer;