import React from "react";
import { DatePicker } from "@mui/x-date-pickers-pro";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const MuiDatePicker = ({
  name,
  label,
  disabled = false,
  required,
  value,
  onChange,
  format="DD-MM-YYYY",
  disableFuture = true,
  sx = {},
  style={},
  isSubmitted=false,
  validationErrMsg="",
}) => {

  const defaultStyles = {
    backgroundColor: "#FFF",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx,
  };

  return (
    <FormControl fullWidth>
    <DatePicker
      label={label}
      name={name}
      disabled={disabled}
      disableFuture={disableFuture}
      required={required}
      value={value}
      format={format}
      onChange={onChange}
      views={['year', 'month', 'day']}
      sx={defaultStyles}
      style={style}
    />
    {isSubmitted && validationErrMsg && <FormHelperText
      style={{ minHeight: '1em', fontFamily: "inter",fontSize: "16px",fontStyle: "normal",fontWeight: "400",lineHeight: "24px", }} // Ensure consistent height
      error={isSubmitted && validationErrMsg}
       >
      {validationErrMsg}
      </FormHelperText>}
      </FormControl>
  );
};

export default MuiDatePicker;