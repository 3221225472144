import React from "react";
import {Button, Typography} from "@mui/material"

const Mui_Button = ({
            type="button",
            variant, 
            onClick,
            value,
            icon,
            disabled,
            btnsx={}, 
            typosx={},
            iconsx={}
        }) =>{

    return (
      <>
        <Button
          fullWidth
          type={type}
          variant={variant}
          disabled={disabled}
        //   color="primary"
          sx={{
            width: "165px",
            height: "36px",
            borderRadius: "4px",
            display: "flex",
            gap: "6px",
            ...btnsx
          }}
          onClick={onClick}
        >
        {icon &&
        React.cloneElement(icon, {
          sx: {
            height: "22px",
            color: "#FFFFFF",
            ...iconsx
          },
        })}
      
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              textTransform: "none",
              ...typosx
            }}
          >
           {value}
          </Typography>
        </Button>
      </>
    );
}

export default Mui_Button;