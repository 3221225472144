
import React, { useEffect, useState } from "react";
import { PrimeReactProvider } from 'primereact/api';
import { Box, Typography, Grid, Stack } from "@mui/material";
import { DashboardCustomizeOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';

import '../styles/brandingStyles/branding.css';
import '../styles/pageStyles/dashboard.css';
import ShareBalIcon from '../assets/dashboard/share.png';
import SubsBalIcon from '../assets/dashboard/subscription.png';
import loanBalIcon from '../assets/dashboard/loan.png';
import SdBalIcon from '../assets/dashboard/sd.png';
import BonusBalIcon from '../assets/dashboard/Bonus.png';
import Sidebar from "../components/sidebar";
import { useSelector } from 'react-redux';

import axios from 'axios';

import { Chart } from 'primereact/chart';
import { Calendar } from "primereact/calendar";
        import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import Header from '../components/header';
import userDetails from "../redux/userDetails";
import { LinkHeader } from "../components/linkHeader";
import CustomAxios from "../utils/CustomAxios";

export const Dashboard = () => {
    // let api_baseUrl = `${process.env.REACT_APP_OASIS_BE_URL}oasis/`
    // let api_baseUrl = "http://127.0.0.1:8000/oasis/"
    const isExpanded = useSelector(state=>state.executive.isExpanded);
    const isMobile = useMediaQuery({ maxWidth: 600 });
    //console.log("isExpanded in dashboard page", isExpanded);
    const role = localStorage.getItem('role')
    let user_details
    if(role!=="member"){
        user_details=JSON.parse(window.sessionStorage.getItem('search_memberid'))
    }
    const [memberId, setMemberId] = useState(null);
    const [memberName, setMemberName] = useState(null);
    const [memberBalance, setMemberBalance] = useState([]);
    const [shareYear,setShareYear] = useState(new Date())
    const [subYear,setSubYear] = useState(new Date())
    const [shareChartData,setShareChartData] = useState("")
    const [subChartData,setSubChartData] = useState("")
    const[error,setError] = useState("")

    const UpdateShareChartData = (res_data)=>{
        let share_keys = Object.keys(res_data.share_yearly)
        let share_values = Object.values(res_data.share_yearly)
        setShareChartData({
            labels:share_keys,
            datasets:[
                {
                    data:share_values,
                    backgroundColor: [
                        "#288d2c"
                    ],
                    borderColor: [
                        "#288d2c"
                    ],
                    borderWidth: 1,
                }
            ]
        })
    }

    const UpdateSubChartData = (res_data)=>{
        let sub_keys = Object.keys(res_data.subscription_yearly)
        let sub_values = Object.values(res_data.subscription_yearly)
        setSubChartData({
            labels:sub_keys,
            datasets:[
                {
                    data:sub_values,
                    backgroundColor: [
                        "#FB923C"
                    ],
                    borderColor: [
                        "#FB923C"
                    ],
                    borderWidth: 1,
                    // borderRadius:20
                }
            ]
        })
    }

    const shareSubYearlyApi = async(id,transtype = "share_sub_yearly",year=new Date().getFullYear())=>{
        CustomAxios.post('oasis/member_dashboard/',{
            memberid:id,
            transtype:transtype,
            year:year
        }).then((data)=>{
            console.log("inside")
            let res_data = data.data
            setMemberBalance(res_data);
            let yearly_data = res_data.data
            //console.log(res_data,transtype)
            if(transtype === "share_sub_yearly"){
                UpdateShareChartData(yearly_data)
                UpdateSubChartData(yearly_data)
            }
            else if(transtype === "share_yearly"){
                UpdateShareChartData({share_yearly:yearly_data})
            }
            else{
                UpdateSubChartData({subscription_yearly:yearly_data})
            }
            res_data.share_last.transtype="Share"
            res_data.subscription_last.transtype="Subscription"
            res_data.loan_last.transtype="Loan" 
            
        }).catch((err)=>{
            console.error(err)
            setError("Sorry For The Inconvenience")
        }
        )
    }

    const [lastTransaction, setLastTransaction] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        //console.log("getting memberid from ls--------------------------", localStorage.getItem('memberid'));

        // testing member id hardcoded
        let memberid 
        if(role=="member"){
         memberid = parseInt(localStorage.getItem('memberid'));
        setMemberName(localStorage.getItem('username'));
        }
        if(role!=="member"){
            memberid = user_details.search_memberid
        }
       

        // const payload = {
        //     // memberid: memberid,
        //     memberid: 487,
        //     year:2024,
        //     transtype:"share_sub_yearly"
        //     // password: password
        // };

        // Send data to the API endpoint using Axios (replace with your actual API endpoint)
        // axios.post(api_baseUrl+'member_dashboard/', payload)
        //   .then(response => {
        //     // Handle API response as needed
        //     console.log('API Response:', response);
        //     const responseData = response.data;
        //     console.log("ResponseData", responseData);
         
            //  console.log(responseData)
        //     setMemberBalance(responseData);
        //     // setResetLinkSent(true)
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
        
        shareSubYearlyApi(memberid)

    },[])
    
    useEffect(()=>{
       
        let last =[]
    if(memberBalance.length>0 || Object.keys(memberBalance).length>0){
        if(Object.keys(memberBalance.share_last).length>1)last.push(memberBalance.share_last)
        if(Object.keys(memberBalance.subscription_last).length>1)last.push(memberBalance.subscription_last)
        if(Object.keys(memberBalance.loan_last).length>1)last.push(memberBalance.loan_last)
    //console.log(last.length)
    setLastTransaction(last)
}
setLoading(false);
    },[memberBalance])
  
    // const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
    // const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
    // const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    // const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
    // const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
    const options = {
        
        scales: {
            x:{
                // grid:{
                //     display:false
                // }
            },
            y: {
                beginAtZero: true,
                grace:1,
                // grid:{
                //     display:false
                // }
            }
        },
        plugins:{
            responsive:true,
            maintainAspectRatio:false,
            legend:{
                display:false
            }
        }

    };    
    const dateOfJoinBody=(rowData)=>{
        //console.log(rowData)

        const originalDate = new Date(rowData.transdate);
        //console.log(originalDate)
        const formattedDate = originalDate.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          });
        // const formattedDate = `${month} ${day},${year}`

        return formattedDate;
    }
    const numberFormate = (rowData) =>{
        return parseInt(rowData.transamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    const [columnVisible, setColumneVisible] = useState([
        { field: "transtype", header: "Transaction type", sortable: true, visible: true},
        { field: "transamount", sortable: true, header: "Amount in RM", filter: true,  visible: true,body:numberFormate},
        { field: "transdate", sortable: true, filter: true, header: "Date",  visible: true,body:dateOfJoinBody},
        { field: "bankname",  sortable: true, header: "Bank Name",  visible: true},
        // { field: "doj", sortable: true, header: "Deposit type",   visible: true,frozen:false},
        { field: "chequeno", sortable: true, header: "Cheq No", visible: true},
    ])

    const handleLogout = () => {
        // Handle logout logic here
        //console.log('Logout clicked');
    
        // Clear localStorage
        localStorage.clear();
    
        // Additional logout logic if needed
    };
    
    
    return(
        <PrimeReactProvider>
            <Box sx={[isMobile&&{p:"32px 0px 58px 0px"}]}>
                {role=="member"&&<Header title="Koperasi" />}
                {role!=="member"&&<LinkHeader title="Koperasi" />}
                <Box sx={{display: {xs:"block",md:"flex"}}} >
                    {!isMobile&&<Sidebar />}
                    <Box sx={{display:"flex",flexDirection:"column",overflow:"auto"}}>
                    <Box sx={{px:"16px", width:"100%"}}>
                        <Box sx={{ display: "flex",alignItems:"center",mb:3,my:{xs:"20px"},gap:"8px" }} >
                            <DashboardCustomizeOutlined sx={{width:{xs:"24px",md:"40px"},height:{xs:"24px",md:"40px"},color:"#288d2c" }}/>
                            <Box className="fn-h2 fw-500" sx={{fontSize : {xs:"24px",md:"30px"}}}>Dashboard</Box> 
                        </Box>
                        <Box sx={{mb:3}}>
                      {role == "member"&&<Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"24px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:isMobile?"500":700,lineHeight:isMobile?"32px":"40px"}}>Hi, <span style={{color:"var(--Pallate-green-500, #288d2c)",alignSelf:"stretch",fontSize:isMobile?"24px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:isMobile?"500":700,lineHeight:isMobile?"32px":"40px"}}>{memberName}</span><span style={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"24px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:isMobile?"500":"400",lineHeight:isMobile?"32px":"40px"}}><span style={{fontWeight:isMobile?"500":"700"}}>!</span> Welcome Back.</span></Typography> } 
                      {role!=="member"&&<Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"24px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:isMobile?"500":700,lineHeight:isMobile?"32px":"40px"}}>Member Id : {user_details.search_memberid}</Typography>     }
                      
                        </Box>
                        <Stack rowGap={{xs:"20px",md:"36px"}}>

                            <Box className="scroll"  sx={{display:"flex",flexWrap:"nowrap",overflow:"auto",gap:"20px",alignSelf:"stretch"}}>
                                    <Box className="balCard" sx={{minWidth:isMobile?"156px":"200px",flexShrink:0,height:isMobile?"144px":"100%",display:"flex",alignSelf:"stretch",flexDirection:"column",alignItems:"flex-start",flex:isMobile?null:"1 0 0"}} >
                                        <Box sx={{display:"flex", alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row",gap:"12px"}}>
                                            <Box component='img' src={ShareBalIcon} sx={{height:"36px",width:"36px"}}/>
                                            <Typography sx={{display:"flex",alignItems:"center",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"12px":"16px",fontStyle:"normal",fontFamily:"inter",fontWeight:"400",lineHeight:isMobile?"16px":"24px"}}>Share Balance</Typography>

                                        </Box>
                                        <Box className="fn-h1">
                                            <Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"16px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:"500",lineHeight:isMobile?"24px":"40px"}}>RM  <span style={{fontWeight:isMobile?"500":"400"}}>{memberBalance.share?parseInt(memberBalance.share).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}</span></Typography>
                                        </Box>
                                    </Box>
                                <Box className="balCard" sx={{minWidth:isMobile?"156px":"200px",flexShrink:0,height:isMobile?"144px":"100%",display:"flex",alignSelf:"stretch",flexDirection:"column",alignItems:"flex-start",flex:isMobile?null:"1 0 0"}} >
                                        <Box sx={{display:"flex", alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row",gap:"12px"}}>
                                            <Box component='img' src={SubsBalIcon} sx={{height:"36px",width:"36px"}}/>
                                            <Typography sx={{display:"flex",alignItems:"center",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"12px":"16px",fontStyle:"normal",fontFamily:"inter",fontWeight:"400",lineHeight:isMobile?"16px":"24px"}}>Subscription Balance</Typography>

                                        </Box>
                                        <Box className="fn-h1">
                                        <Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"16px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:"500",lineHeight:isMobile?"24px":"40px"}}>RM <span style={{fontWeight:isMobile?"500":"400"}}>{memberBalance.subscription?parseInt(memberBalance.subscription).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}</span></Typography>

                                        </Box>
                                    </Box>
                                <Box className="balCard" sx={{minWidth:isMobile?"156px":"200px",flexShrink:0,height:isMobile?"144px":"100%",display:"flex",alignSelf:"stretch",flexDirection:"column",alignItems:"flex-start",flex:isMobile?null:"1 0 0"}} >
                                        <Box sx={{display:"flex", alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row",gap:"12px"}}>
                                            <Box component='img' src={loanBalIcon} sx={{height:"36px",width:"36px"}}/>
                                            <Typography sx={{display:"flex",alignItems:"center",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"12px":"16px",fontStyle:"normal",fontFamily:"inter",fontWeight:"400",lineHeight:isMobile?"16px":"24px"}}>Loan Balance</Typography>
                                        </Box>
                                        <Box className="fn-h1">
                                            <Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"16px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:"500",lineHeight:isMobile?"24px":"40px"}}>RM  <span style={{fontWeight:isMobile?"500":"400"}}>{memberBalance.loanbalance?parseInt(memberBalance.loanbalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}</span></Typography>
                                        </Box>
                                    </Box>
                                <Box className="balCard" sx={{minWidth:isMobile?"156px":"200px",flexShrink:0,height:isMobile?"144px":"100%",display:"flex",alignSelf:"stretch",flexDirection:"column",alignItems:"flex-start",flex:isMobile?null:"1 0 0"}} >
                                        <Box sx={{display:"flex", alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row",gap:"12px"}}>
                                            <Box component='img' src={SdBalIcon} sx={{height:"36px",width:"36px"}}/>
                                            <Typography sx={{display:"flex",alignItems:"center",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"12px":"16px",fontStyle:"normal",fontFamily:"inter",fontWeight:"400",lineHeight:isMobile?"16px":"24px"}}>Specific Balance</Typography>
                                        </Box>
                                        <Box className="fn-h1">
                                        <Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"16px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:"500",lineHeight:isMobile?"24px":"40px"}}>RM  <span style={{fontWeight:isMobile?"500":"400"}}>{memberBalance.specific_deposit?parseInt(memberBalance.specific_deposit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}</span></Typography>
                                        </Box>
                                    </Box>
                                <Box className="balCard" sx={{minWidth:isMobile?"156px":"200px",flexShrink:0,height:isMobile?"144px":"100%",display:"flex",alignSelf:"stretch",flexDirection:"column",alignItems:"flex-start",flex:isMobile?null:"1 0 0"}} >
                                        <Box sx={{display:"flex", alignItems:isMobile?"start":"center",flexDirection:isMobile?"column":"row",gap:"12px"}}>
                                            <Box component='img' src={BonusBalIcon} sx={{height:"36px",width:"36px"}}/>
                                            <Typography sx={{display:"flex",alignItems:"center",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"12px":"16px",fontStyle:"normal",fontFamily:"inter",fontWeight:"400",lineHeight:isMobile?"16px":"24px"}}>Bonus</Typography>
                                        </Box>
                                        <Box className="fn-h1">
                                        <Typography sx={{color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:isMobile?"16px":"36px",fontStyle:"normal",fontFamily:"inter",fontWeight:"500",lineHeight:isMobile?"24px":"40px"}}>RM  <span style={{fontWeight:isMobile?"500":"400"}}>{memberBalance.bonus?parseInt(memberBalance.bonus).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'):(0).toFixed(2)}</span></Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            <Grid container spacing={{xs:"20px",md:"18px"}} >
                                <Grid item xs={12} md={6} lg={6} >
                                    <Stack rowGap="24px">
                                        <Grid container spacing={{xs:"12px"}} sx={{alignItems:"center"}}>
                                            <Grid item xs={12} sm={7} sx={{p:0}}>
                                                <Typography sx={{color:"#000",fontFamily:"Inter",fontSize:{xs:"16px",md:"18px"},fontStyle:"normal",fontWeight:600,lineHeight:{xs:"24px",md:"28px"}}}>Share Transaction Report</Typography>
                                            </Grid>
                                            <Grid item xs={8} sm={5}>
                                                <Calendar id="share-cal" className="p-inputgroup" 
                                                appendTo="self"
                                                showIcon
                                                value={shareYear} onChange={(e) => shareSubYearlyApi(memberId,"share_yearly",e.value.getFullYear())} readOnlyInput 
                                                view="year"
                                                dateFormat="yy" />
                                            </Grid>
                                        </Grid>
                                        <Chart type="bar" data={shareChartData} options={options} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} >
                                    <Stack rowGap="24px">
                                        <Grid container spacing={{xs:"12px"}} sx={{alignItems:"center"}}>
                                            <Grid item  xs={12} sm={7} >
                                                <Typography sx={{color:"#000",fontFamily:"Inter",fontSize:{xs:"16px",md:"18px"},fontStyle:"normal",fontWeight:600,lineHeight:{xs:"24px",md:"28px"}}}>Subscription Transaction Report</Typography>
                                            </Grid>
                                            <Grid item xs={8} sm={5} >
                                                <Calendar id="sub-cal" appendTo="self" className="p-inputgroup" showIcon
                                                value={subYear} onChange={(e) => shareSubYearlyApi(memberId,"subscription_yearly",e.value.getFullYear())} readOnlyInput
                                                view="year" dateFormat="yy" />
                                            </Grid>
                                        </Grid> 
                                        <Chart type="bar" data={subChartData} options={options} />
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Stack>
                    </Box>
                    <Box sx={{ marginTop: {xs:"20px",md:"24px"},px:2 }}>
                        <Box>
                            <Box className="fn-h4 fw-600" sx={{fontSize:{xs:"16px"},fontWeight:{xs:600},lineHeight:{xs:"24px"}}}>Last Transactions</Box>
                        </Box>
                        <div className="card" style={{marginTop: "24px"}}>
                        <DataTable value={lastTransaction}  rows={10}  style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px"  loading={loading} columnResizeMode="expand" removableSort resizableColumns 
                              filterIcon={false}  emptyMessage="No customers found." >
                               
                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{width:"500px"}} field={col.field} filterElement={col.filterElement}  header={col.header} sortable={col.sortable} body={col.body}  alignFrozen="left" filterField={col.filterField}   filterPlaceholder={col.filterPlaceholder} frozen={col.frozen}  />)}
                            </DataTable>
                        </div>
                    </Box>
                    </Box>
                </Box>
                  
                <Typography sx={{ alignSelf: "center", justifySelf: "center", display: "flex", gap: "16px", alignItems: "center", justifyContent: "center", marginLeft: "30px", color: "red", padding: "10px"}}>
{error}
</Typography>

            </Box>
        </PrimeReactProvider>
    )
}