
import { Box, Typography, Button } from "@mui/material"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { InputText } from "primereact/inputtext";
import { Form } from "./form";
import { Dialog } from 'primereact/dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { updateModalOpen, } from "../redux/executiveReducer";
import { useDispatch } from "react-redux";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CSVLink } from 'react-csv'
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { Toast } from 'primereact/toast';

const NomineeStatus = () => {
    const NominiStatusData = useSelector(state => state.executive.NomineeStatusData)
    const NomineeType = useSelector(state => state.executive.NomineeType)
    const [visible, setVisible] = useState(false);
    const [filterState, setFilterState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null)
    const [globalFilterFields, setGlobalFilterFields] = useState(["memberid", "membername", "shareBalance"])
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const [arrangeTable, setArrangeTable] = useState(false)
    const dispatch = useDispatch()
    const [exportCSV, setExportCSV] = useState([])
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedColumn, setSelectedColumn] = useState([])
    const [formattedData, setFormattedData] = useState([])
    const [expandedRows, setExpandedRows] = useState(null);
    const formPayload = useSelector(state => state.executive.formPayload)
    const toast = useRef(null);
    const dt = useRef(null);
    const isSidebarOpen = useSelector((state) => state.executive.open);
    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };


    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" />;
    };

    const dateOfJoinBody = (rowData) => {
        const originalDate = new Date(rowData);
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formattedDate = `${month}/${day}/${year}`

        return formattedDate;
    }
    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumneVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }

    const [columnVisible, setColumneVisible] = useState([])
    // useEffect(() => {
    //     // newTable()
    //     console.log("---inside NM", formattedData)
    // }, [NominiStatusData])
    const decimalshare = (rowData) => {

        if (isNaN(rowData.sharebalance) || rowData.sharebalance == null)
            return "0.00";
        
        return parseFloat(rowData.sharebalance).toFixed(2)
    }
    useEffect(() => {
        if (formPayload['NS'].NomineeStatus == "withNominee") {
            //console.log(formattedData)
            setColumneVisible(
                [
                    // {  header: "S/N", sortable: true, filter: true, filterPlaceholder: "Search", visible: true,  filterElement: CustomFilter, filterField: "memberid", },
                    { field: "memberid", header: "Member ID", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "memberid", },
                    { field: "membername", header: "Member Name", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "membername", },
                    { field: "sharebalance", header: "Share Balance", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sharebabalce", body: decimalshare },

                ]
            )

            expandAll()

        }
        else if (formPayload['NS'].NomineeStatus == "withoutNominee") {
            setColumneVisible(
                [
                    // {  header: "S/N", sortable: true, filter: true, filterPlaceholder: "Search", visible: true,  filterElement: CustomFilter, filterField: "memberid", },
                    { field: "memberid", header: "Member ID", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "memberid", },
                    { field: "membername", header: "Member Name", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "membername", },
                    { field: "sharebalance", header: "Share Balance", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sharebabalce", body: decimalshare },

                ]
            )

        }




    }, [formPayload['NS'].NomineeStatus])
    const clearFilter = () => {
        setFilters(
            {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                membername: { value: null, matchMode: FilterMatchMode.CONTAINS },

                share: { value: null, matchMode: FilterMatchMode.EQUALS },



            }
        )
        setGlobalFilterValue('');
        setFilterState(false)
    }


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        membername: { value: null, matchMode: FilterMatchMode.CONTAINS },

        share: { value: null, matchMode: FilterMatchMode.EQUALS },


    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(NominiStatusData);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };
    useEffect(() => {
        //console.log("---inside NMC---", NominiStatusData)
    }, [NominiStatusData])

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    let printing_object = []
    const newTable = () => {

        if (formPayload['NS'].NomineeStatus == "withNominee" && NominiStatusData) {



            // let obj_filter=NominiStatusData.filter((item,index)=>item.member.estate)
            let seen = {};
            let duplicates = [];
            let unique = [];
            NominiStatusData.forEach((item) => {
                const value = item.mem.memberid;

                if (seen[value]) {
                    duplicates.push(item);
                } else {
                    seen[value] = true;
                    unique.push(item.mem.memberid);
                }
            });

            //console.log(unique)
            NominiStatusData.forEach((item) => {
                const value = item.mem.memberid;

                if (seen[value]) {
                    duplicates.push(item);
                } else {
                    seen[value] = true;
                    unique.push(item.mem.memberid);
                }
            });

            for (let i = 0; i < unique.length; i++) {
                let model_obj = { membername: "", memberid: "", shareBalance: "", nominee: [] }

                NominiStatusData.forEach((item) => {

                    if (unique[i] === item.mem.memberid) {

                        // console.log(item.mem.memberid)

                        model_obj.membername = item.mem.membername
                        model_obj.memberid = item.mem.memberid
                        model_obj.shareBalance = item.mem.sharebalance
                        model_obj.nominee.push(item.nominee)

                    }
                })

                //    console.log(model_obj)
                printing_object.push(model_obj)

            }
            //console.log(printing_object)
            setFormattedData(printing_object)

        }
    }

    //   const onRowExpand = (event) => {
    //     console.log(event.data)
    //     toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    // };
    // const onRowCollapse = (event) => {
    //     toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    // };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">

                <DataTable value={data.nominee}>
                    <Column field="nomineeid" header="Nominee Id" sortable></Column>
                    <Column field="nomineename" header="Nominee Name" sortable></Column>
                    <Column field="nomineeicno" header="Nominee IC NO" sortable></Column>
                    <Column field="relationship" header="Relationship" sortable></Column>
                    <Column field="percentage" header="Percentage" sortable></Column>
                    <Column headerStyle={{ width: '4rem' }} ></Column>
                </DataTable>
            </div>
        );
    };
    const allowExpansion = (rowData) => {
        return rowData?.nominee?.length > 0;
    };
    const expandAll = () => {
        let _expandedRows = {};

        // formattedData.forEach((mem) => (_expandedRows[`${mem.memberid}`] = true));
        formattedData.forEach((p) => setExpandedRows(p));


        // setExpandedRows(_expandedRows);
    };
    const exportPDF = () => {
        dt.current.exportCSV()
    }
    return (
        <Box className="fulldiv" sx={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >
            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >
                <Form formAlone={true} api="NS" />
            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", height: "93vh", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010" }}>{formPayload['NS'].NomineeStatus == "withoutNominee" ? "Without Nominee Status Report" : "Nominee Status Report"}</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found <span style={{ fontWeight: "700" }}>{NominiStatusData.length}</span> records with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap" }}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
                            <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={exportPDF} /> </Box>

                            {/* <CSVLink data={selectedCustomers.length > 0 ? selectedCustomers : NominiStatusData} headers={exportCSV} filename={'table-export.csv'}> <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /> </Box></CSVLink> */}
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

                        </Box>

                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <div className="card">
                        {/* <Toast ref={toast} /> */}
                        <DataTable ref={dt} value={NominiStatusData} filters={filters} paginator rows={10} dataKey="memberid" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" removableSort resizableColumns expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate} selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." globalFilterFields={globalFilterFields}>
                            {/* <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} /> */}

                            {formPayload['NS'].NomineeStatus == "withNominee" ? <Column expander={allowExpansion} style={{ width: '5rem' }} /> : null}
                            <Column header="S/NO" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                            {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                        </DataTable>
                    </div>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                        {columnVisible.map((column, ind) => {
                            return (

                                <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                    <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                    <label htmlFor={column.header} className="ml-2">
                                        {column.header}
                                    </label>
                                </div>

                            );
                        })}

                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box>
    )
}


export default NomineeStatus