import React, { useRef, useState } from 'react';
import { Box, Typography, Button, Tooltip, IconButton, Menu, MenuList, Drawer } from "@mui/material";
import { HelpOutlineOutlined, Logout } from '@mui/icons-material';
import TransLogo from "../assets/KSKB_Logo_TransparentBG.PNG"
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { updateToggleBarActiveTab, updateIsExpanded } from "../redux/toggleBarReducer"
import { DashboardCustomizeOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/system';
import { MenuItem } from '@mui/base';
import Sidebar from './sidebar';
import { useMediaQuery } from 'react-responsive';
const Header = ({title,color}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        //console.log('Logout clicked');
        dispatch(updateToggleBarActiveTab('dashboard'))
        // Clear localStorage
        localStorage.clear();
        navigate('/login');
    };
    const menu_options = [
        {
            label: '',
            items: [
                {
                    label: 'Dashboard',
                    icon: <DashboardCustomizeOutlined sx={{width:"40px",height:"40px",color:"#288d2c" }}/>
                },
                {
                    label: 'Export',
                    icon: 'pi pi-upload'
                }
            ]
        }
    ]
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    }
    const memberName = localStorage.getItem('username')
    return (    
        <Box sx={{ background: 'var(--pallate-green-500, #E7EFE9)', padding: "16px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Stack direction="row" sx={{gap:"24px"}}>
                {isMobile&&<>
                    <MenuIcon sx={{color:"#FFFFFF"}} onClick={(e)=>setAnchorEl(e.currentTarget)} />
                    <Drawer
                        id="header_menu"
                        anchor = "left"
                        open={open}
                        onClose={()=>handleClose()}
                        PaperProps={{
                            style:{paddingTop:"16px",paddingBottom:"16px",width:"258px"}
                        }}
                    >   
                        <Typography sx={{mb:"1rem",p:"12px 16px",color:"var(--Light-Text-Default-Text, #101010)",alignSelf:"stretch",fontSize:"20px",fontStyle:"normal",fontFamily:"inter",fontWeight:500,lineHeight:"28px"}}>
                            Hi, {memberName}
                        </Typography>     
                        <Sidebar isHeaderMenuClicked={true}/>
                    </Drawer>
                </>}
                <Box component="img" sx={{width:"32px", height:"32px"}} src={TransLogo}></Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 600, lineHeight: "28px", color: "black" }}>KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)</Typography>
            </Stack>
        <Box sx={{display:"flex"}}>
        <Tooltip title="Help" arrow>
            <IconButton
            sx={{ "&:hover":{background:'var(--pallate-green-500, #E7EFE9)'},display:"flex",padding:"8px",alignItems:"flex-start",gap:"10px",borderRadius:"20px",border:"1px solid black",background:'var(--pallate-green-500, #E7EFE9)'}}
            onClick={()=> navigate('/contact_us')}
            >
            <HelpOutlineOutlined sx={{width:"24px",height:"24px",color:"black"}}/>
            </IconButton>
        </Tooltip>
        <Tooltip title="Logout" arrow>
            <Button
            sx={{ color: "black" }}
            onClick={handleLogout}
            >
            <Logout />
            </Button>
        </Tooltip>
        </Box>
        </Box>
    );
};

export default Header;