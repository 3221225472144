import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useState, useEffect } from "react"
import CustomAxios from "../utils/CustomAxios"
import { useDispatch, useSelector } from "react-redux";
import { updateLoanListPayload } from "../redux/executiveReducer"
import { Box, Stack, Typography, Button } from "@mui/material"
import { ProgressBar } from "primereact/progressbar";
import { LinkHeader } from "../components/linkHeader"
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { InputText } from "primereact/inputtext";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TableChartIcon from '@mui/icons-material/TableChart';
import { FilterMatchMode } from 'primereact/api';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import * as XLSX from 'xlsx';

export function LoanList () {

    const dispatch = useDispatch()
    const loanListForAllMembers = useSelector(state => state.executive.loanLists)
    const memberLists = useSelector(state => state.executive.memberLists)
    const open = useSelector((state) => state.executive.open);
    const [isLoading, setIsLoading] = useState(true)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filterState, setFilterState] = useState(false)
    const [arrangeTable, setArrangeTable] = useState(false)
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [selectedColumn, setSelectedColumn] = useState([])
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null)

    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };

    const [columnVisible, setColumneVisibleTransactions] = useState([
        { field: "loanid", header: "Loan#", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "loanid", datatype: "numeric" },
        { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
        { field: "memberName", header: "Member Name", visible: true, filterField: "memberName", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "loantype", header: "Description", visible: true, filterField: "loantype", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "rateofinterest", header: "Rate %", visible: true, filterField: "rateofinterest", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "diminishingbalance", header: "SL/RB", visible: true, filterField: "diminishingbalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "noofinstalment", header: "# Mth", visible: true, filterField: "noofinstalment", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "loanstartdate", header: "Start Date", visible: true, filterField: "loanstartdate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "loanexpirydate", header: "Expiry Date", visible: true, filterField: "loanexpirydate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "principalamount", header: "Principal", visible: true, filterField: "principalamount", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "interestamount", header: "Interest", visible: true, filterField: "interestamount", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "amountbeforededuction", header: "Total Loan", visible: true, filterField: "amountbeforededuction", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "loanbalanceamount", header: "Loan Balance", visible: true, filterField: "loanbalanceamount", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "penalty", header: "Penalty", visible: true, filterField: "penalty", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "rebate", header: "Rebate", visible: true, filterField: "rebate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "processingfee", header: "Pr Fee", visible: true, filterField: "processingfee", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "stampingfee", header: "Stamp Fee", visible: true, filterField: "stampingfee", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "legalfee", header: "Legal Fee", visible: true, filterField: "legalfee", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "insuranceamount", header: "Insurance", visible: true, filterField: "insuranceamount", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "totalotherdeductions", header: "Total Deduct", visible: true, filterField: "totalotherdeductions", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "amountafterdeduction", header: "After Deduct", visible: true, filterField: "amountafterdeduction", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter }
    ])

    const [filters, setFilters] = useState(() => {
        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        return filter_obj
    });

    const formatDate = (date) => {
        if (typeof (date) != "string") {
            const new_date = new Date(date)
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const day = String(new_date.getDate()).padStart(2, '0');
            const monthIndex = String(new_date.getMonth() + 1).padStart(2, '0');
            const month = months[monthIndex];
            const year = new_date.getFullYear();
            console.log(day)
            return `${day}-${monthIndex}-${year}`;
        }
        else {
            const dd = String(new Date(date).getUTCDate()).padStart(2, '0');
            const mm = String(new Date(date).getUTCMonth() + 1).padStart(2, '0'); // January is 0!
            const yy = String(new Date(date).getUTCFullYear());

            return `${dd}-${mm}-${yy}`;
        }

    };

    useEffect(() => {
        if (memberLists.length > 0) {
            const fetchLoanListForAllMembers = async () => {
                try {
                    const res = await CustomAxios.get(`oasis/loanList/`)
                    console.log(res)
                        const loanListsWithMNS = res.data.loanList.map((loan) => {

                            const member = memberLists.filter(mem => mem.memberid == loan.memberid)
                            return { ...loan, memberName: member[0]?.membername, state: member[0]?.state, loanstartdate: formatDate(loan.loanstartdate), loanexpirydate: formatDate(loan.loanexpirydate), diminishingbalance: slRbCheck(loan.diminishingbalance), loantype: ["Education Loan", "Personal Loan", "Housing Loan", "Business Loan", "Angkasa Loan", "Angkasa Tung"][loan.loantype - 1] }
                        })
                        console.log(loanListsWithMNS)

                        dispatch(updateLoanListPayload(loanListsWithMNS))
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                setIsLoading(false)
            }
            fetchLoanListForAllMembers()
        }
    }, [memberLists])

    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumneVisibleTransactions(selectedColumn)
        setArrangeTable(false)
        // let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        // exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        // setExportCSV(exportHeaders)
    }

    const clearFilter = () => {

        let filter_obj = {}
        filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        columnVisible.map((obj) => {
            filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        setFilters(filter_obj)
        setGlobalFilterValue('')
        setFilterState(false)
    }

    const globalFilterFields = columnVisible.map((obj) => { return obj.field })

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(loanListForAllMembers);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        // console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };

    const slRbCheck = (val) => {
        if (typeof (val) !== 'Boolean') {
            if (val == 0) {
                return false
            } else {
                return true
            }
        }
        else {
            return val
        }
    }

    const exportExcel = () => {

        let headers = columnVisible.map(col => ({ header: col.header, key: col.field }));
        headers = [{ header: "SN", key: "SN" }, ...headers];

        const data = selectedCustomers.length>0?selectedCustomers.map((item, index) => {
            return {  SN: index + 1, ...item };
          }):loanListForAllMembers.map((item, index) => {
            return { SN: index + 1, ...item };
        });
    
        const headersBold = headers.map(({ header }) => ({
            v: header,
            t: 's',
            s: {
                font: { bold: true, size: 10 }
            }
        }));
    
        // Convert array of objects to array of arrays (2D array) for the data section
        const dataRows = data.map(obj => headers.map(h => {
            let value;
            const amountRelatedKeys = ["rateofinterest","principalamount","interestamount","amountbeforededuction","loanbalanceamount","penalty","rebate","processingfee","stampingfee","legalfee","insuranceamount","totalotherdeductions","amountafterdeduction",]
            if(amountRelatedKeys.includes(h.key)){
                if(obj[h.key] == null)
                    value = 0
                else
                    value = parseInt(parseFloat(obj[h.key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[h.key]):obj[h.key]
            }
            else
                value = obj[h.key]
            return ({
            v: value,
            t: typeof value === 'number' ? 'n' : 's',
            s: {
                alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
                font: { size: 8, name: 'Times New Roman' }
            }
        })}));
        console.log(dataRows)
    
        const dataArray = [headersBold, ...dataRows];
    
        // Convert data to worksheet
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
    
        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        // Generate XLSX file and trigger download
        XLSX.writeFile(wb, 'All Loan Details List.xlsx');

    };

    const header = (


        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>


        <Box sx={{ display: "flex", alignItems: "center" }}>

            <Box>

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                </span>
            </Box>
            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                Clear Filter
            </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
            <Button type="button" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

        </Box>

    </Box>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column",width:"100%",padding:"10px 30px"}}>
            {/* <LinkHeader /> */}
            {isLoading ?
                <Stack rowGap="10px" sx={{ width: "100%", height: "87vh", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Please Wait</Typography>
                    <ProgressBar mode="indeterminate" style={{ width: "25%", height: '6px' }} />
                </Stack>
                :
                <>
                    <Box sx={{ height: "calc(100vh-84px)", width: open?"82.5vw":"94vw" }}>

                        <Box className="card">
                            <DataTable value={loanListForAllMembers} header={header} rows={10} style={{ backgroundColor: "#F1F5F9", position: "relative" }} scrollable removableSort filters={filters} paginator filterDisplay={filterState ? "row" : ""} columnResizeMode="expand" resizableColumns
                                emptyMessage="No Loans found." selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} globalFilterFields={globalFilterFields} >
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} frozen style={{textAlign:"center"}}/>
                                <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen style={{textAlign:"center"}} ></Column>
                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} header={col.header} body={col.body} alignFrozen="left" filter={col.filter} sortable dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </Box>
                    </Box>  
                    <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >
                        <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                                <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                                <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                            </Box>
                            <Box>


                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                                </span>

                            </Box>
                            <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                                {columnVisible.map((column, ind) => {
                                    return (

                                        <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                            <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                            <label htmlFor={column.header} className="ml-2">
                                                {column.header}
                                            </label>
                                        </div>

                                    );
                                })}

                            </Box>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                                <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                                <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                            </Box>
                        </Box>
                    </Dialog >
                </>

            }
        </Box>
    )
}

